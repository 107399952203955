import { Component, Input } from '@angular/core';
import { Status } from '@components/funding-request-summary-table/models/status.model';
import { FundingRequest } from '@services/funding-request/models/funding-request.model';
import { convertRawStatusResponseToStatus } from '@services/funding-request/models/response-status-converters';

import { ConstantsOverviewPage } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_DetailPages';

export type LockedReason = 'ach' | 'user';

@Component({
    selector: 'eb-request-locked',
    templateUrl: './request-locked.component.html',
    styleUrls: ['./request-locked.component.scss'],
})
export class RequestLockedComponent {
    @Input() fundingRequest: FundingRequest;

    // E2E
    public constantsOverviewPage: typeof ConstantsOverviewPage = ConstantsOverviewPage;

    /**
     * Initializes data to 'Locked by ACH' status if funding request is undefined.
     * Otherwise determines correct data based on status value.
     */

    public get lockedClass(): string {
        return this.fundingRequest && !this.isInAchProcess ? 'user' : 'ach';
    }

    public get lockedIcon(): string {
        return this.fundingRequest && !this.isInAchProcess ? 'person' : 'lock';
    }

    public get lockedType(): string {
        return this.fundingRequest && !this.isInAchProcess ? 'RECORD LOCKED' : 'STATUS LOCKED';
    }

    public get lockedCause(): string {
        return this.fundingRequest && !this.isInAchProcess ? 'by ' + this.fundingRequest.currentEditingBy + '. Saving will cancel their changes.' : 'by ACH process.';
    }

    public get isInAchProcess(): boolean {
        return convertRawStatusResponseToStatus(this.fundingRequest.status) === Status.inProcess;
    }
}
