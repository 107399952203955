import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';

import { MessageStatusHistoryComponent } from './message-status-history.component';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [CommonModule, MatTableModule, MatSortModule, MatTooltipModule],
    declarations: [MessageStatusHistoryComponent],
    exports: [MessageStatusHistoryComponent],
})
export class MessageStatusHistoryModule {}
