import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { SetCallDateTimePanelData } from '@components/update-funding-request/update-funding-request-call-time/update-funding-request-call-time.component';
import { CallType, RoutingPaths } from '@constants';
import { FundingRequestSummaryTableService } from '@services/funding-request-summary-table/funding-request-summary-table.service';
import { WINDOW } from '@services/shared/window/window.provider';
import { UpdateFundingRequestService } from '@services/update-funding-request/update-funding-request.service';
import { Moment } from 'moment';

// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';

import {
    ConstantsActionMenuOptions,
    ConstantsDashboardAuthorizationRow,
    ConstantsDashboardAuthorizationsTable,
    ConstantsDashboardFilters,
    ConstantsDashboardTablePagination,
} from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';
import { FundingAuthorizationSummary } from '../../services/funding-request/funding-authorization.model';

import {
    createFundingRequestRowViewModelFromFundingAuthorizationSummaryResponse,
    FundingException,
    FundingRequestRowViewModel,
} from './models/funding-request-table.models';
import { Status } from './models/status.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'eb-funding-request-summary-table',
    templateUrl: './funding-request-summary-table.component.html',
    styleUrls: ['./funding-request-summary-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class FundingRequestSummaryTableComponent implements OnInit {
    @ViewChild(MatSort, { static: true }) public sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
    @Output() dataLoadedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constantsAppHeader: typeof ConstantsDashboardAuthorizationsTable = ConstantsDashboardAuthorizationsTable;
    public constantsAppRow: typeof ConstantsDashboardAuthorizationRow = ConstantsDashboardAuthorizationRow;
    public constantsActionMenu: typeof ConstantsActionMenuOptions = ConstantsActionMenuOptions;
    public constantsPagination: typeof ConstantsDashboardTablePagination = ConstantsDashboardTablePagination;
    public constantsFilters: typeof ConstantsDashboardFilters = ConstantsDashboardFilters;
    public dataSource: MatTableDataSource<FundingRequestRowViewModel>;
    public fundingExceptionTypes: typeof FundingException = FundingException;
    public dataLoaded = false;
    public statusType = Status;
    public callType: typeof CallType = CallType;
    public authorizationsRoutingPath = `/${RoutingPaths.fundingRequestSummaries}`;
    public pageSizeOptions: number[] = [20, 50, 100];
    public pageIndex = 0;
    public pageSize = 100;
    public displayedColumns: string[] = [
        'icon',
        'applicationNumber',
        'positiveConfirmationLevel',
        'contractor',
        'applicantName',
        'applicantState',
        'createdDate',
        'lastCall',
        'nextCall',
        'status',
        'statusDetail',
        'exception',
        'actions',
    ];

    public sortBy: string;
    public sortDirection: string;
    
    constructor(
        public dialog: MatDialog,
        public fundingRequestSummaryTableService: FundingRequestSummaryTableService,
        public updateFundingRequestService: UpdateFundingRequestService,
        @Inject(WINDOW) public window: Window
    ) {
        this.dataSource = new MatTableDataSource<FundingRequestRowViewModel>();
    }

    ngOnInit() {
        this.onPageOptionsChanged({
            pageIndex: 0,
            pageSize: this.pageSize,
        } as PageEvent);
        this.populateFundingRequestRowViewModels();
    }


    public onSetCallDateTimeClicked(mainRowModel: FundingRequestRowViewModel, callTypeText: CallType) {
        let callDateTime: Moment = moment();

        switch (callTypeText) {
            case CallType.next:
                callDateTime = mainRowModel.nextCall;
                break;
            case CallType.last:
                callDateTime = mainRowModel.lastCall;
                break;
            default:
                return;
        }

        this.updateFundingRequestService.openSetCallDateTime({
            editedCallType: callTypeText,
            selectedDateTime: callDateTime,
            selectedFundingRequest: mainRowModel,
        } as SetCallDateTimePanelData);
    }

    public openStatusOverride(fundingRequestRowModel: FundingRequestRowViewModel): void {
        this.updateFundingRequestService.openUpdateStatus(fundingRequestRowModel);
    }

    public onSortChanged(sortData: Sort): void {
        this.fundingRequestSummaryTableService.changeSort(sortData.active, sortData.direction);
    }

    public onPageOptionsChanged(pageData: PageEvent): void {
        this.pageIndex = pageData.pageIndex;
        this.pageSize = pageData.pageSize;
        this.fundingRequestSummaryTableService.changePage(pageData.pageIndex, pageData.pageSize);
    }

    public onApplicationNumberClicked(event: MouseEvent, authorizationNum: string) {
        window.open(`${this.authorizationsRoutingPath}/${authorizationNum}`, '_blank');
    }

    dataSourceLoaded(value: boolean) {
        this.dataLoadedEvent.emit(value);
    }

     // Builds an array of rows by calling the FundingRequestService and converting the responses.
     private populateFundingRequestRowViewModels(): void {
        // const masterRowViewModels: Observable<FundingRequestRowViewModel[]> =
        this.fundingRequestSummaryTableService.fundingAuthorizationData
            .pipe(
                mergeMap((fundingAuthorizationSummaries: FundingAuthorizationSummary[]) => {
                    const rows: FundingRequestRowViewModel[] = [];

                    // Map funding requests.
                    fundingAuthorizationSummaries.forEach((fundingAuthorizationSummary: FundingAuthorizationSummary) => {
                        // TODO: As per Steve Evers, the loan id will be copied to the application number until we hear back from Sergio.
                        fundingAuthorizationSummary.applicationNumber = fundingAuthorizationSummary.applicationNumber;

                        const masterRowViewModel = createFundingRequestRowViewModelFromFundingAuthorizationSummaryResponse(fundingAuthorizationSummary);
                        rows.push(masterRowViewModel);
                    });

                    return of(rows);
                })
            )
            .subscribe((models: FundingRequestRowViewModel[]) => {
                this.dataSource.data = models;
                this.pageIndex = this.fundingRequestSummaryTableService.pageIndex;
                this.dataSourceLoaded(true);
                this.sortBy = this.fundingRequestSummaryTableService.sortBy;
                this.sortDirection = this.fundingRequestSummaryTableService.sortDirection;
            });
    }
}
