import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AchService } from '@services/ach/ach.abstract';
import { AchEstimates } from '@services/ach/models/ach-estimates.model';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';
import { Subscription } from 'rxjs';

import { ConstantsObjectsLabelValuePair } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { ConstantsGenericSidePanel } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

@Component({
    selector: 'eb-create-ach-review',
    templateUrl: './create-ach-review.component.html',
    styleUrls: ['./create-ach-review.component.scss'],
})
export class CreateAchReviewComponent implements OnInit, OnDestroy {
    @Input() achFile: AchFileMetadata;

    public username = '';
    public estimates: AchEstimates = {
        fundingRequests: 0,
        total: 0,
    };
    achEstimatesSubscription: Subscription;

    // E2E Tests
    public constantsCreateAchSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;
    public constantsObjectsLabelValuePair: typeof ConstantsObjectsLabelValuePair = ConstantsObjectsLabelValuePair;

    constructor(private achService: AchService) {}

    public get achGenerationInProgress(): boolean {
        if (this.achService) {
            return this.achService.achGenerationInProgress;
        }
    }

    ngOnInit(): void {
        this.achEstimatesSubscription = this.achService.achEstimatesSubject.subscribe((estimates: AchEstimates) => {
            this.estimates = {
                fundingRequests: estimates ? estimates.fundingRequests : 0,
                total: estimates ? estimates.total : 0,
            };
        });
    }

    ngOnDestroy(): void {
        if (this.achEstimatesSubscription) {
            this.achEstimatesSubscription.unsubscribe();
        }
    }
}
