import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';

import { AuthService } from '../auth/auth.abstract';

import { PermissionEnum } from '@constants';
import { PermissionsService } from './permissions.abstract';
import { PermissionsMockService } from './permissions.mock-service';
import { PermissionsApiService } from './permissions.service';

const factory = (environment: Environment, http: HttpClient, authService: AuthService, googleAnalyticsService: GoogleAnalyticsService) =>
    environment.mockServicesEnabled ? new PermissionsMockService(environment) : new PermissionsApiService(environment, http, authService, googleAnalyticsService);

export const permissionsServiceProvider: FactoryProvider = {
    deps: [ENVIRONMENT, HttpClient, AuthService, GoogleAnalyticsService],
    provide: PermissionsService,
    useFactory: factory,
};

export interface UserAuthorization {
    permissions: PermissionEnum[];
    roles: string[];
    principal: SubjectPrincipal;
}
export interface SubjectPrincipal {
    subjectId: string;
    name: string;
    givenName: string;
    familyName: string;
    userPrincipalName: string;
    claims: string[];
}
