<div class="update-content-container">
  <div class="update-content-title" [attr.data-automation]="constantStatusOverrideSection.statusOptionLabel">Status:</div>
  <div>
    <mat-radio-group [formControl]="statusControl">
      <mat-radio-button *ngIf="canShowPendingOption" value="{{status.pending}}" [attr.data-automation]="constantStatusOverrideSection.pendingStatus"> {{status.pending}}<span *ngIf="showPendingCurrent" class="current" [attr.data-automation]="constantsObjectsRadioButton.radioButtonCurrent"> (current)</span></mat-radio-button>
      <mat-radio-button *ngIf="canShowApprovedOption" value="{{status.approved}}" [attr.data-automation]="constantStatusOverrideSection.approvedStatus">{{status.approved}}<span *ngIf="showApprovalCurrent" class="current" [attr.data-automation]="constantsObjectsRadioButton.radioButtonCurrent"> (current)</span></mat-radio-button>
      <mat-radio-button *ngIf="canShowHoldOption" value="{{status.hold}}" [attr.data-automation]="constantStatusOverrideSection.holdStatus">{{status.hold}}<span *ngIf="showHoldCurrent" class="current" [attr.data-automation]="constantsObjectsRadioButton.radioButtonCurrent"> (current)</span></mat-radio-button>
      <mat-radio-button *ngIf="canShowCanceledOption" value="{{status.canceled}}" [attr.data-automation]="constantStatusOverrideSection.canceledStatus">{{status.canceled}}</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="showErrorText" class="error-content-container">
      <span>The user that created a funding request cannot approve the same request.</span>
    </div>
  </div>
</div>
<div class="button-container">
  <button class="submit" [attr.data-automation]="constantsGenericSidePanel.proceedButton" (click)="onChangeStatusSubmit()">Change Status</button>
</div>
