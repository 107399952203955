import { Provider } from '@angular/core';
import { achServiceProvider } from '@services/ach/ach.provider';
import { fundingRequestSummaryTableServiceProvider } from '@services/funding-request-summary-table/funding-request-summary-table.provider';
import { fundingRequestServiceProvider } from '@services/funding-request/funding-request.provider';
import { newAuthTableServiceProvider } from '@services/new-auth-table/new-auth-table.provider';
import { authGuardServiceProvider, authServiceProvider } from '@services/shared/auth/auth.provider';
import { googleAnalyticsServiceProvider } from '@services/shared/google-analytics/google-analytics.provider';
import { loggerServiceProvider } from '@services/shared/logger/logger.provider';
import { permissionsServiceProvider } from '@services/shared/permissions/permissions.provider';
import { windowProvider } from '@services/shared/window/window.provider';
import { sidePanelServiceProvider } from '@services/side-panel/side-panel.service.provider';
import { updateFundingRequestServiceProvider } from '@services/update-funding-request/update-funding-request.service.provider';

import { AnnouncementDialogService } from './announcement-dialog/announcement-dialog.service';
import { loanServiceProvider } from './loan/loan.provider';
import { featuresServiceProvider } from './shared/features/features.provider';
import { authenticationInitializationProvider } from './shared/auth/authentication-initialization.provider';

export const serviceProviders: Provider[] = [
    AnnouncementDialogService,
    authServiceProvider,
    authGuardServiceProvider,
    fundingRequestServiceProvider,
    achServiceProvider,
    featuresServiceProvider,
    fundingRequestSummaryTableServiceProvider,
    googleAnalyticsServiceProvider,
    loanServiceProvider,
    loggerServiceProvider,
    newAuthTableServiceProvider,
    permissionsServiceProvider,
    sidePanelServiceProvider,
    updateFundingRequestServiceProvider,
    authenticationInitializationProvider,
    windowProvider,
];
