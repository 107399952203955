import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { CUSTOM_DATEPICKER_FORMATS, DatepickerComponent, EnerbankMomentDateAdapter } from './datepicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [CommonModule, FormsModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatMomentDateModule, ReactiveFormsModule],
    declarations: [DatepickerComponent],
    exports: [DatepickerComponent],
    providers: [
        { provide: DateAdapter, useClass: EnerbankMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATEPICKER_FORMATS },
    ],
})
export class DatepickerModule {}
