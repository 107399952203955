import { FactoryProvider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { LoggerService } from '@services/shared/logger/logger.abstract';

import { WINDOW } from '@services/shared/window/window.provider';

import { GoogleAnalyticsService } from './google-analytics.abstract';
import { GoogleAnalyticsMockService } from './google-analytics.mock-service';
import { GoogleAnalyticsApiService } from './google-analytics.service';

// ng lint insists to specify ng disable snippet
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const factory = (logger: LoggerService, environment: Environment, window: any) => environment.mockServicesEnabled ? new GoogleAnalyticsMockService(logger, environment) : new GoogleAnalyticsApiService(logger, environment, window);

export const googleAnalyticsServiceProvider: FactoryProvider = {
    deps: [LoggerService, ENVIRONMENT, WINDOW],
    provide: GoogleAnalyticsService,
    useFactory: factory,
};
