import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NewAuthRowViewModel } from '@components/create-new-authorization/models/create-new-auth-table.models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NewAuthTableService {
    public dataSource: MatTableDataSource<NewAuthRowViewModel> = new MatTableDataSource<NewAuthRowViewModel>();

    public readonly dataSourceUpdated: BehaviorSubject<NewAuthRowViewModel[]> = new BehaviorSubject<NewAuthRowViewModel[]>([]);

    public readonly numTotalFundingRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public readonly numFilteredFundingRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor() {
        this.dataSource.filterPredicate = (data: NewAuthRowViewModel, filterValue: string): boolean => this.matchesSearchFilter(data, filterValue);
    }

    public filterBySearch(searchValue: string): void {
        // Don't initiate a search/filter unless the user enters
        // 2 or more characters into the search bar or we're clearing
        // the filter by passing in an empty string.
        if (searchValue.length !== 1) {
            this.dataSource.filter = searchValue;

            const { data, filteredData } = this.dataSource;
            this.numFilteredFundingRequests.next(filteredData.length);
            this.numTotalFundingRequests.next(data.length);
        }
    }

    public matchesSearchFilter(data: NewAuthRowViewModel, filterValue: string): boolean {
        const filterVal = filterValue.trim().toLowerCase();

        // don't return anything for empty string or whitespace
        if (!filterVal || !data) {
            return false;
        }

        return (data.applicationNumber && data.applicationNumber.includes(filterVal)) || (data.spectrumAccountNumber && data.spectrumAccountNumber.includes(filterVal));
    }
}
