<div [ngStyle]="{'width': width}" class="search-container" [attr.data-automation]="constantsDashboardSearchSection.searchBoxContainer">
  <mat-form-field class="search-field">
    <mat-label>{{placeholderText}}</mat-label>
    <input matInput type="text" [formControl]="searchInputBox" [attr.data-automation]="constantsDashboardSearchSection.searchInputBox"
           
           (keyup)="onSearchBarKeyUp($event)">
    <button mat-button matSuffix *ngIf="(searchInput | async); else emptyInputDisplay" mat-icon-button aria-label="Clear"
            (click)="onClearButtonClicked()" [attr.data-automation]="constantsDashboardSearchSection.searchClearIcon">
      <mat-icon class="md-24">close</mat-icon>
    </button>
    <ng-template #emptyInputDisplay>
      <mat-icon matSuffix [attr.data-automation]="constantsDashboardSearchSection.searchMagnifyingGlassIcon">search</mat-icon>
    </ng-template>
  </mat-form-field>
</div>