// Used to find elements for various objects

export class ConstantsObjectsEllipsisField {
    public static displayedTextWithEllipsis = 'QA-DisplayedTextFollowedByEllipsis';
    public static ellipsis = 'QA-Ellipsis';
}

export class ConstantsObjectsRadioButton {
    public static radioButtonValue = 'QA-ConstantObjects-RadioButtonValue';
    public static radioButton = 'QA-ConstantObjects-RadioButton';
    public static radioButtonCurrent = 'QA-ConstantObjects-RadioButton-Current';
}

// Used when there's a title and, in a separate tag, a static value
export class ConstantsObjectsLabelValuePair {
    public static label = 'label';
    public static value = 'strong';
}

export class ConstantsObjectsCheckBox {
    public static checkBox = 'QA-ConstantObjects-CheckBox';
    public static checkBoxValue = 'QA-ConstantObjects-CheckBoxValue';
    // State may need to be updated for MD-26 Tests
    public static checkBoxState = 'primary';
    // Tag may need to be updated for MD-26 tests
    public static checkBoxTag = 'ng-reflect-color';
}

export class ConstantsObjectsAchFileRow {
    public static checkbox = 'QA-ConstantObjects-Checkbox';
    public static fileIcon = 'QA-ConstantObjects-FileIcon';
    public static achFileText = 'QA-ConstantObjects-AchFileText';
    public static dateTimeText = 'QA-ConstantObjects-DateTimeText';
    public static username = 'QA-ConstantObjects-Username';
}

export class ConstantsHeader {
    public static logOutButton = 'QA-Header-logOutButton';
    public static title = 'QA-Header-title';
}

export class ConstantsExceptionIcons {
    public static manualExceptionIcon = 'QA-Dashboard-Authorization-ManualExceptionIcon';
    public static nachaExceptionIcon = 'QA-Dashboard-Authorization-NachaExceptionIcon';
    public static rescissionExceptionIcon = 'QA-Dashboard-Authorization-RescissionExceptionIcon';
    public static frozenExceptionIcon = 'QA-Dashboard-Authorization-FrozenExceptionIcon';
    public static failedToFundExceptionIcon = 'QA-Dashboard-Authorization-FailedToFundExceptionIcon';
    public static pastReamortizationDateExceptionIcon = 'QA-Dashboard-Authorization-PastReamortizationDateIcon';
    public static blockedBySpectrum = 'QA-Dashboard-Authorization-BlockedBySpectrum';
}

export class ConstantsSortIcon {
    // This section is used to determine sorting and sorting direction
    public static subHeaderContainer = 'mat-sort-header-container';
    public static sortingIndicator = 'mat-sort-header-sorted';
    public static arrowContainer = 'mat-sort-header-pointer-left';
    public static arrowDirectionIndicatorAscending = 'rotate(-45deg)'; // -45def = pointing up (ascending); 45deg = pointing down (desc.)
}
