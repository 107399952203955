import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContentFilterMenuModule } from '@components/shared/content-filter-menu/content-filter-menu.module';

import { CommunicationMethodFilterMenuComponent } from './communication-method-filter-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { PipesModule } from '@components/utils/pipes/pipes.module';
import { CommunicationMethodPipe } from '@components/utils/pipes/communication-method.pipe';

@NgModule({
    imports: [CommonModule, ContentFilterMenuModule, MatButtonModule, MatCardModule, MatListModule, PipesModule],
    declarations: [CommunicationMethodFilterMenuComponent],
    exports: [CommunicationMethodFilterMenuComponent],
    providers: [CommunicationMethodPipe]
})
export class CommunicationMethodFilterMenuModule {}
