<eb-content-filter-menu buttonText="{{buttonDisplayText}}"
                        [active]="hasFiltersApplied"
                        [height]="260"
                        width="15%"
                        (menuToggled)="menuToggled($event)"
                        [menuOpen]="menuOpen">
  <mat-card-content style="top: 310px !important">
    <mat-selection-list #statusList [attr.data-automation]="constantsStatusFilter.statusContainer">
      <mat-list-option style="height: 39px;" *ngFor="let method of status"
                       checkboxPosition="before"
                       [value]="method" [attr.data-automation]="constantsStatusFilter.statusRow">
        {{method}}
      </mat-list-option>
    </mat-selection-list>
  </mat-card-content>

  <!--Clear/Apply Buttons-->
  <mat-card-actions [attr.data-automation]="constantsAbstractFilter.bottomButtonsContainer">
    <button mat-button class="apply-btn" (click)="onApply()" [disableRipple]="true"
      [attr.data-automation]="constantsAbstractFilter.applyButton">Apply</button>
  </mat-card-actions>
</eb-content-filter-menu>
