<div class="body">
  <mat-card>
    <div class="description">
      <p [attr.data-automation]="constantsGenericSidePanel.callStatementTitle"><b>{{ editedCallType }} Call:</b></p>
      <p *ngIf="isLastCall" [attr.data-automation]="constantsGenericSidePanel.callStatement">The "Next Call" will automatically be set to 24 hours from this time.</p>
    </div>

    <div class="form">
      <eb-date-time-input *ngIf="selectedDateTime" [selectedDateTime]="selectedDateTime" (dateTimeUpdated)="onDateTimeUpdated($event)"></eb-date-time-input>
      <div class="error" *ngIf="errorMessages">
        <label [attr.data-automation]="constantsGenericSidePanel.futureError" *ngFor="let errorMessage of errorMessages">{{ errorMessage }}</label>
      </div>
    </div>
  </mat-card>
</div>
<div class="button-container">
  <button class="submit" (click)="onSetCallButtonClicked()" [disabled]="dateTimeControl.errors" [class.disabled]="dateTimeControl.errors" [attr.data-automation]="constantsGenericSidePanel.proceedButton">Submit</button>
</div>
