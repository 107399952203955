// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum EnvironmentName {
    dev = 'dev',
    test = 'test',
    staging = 'staging',
    prod = 'prod',
    docker = 'docker',
    mocked = 'mocked',
    e2eMocked = 'e2e-mocked',
}
