<mat-table [hidden]="!dataSource" [dataSource]="dataSource" matSort>

  <!-- Date/Time Column -->
  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.dateTimeColumnHeader" mat-sort-header class="bold"> Date/Time </mat-header-cell>
    <mat-cell class="main-cell-content createDate" *matCellDef="let event" [attr.data-automation]="constantsHistoryRow.dateTimeRecord">
      <div>
        <div>{{event.messageDate | date: 'MM/dd/yy'}}</div>
        <div>{{event.messageDate | date: 'hh:mm a'}} MT</div> 
      </div>
    </mat-cell>
  </ng-container>

  <!-- Type Column -->
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.statusColumnHeader" mat-sort-header class="bold"> Type </mat-header-cell>
    <mat-cell class="main-cell-content type" *matCellDef="let event" [attr.data-automation]="constantsHistoryRow.messageType"> {{event.messageType}} </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.statusColumnHeader" mat-sort-header class="bold"> Status </mat-header-cell>
    <mat-cell class="main-cell-content status" *matCellDef="let event" [attr.data-automation]="constantsHistoryRow.messageStatus"> 
      <div>
      <div>{{event.status}}</div>
      <div *ngIf=event.errorCode matTooltip={{event.errorDescription}} class="bold">{{event.errorCode}}</div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Parties Column-->
  <ng-container matColumnDef="parties">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.messageColumnHeader" mat-sort-header class="bold"> Parties </mat-header-cell>
    <mat-cell class="main-cell-content parties" *matCellDef="let event" [attr.data-automation]="constantsHistoryRow.phoneNumRecord"> 
      <div>
        <div><span class="bold">From: </span> <span class="wrap">{{event.sender}}</span></div>
        <div><span class="bold">To: </span> <span class="wrap">{{event.recipient}}</span></div> 
      </div>
    </mat-cell>
  </ng-container>

   <!-- Message Column -->
    <ng-container matColumnDef="message">
      <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.messageColumnHeader" mat-sort-header class="bold"> Message </mat-header-cell>
      <mat-cell class="main-cell-content message" *matCellDef="let event" [attr.data-automation]="constantsHistoryRow.message"> 
       <div *ngIf=event.subject>
          <a class="link" (click)="showEmail(event)">{{event.subject}}</a>
        </div>
        <div *ngIf=!event.subject>
          <div *ngIf=event.viewAll>
            <div [innerHTML]=event.message></div>
            <a class="link" (click)="showText(event)">view less</a>
            </div>
            <div *ngIf=!event.viewAll>
            <div [innerHTML]="event.message | slice:0:200"></div>
            <a *ngIf="event.message.length > 200" class="link" (click)="showText(event)">view more</a>
            </div>
        </div>
      </mat-cell>
   </ng-container>  

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" [attr.data-automation]="constantsHistoryRow.rowContainer"></mat-row>
</mat-table>
