import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoggerService } from '@services/shared/logger/logger.abstract';

import { ConstantsObjectsAbstractFilter } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Filters';

@Component({
    selector: 'eb-content-filter-menu',
    templateUrl: './content-filter-menu.component.html',
    styleUrls: ['./content-filter-menu.component.scss'],
})
export class ContentFilterMenuComponent {
    // QA config.
    @Input() public filterButtonId: string;

    // Default sizing.
    @Input() public width = 300;
    @Input() public height = 300;

    // The spacing between the button and the filter content box.
    @Input() public filterContentHeightBuffer = 10;

    // Menu open/close state management.
    @Input() public menuOpen = false;
    @Output() public menuToggled: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    // Button state management.
    @Input() public active = false;
    @Input() public buttonText: string;

    // Menu positioning.
    public filterX = 0;
    public filterY = 0;

    // For QA Automation
    public constantsAbstractFilter: typeof ConstantsObjectsAbstractFilter = ConstantsObjectsAbstractFilter;

    constructor(private logger: LoggerService) {}

    public toggleMenu(button: HTMLElement): void {
        this.logger.log(`ContentFilterMenuComponent toggleMenu(): input button = ${button}`);

        this.filterX = button.offsetLeft;
        this.filterY = button.offsetTop + button.clientHeight + this.filterContentHeightBuffer;
        this.menuOpen = !this.menuOpen;
        this.menuToggled.emit(this.menuOpen);
    }

    public clickedOutsideCardContent(): void {
        this.logger.log(`ContentFilterMenuComponent clickedOutsideCardContent()`);

        this.menuOpen = false;
        this.menuToggled.emit(this.menuOpen);
    }
}
