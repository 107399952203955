import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PermissionEnum } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';

import { PermissionsService } from './permissions.abstract';
import { take, map } from 'rxjs/operators';

@Injectable()
export class PermissionsMockService extends PermissionsService {
    constructor(@Inject(ENVIRONMENT) environment: Environment) {
        super(environment, null, null);
    }

    public initialize(): Observable<void> {
        return this.getPermissions().pipe(
            take(1),
            map((permissions) => {
                this.canCreateAchFile.next(permissions.includes(PermissionEnum.canCreateACHRequests));
            })
        );
    }

    public getPermissions(): Observable<PermissionEnum[]> {
        const permissions = [PermissionEnum.canCreateACHRequests] as PermissionEnum[];
        return of(permissions);
    }
}
