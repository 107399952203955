import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { ObjectKeysPipe } from '@components/utils/pipes/object-keys/object-keys.pipe';
import { CallType, ValidationMessages } from '@constants';
import { FundingRequestFields, FundingRequestPatch } from '@services/funding-request/models/funding-request-patch.model';
import { UpdateFundingRequestService } from '@services/update-funding-request/update-funding-request.service';
import * as moment from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import { Moment } from 'moment';
import { ConstantsGenericSidePanel } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';
import * as DateTimeValidators from './validators/call-date-time-validators';

@Component({
    selector: 'eb-update-funding-request-call-time',
    templateUrl: './update-funding-request-call-time.component.html',
    styleUrls: ['./update-funding-request-call-time.component.scss'],
})

export class UpdateFundingRequestCallTimeComponent implements OnInit {
    
    public dateTimeControl: UntypedFormControl = new UntypedFormControl();
    public errorMessageMappings: Record<string, unknown>;
    public errorMessages: string[] = [];
    public editedCallType: CallType;
    public lastCall: Moment;
    public nextCall: Moment;
    public selectedDateTime: Moment = moment();

    readonly millisecondsInAnHour: number = 1000 * 60 * 60;

    // E2E tests
    public constantsGenericSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;

    constructor(public updateFundingRequestService: UpdateFundingRequestService, private objectKeys: ObjectKeysPipe) {}

    public get isLastCall(): boolean {
        return this.editedCallType === CallType.last;
    }

    ngOnInit() {
        const data = this.updateFundingRequestService.callTimeData;
        if (data) {
            this.editedCallType = data.editedCallType;
            this.lastCall = moment(data.selectedFundingRequest.lastCall);
            this.nextCall = moment(data.selectedFundingRequest.nextCall);
        }

        this.selectedDateTime = data && data.selectedDateTime && data.selectedDateTime.isValid() ? data.selectedDateTime : moment();

        this.errorMessageMappings = {
            [DateTimeValidators.valuesRequiredPropertyName]: ValidationMessages.dateAndTimeValuesRequired,
            [DateTimeValidators.lastCallTooFarInPastPropertyName]: ValidationMessages.lastCallTooFarInPast,
            [DateTimeValidators.lastCallInFuturePropertyName]: ValidationMessages.lastCallIsInFuture,
            [DateTimeValidators.nextCallInPastPropertyName]: ValidationMessages.nextCallInPast,
            [DateTimeValidators.nextCallBeforeLastCallPropertyName]: ValidationMessages.nextCallIsBeforeLastCall,
            [DateTimeValidators.nextCallTooFarInFuturePropertyName]: ValidationMessages.nextCallTooFarInFuture,
            [DateTimeValidators.tooLongBetweenCallsPropertyName]: ValidationMessages.tooLongBetweenCalls,
        };

        this.setDateTimeValidators(this.dateTimeControl);
    }

    public onDateTimeUpdated(dateTime: Moment): void {
        if (dateTime && dateTime.isValid()) {
            this.dateTimeControl.setValue(dateTime);
            this.updateErrorMessages();
        }
    }

    public updateErrorMessages(): void {
        let messages = [];
        for (const err of this.objectKeys.transform(this.dateTimeControl.errors)) {
            messages = messages.concat(messages, this.errorMessageMappings[err]);
        }
        this.errorMessages = messages;
    }

    public onSetCallButtonClicked() {
        if (!this.selectedDateTime) {
            return;
        }
        // so that any manipulations we do don't affect the original object
        let patchData: FundingRequestPatch[];
        const selectedDateTime: Moment = this.selectedDateTime.clone();
        const selectedFundingRequest = this.updateFundingRequestService.selectedFundingRequest;
        switch (this.editedCallType) {
            case CallType.last:
                // if last call is more than 24 hours in the past,
                // set next call to current date time to prevent
                // next call from being automatically set in past
                const now = moment();
                const diffInHours = (now.valueOf() - selectedDateTime.valueOf()) / this.millisecondsInAnHour;
                const newNextCall = diffInHours > 24 ? now : selectedDateTime.clone().add(1, 'd');
                patchData = [
                    {
                        field: FundingRequestFields.lastCall,
                        oldValue: this.lastCall.isValid() ? this.formatTime(this.lastCall) : null,
                        newValue: this.formatTime(selectedDateTime),
                    } as FundingRequestPatch,
                    {
                        field: FundingRequestFields.nextCall,
                        oldValue: this.nextCall.isValid() ? this.formatTime(this.nextCall) : null,
                        newValue: this.formatTime(newNextCall),
                    } as FundingRequestPatch,
                ];
                break;
            case CallType.next:
                patchData = [
                    {
                        field: FundingRequestFields.nextCall,
                        oldValue: this.nextCall.isValid() ? this.formatTime(this.nextCall) : null,
                        newValue: this.formatTime(selectedDateTime),
                    } as FundingRequestPatch,
                ];
                break;
        }
        this.updateFundingRequestService.saveFundingRequestChanges(selectedFundingRequest.authorizationNum, patchData);
    }

    private setDateTimeValidators(control: AbstractControl) {
        const valuesRequiredValidator: ValidatorFn = DateTimeValidators.valuesRequiredValidator(this.selectedDateTime);
        const lastCallTooFarInPastValidator: ValidatorFn = DateTimeValidators.lastCallTooFarInPastValidator(this.selectedDateTime);
        const lastCallInFutureValidator: ValidatorFn = DateTimeValidators.lastCallInFutureValidator(this.selectedDateTime);
        const nextCallInPastValidator: ValidatorFn = DateTimeValidators.nextCallInPastValidator(this.lastCall, this.selectedDateTime);
        const nextCallTooFarInFutureValidator: ValidatorFn = DateTimeValidators.nextCallTooFarInFutureValidator(this.selectedDateTime);
        const tooLongBetweenCallsValidator: ValidatorFn = DateTimeValidators.tooLongBetweenCallsValidator(this.lastCall, this.selectedDateTime);

        const validators: ValidatorFn[] = [valuesRequiredValidator];

        if (this.editedCallType === CallType.last) {
            validators.push(lastCallTooFarInPastValidator, lastCallInFutureValidator);
        } else if (this.editedCallType === CallType.next) {
            validators.push(nextCallInPastValidator);

            if (this.lastCall.isValid()) {
                validators.push(tooLongBetweenCallsValidator);
            } else {
                validators.push(nextCallTooFarInFutureValidator);
            }
        }

        control.setValidators(validators);
        control.updateValueAndValidity();
    }

    /**
     * Helper function used to generate the ISO 8601 datTime string with
     * 6 subsecond decimals that the BE is expecting.
     *
     * @param time {Moment}
     */
    private formatTime(time: Moment): string {
        return time.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
    }
}

export interface SetCallDateTimePanelData {
    editedCallType: CallType;
    selectedDateTime: Moment;
    selectedFundingRequest: FundingRequestRowViewModel;
}
