import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';

import { ContentFilterMenuComponent } from './content-filter-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatIconModule],
    declarations: [ContentFilterMenuComponent],
    exports: [ContentFilterMenuComponent],
    providers: [],
})
export class ContentFilterMenuModule {}
