import { Component, ViewChild } from '@angular/core';
import { CommunicationMethod } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { FundingRequestSummaryTableService } from '@services/funding-request-summary-table/funding-request-summary-table.service';

import { ConstantsObjectsAbstractFilter, ConstantsObjectsCommunicationMethodFilter } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Filters';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { CommunicationMethodPipe } from '@components/utils/pipes/communication-method.pipe';

@Component({
    selector: 'eb-communication-method-filter-menu',
    templateUrl: './communication-method-filter-menu.component.html',
    styleUrls: ['./communication-method-filter-menu.component.scss'],
})
export class CommunicationMethodFilterMenuComponent {
    @ViewChild('communicationMethodList', { static: true }) public communicationMethodList: MatSelectionList;
    public communicationMethod: CommunicationMethod[] = [CommunicationMethod.sms, CommunicationMethod.phone];


    public menuOpen = false;

    public numberOfFiltersApplied = 0;
    public hasFiltersApplied = false;

    // QA Config
    // TODO: any QA-specific ids/etc that are needed go here
    public constantsAbstractFilter: typeof ConstantsObjectsAbstractFilter = ConstantsObjectsAbstractFilter;
    public constantsCommunicationMethodFilter: typeof ConstantsObjectsCommunicationMethodFilter = ConstantsObjectsCommunicationMethodFilter;
    // ng lint is disabled to avoid member-ordering error
    /* eslint-disable */
    private readonly defaultButtonDisplayText: string = 'Communication Method';
    public buttonDisplayText: string = this.defaultButtonDisplayText;
    /* eslint-enable */
    constructor(private fundingRequestSummaryTableService: FundingRequestSummaryTableService, private communicationMethodPipe: CommunicationMethodPipe) {
        this.fundingRequestSummaryTableService.communicationListFilter.subscribe((list) => this.onUpdateMethod(list));
    }

    public menuToggled(isOpen: boolean): void {
        if (isOpen) {
            this.populateCurrentFilterState();
        }

        this.menuOpen = isOpen;
    }

    public onUpdateMethod(communicationMethodList: CommunicationMethod[]) {
        this.numberOfFiltersApplied = communicationMethodList.length;
        if (this.numberOfFiltersApplied > 0) {
            this.buttonDisplayText = this.communicationMethodPipe.transform(this.communicationMethod.find((x) => communicationMethodList.includes(x)));
            if (this.numberOfFiltersApplied > 1) {
                this.buttonDisplayText += ' +' + (this.numberOfFiltersApplied - 1);
            }
        } else {
            this.buttonDisplayText = this.defaultButtonDisplayText;
        }
        this.hasFiltersApplied = this.numberOfFiltersApplied !== 0;
    }

    public onApply(): void {
        const communicationMethodSelected: CommunicationMethod[] = this.communicationMethodList.selectedOptions.selected.map((x) => x.value);
        this.fundingRequestSummaryTableService.communicationListFilter.next(communicationMethodSelected);
        this.fundingRequestSummaryTableService.populateDataSource();
        this.menuOpen = !this.menuOpen;
    }

    private populateCurrentFilterState(): void {
        const { options, selectedOptions } = this.communicationMethodList;

        const selectedCommunications: CommunicationMethod[] = this.fundingRequestSummaryTableService.communicationListFilter.getValue();
        const optionsToSelect: MatListOption[] = options.filter((option: MatListOption) => selectedCommunications.includes(option.value));
        selectedOptions.clear();
        optionsToSelect.forEach((option) => selectedOptions.select(option));
    }
}
