
// ng lint insists to specify ng disable snippet
/* eslint-disable */
import { FactoryProvider } from '@angular/core';
import { Environment, Identity, IdentityMetadata } from 'environments/environment.interface';
import { ENVIRONMENT } from 'environments/environment.token';
import { Log, UserManager } from 'oidc-client';

export const userManagerFactory = (environment: Environment): UserManager => {
  const identity: Identity = environment.identity || {} as Identity;
  const metadata: IdentityMetadata = identity.metadata || {} as IdentityMetadata;

  Log.level = identity.logLevel;

  return new UserManager({
    authority: identity.authority,
    client_id: identity.clientId,
    scope: identity.scope,
    redirect_uri: identity.redirectUri,
    post_logout_redirect_uri: identity.postLogoutRedirectUri,
    response_type: identity.responseType,
    accessTokenExpiringNotificationTime: identity.accessTokenExpiringNotificationTime,
    loadUserInfo: identity.loadUserInfo,
    silent_redirect_uri: identity.silentRedirectUrl,
    automaticSilentRenew: identity.automaticSilentRenew,
    metadata: {
        issuer: metadata.issuer,
        authorization_endpoint: metadata.authorizationEndpoint,
        end_session_endpoint: metadata.endSessionEndpoint,
        jwks_uri: metadata.jwksUri,
    },
    monitorSession: true
  });
};

export const userManagerProvider: FactoryProvider = {
    provide: UserManager,
    useFactory: userManagerFactory,
    deps: [ENVIRONMENT]
};
