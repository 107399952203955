import { Inject, Injectable } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';

@Injectable()
export class FeaturesService {
    private features: Record<string, unknown>;

    constructor(@Inject(ENVIRONMENT) environment: Environment) {
        // ng lint insists to specify ng disable snippet
        // eslint-disable-next-line
        this.features = !!environment.features ? environment.features : {};
    }

    public isEnabled(featureName: string): boolean {
        return !!this.features[featureName];
    }
}
