import { Inject, Injectable } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';

import { LoggerService } from '@services/shared/logger/logger.abstract';

import { GoogleAnalyticsService } from './google-analytics.abstract';

@Injectable()
export class GoogleAnalyticsMockService extends GoogleAnalyticsService {
    constructor(private loggerService: LoggerService, @Inject(ENVIRONMENT) protected environment: Environment) {
        super(environment);
    }

    public sendApiCallEvent(apiLabel: string, httpMethod: string, endpointLabel: string, endpointUrl: string): void {
        this.loggerService.log(`GoogleAnalytics Service: sendApiCallEvent() for ${apiLabel} - ${httpMethod}: ${endpointLabel} (${endpointUrl})`);
    }

    public sendPageViewEvent(path: string): void {
        this.loggerService.log(`GoogleAnalytics Service: sendPageViewEvent() for ${path}`);
    }
}
