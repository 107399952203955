
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum ResponseStatus {
     /* eslint-disable */
    PENDING_STATUS_API_RESPONSE = 'pending', 
    HOLD_STATUS_API_RESPONSE = 'hold',
    APPROVED_STATUS_API_RESPONSE = 'approved',
    FUNDED_STATUS_API_RESPONSE = 'funded',
    CANCELED_STATUS_API_RESPONSE = 'canceled',
    INPROCESS_STATUS_API_RESPONSE = 'inProcess',
    WORKING_STATUS_API_RESPONSE = 'working',
    READYFORFUNDING_STATUS_API_RESPONSE = 'readyForFunding',
    NEW_STATUS_API_RESPONSE = 'new',
    /* eslint-enable */
}
