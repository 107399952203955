import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ConstantsAchFilesBar,
  ConstantsDashboardTablePagination
} from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';
import { SidePanelContent } from '@services/side-panel/models/side-panel.model';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';


@Component({
  selector: 'eb-ach-files',
  templateUrl: './ach-files.component.html',
  styleUrls: ['./ach-files.component.scss']
})
export class AchFilesComponent implements OnInit {
  @ViewChild('sideNav', { static: true }) public sideNav;
  @ViewChild('displayedComponent', { static: true }) public displayedComponent;

  public constantsAchFilesBar: typeof ConstantsAchFilesBar = ConstantsAchFilesBar;
  public constantsPagination: typeof ConstantsDashboardTablePagination = ConstantsDashboardTablePagination;

  // public displayedComponent: SidePanelContent;
  public sidePanelContent: typeof SidePanelContent = SidePanelContent;
  public fundingRequestModel: FundingRequestRowViewModel = {} as FundingRequestRowViewModel;
  public achAuthSectionHidden = false;
  public achFile: AchFileMetadata;
  public reloadAchFiles: boolean;

  constructor(private sidePanelService: SidePanelService,
    private permissionService: PermissionsService,
  ) { }

  ngOnInit() {
    this.sidePanelService.openCloseSideNav.subscribe((isSidePanelOpen) => {
      if (isSidePanelOpen) {
        this.openDrawer();
      } else {
        this.closeDrawer();
      }
    });
    this.sidePanelService.onSidePanelContentChanged.subscribe((sidePanelParameters) => {
      this.displayedComponent = sidePanelParameters.displayedComponent;
      this.fundingRequestModel = sidePanelParameters.fundingRequestModel;
    });
    this.sideNav.closedStart.subscribe(() => {
      if (this.displayedComponent !== SidePanelContent.none) {
        this.sidePanelService.onSidePanelContentChanged.next({ displayedComponent: SidePanelContent.none });
      }
      this.sidePanelService.onSideNavOpenClosed.next(false);
    });
    this.sideNav.openedStart.subscribe(() => this.sidePanelService.onSideNavOpenClosed.next(true));
    this.permissionService.canCreateAchFile$.subscribe((canCreate) => (this.achAuthSectionHidden = canCreate));
  }

  public newAchFileButtonClicked(): void {
    this.displayedComponent = this.sidePanelContent.achFileCreate;
    this.openDrawer();
  }

  public openDrawer(): void {
    this.sideNav.open();
  }

  public closeDrawer(): void {
    this.sideNav.close();
  }
  // ng lint insists to specify ng disable snippet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onUploadAchFileButtonClicked(data: any) {
    this.displayedComponent = data.content;
    this.achFile = data.achFile;
    this.openDrawer();
  }

  public refreshAchFilesTable() {
    this.reloadAchFiles = true;
  }
}
