import { Component, OnInit } from '@angular/core';
import { Status } from '@components/funding-request-summary-table/models/status.model';
import { FundingRequestCalculations } from '@components/shared/funding-request-calculations/funding-request-calculations';
import { FundingRequestService } from '@services/funding-request/funding-request.abstract';
import { FundingRequest } from '@services/funding-request/models/funding-request.model';
import { convertRawStatusResponseToStatus } from '@services/funding-request/models/response-status-converters';
import { UpdateFundingRequestComponentEnum, UpdateFundingRequestService } from '@services/update-funding-request/update-funding-request.service';
import { take } from 'rxjs/operators';

import { ConstantsObjectsLabelValuePair, ConstantsObjectsRadioButton } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { ConstantsGenericSidePanel } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import { ConstantStatusOverrideSection } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

import { UpdateFundingRequestCallTimeComponent } from './update-funding-request-call-time/update-funding-request-call-time.component';
import { UpdateFundingRequestStatusComponent } from './update-funding-request-status/update-funding-request-status.component';
import { combineLatest } from 'rxjs';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';

@Component({
    selector: 'eb-update-funding-request',
    templateUrl: './update-funding-request.component.html',
    styleUrls: ['./update-funding-request.component.scss'],
})
export class UpdateFundingRequestComponent implements OnInit {
    public authorizationStatus: string;
    public requestLocked: boolean;
    public fundingRequest: FundingRequest;
    public displayedApplicantName: string;
    public authorizationNum: string;
    public applicationNumber: string;

    public constantsObjectsRadioButton: typeof ConstantsObjectsRadioButton = ConstantsObjectsRadioButton;
    public constantsObjectsLabelValuePair: typeof ConstantsObjectsLabelValuePair = ConstantsObjectsLabelValuePair;
    public constantStatusOverrideSection: typeof ConstantStatusOverrideSection = ConstantStatusOverrideSection;
    public constantsGenericSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;

    public displayedComponent;

    constructor(public updateFundingRequestService: UpdateFundingRequestService, private fundingRequestService: FundingRequestService) {}

    ngOnInit(): void {
        this.displayedApplicantName = FundingRequestCalculations.getDisplayedApplicantName(this.updateFundingRequestService.selectedFundingRequest);
        this.authorizationNum = this.updateFundingRequestService.selectedFundingRequest.authorizationNum;
        this.applicationNumber = this.updateFundingRequestService.selectedFundingRequest.applicationNumber;

        combineLatest([
            this.fundingRequestService.getFundingRequestDetails(this.authorizationNum),
            this.updateFundingRequestService.selectedComponent
        ])
        .pipe(take(1))
        .subscribe(([request, component]: [FundingRequest, UpdateFundingRequestComponentEnum]) => {
            if (request && component) {
                const fundingRequestRowView = this.updateFundingRequestService.selectedFundingRequest;
                this.fundingRequest = request;
                this.authorizationStatus = convertRawStatusResponseToStatus(this.fundingRequest.status);
                this.requestLocked = this.authorizationStatus === Status.inProcess;
                fundingRequestRowView.createdsubject = request.createdSubject;
                this.updateFundingRequestService.selectedFundingRequest = fundingRequestRowView;
                switch (component) {
                    case UpdateFundingRequestComponentEnum.status:
                        this.displayedComponent = UpdateFundingRequestStatusComponent;
                        break;
                    case UpdateFundingRequestComponentEnum.callDateTime:
                        this.displayedComponent = UpdateFundingRequestCallTimeComponent;
                }
            }
        });
    }
}
