import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SidePanelTemplateComponent } from './side-panel-template.component';

@NgModule({
    imports: [MatIconModule],
    declarations: [SidePanelTemplateComponent],
    exports: [SidePanelTemplateComponent],
})
export class SidePanelTemplateModule {}
