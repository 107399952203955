import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { Status } from '@components/funding-request-summary-table/models/status.model';
import { FundingRequestCalculations } from '@components/shared/funding-request-calculations/funding-request-calculations';
import { FundingRequestFields, FundingRequestPatch } from '@services/funding-request/models/funding-request-patch.model';
import { convertStatusToApiString } from '@services/funding-request/models/response-status-converters';
import { UpdateFundingRequestService } from '@services/update-funding-request/update-funding-request.service';

import { ConstantsObjectsLabelValuePair, ConstantsObjectsRadioButton } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { ConstantsGenericSidePanel, ConstantStatusOverrideSection } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { User } from 'oidc-client';

@Component({
    selector: 'eb-update-funding-request-status',
    templateUrl: './update-funding-request-status.component.html',
    styleUrls: ['./update-funding-request-status.component.scss'],
})
export class UpdateFundingRequestStatusComponent implements OnInit, OnChanges {

    public statusControl: UntypedFormControl = new UntypedFormControl();

    public displayedApplicantName: string;
    public showApprovalCurrent: boolean;
    public showHoldCurrent: boolean;
    public showPendingCurrent: boolean;

    public canShowPendingOption: boolean;
    public canShowApprovedOption: boolean;
    public canShowHoldOption: boolean;
    public canShowCanceledOption: boolean;

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public status: any = Status;

    public constantsObjectsRadioButton: typeof ConstantsObjectsRadioButton = ConstantsObjectsRadioButton;
    public constantsObjectsLabelValuePair: typeof ConstantsObjectsLabelValuePair = ConstantsObjectsLabelValuePair;
    public constantStatusOverrideSection: typeof ConstantStatusOverrideSection = ConstantStatusOverrideSection;
    public constantsGenericSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;

    public displayedComponent = UpdateFundingRequestStatusComponent;
    public showErrorText = false;
    private user: User = null;

    constructor(public updateFundingRequestService: UpdateFundingRequestService, private authService: AuthService) { }

    public get fundingRequestModel(): FundingRequestRowViewModel {
        return this.updateFundingRequestService.selectedFundingRequest;
    }

    public set fundingRequestModel(fundingRequest: FundingRequestRowViewModel) {
        this.updateFundingRequestService.selectedFundingRequest = fundingRequest;
    }

    async ngOnInit() {
        this.user = this.authService.getUser();
        this.setDisplayValues();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.fundingRequestModel) {
            this.setDisplayValues();
        }
    }

    public onChangeStatusSubmit() {
        this.showErrorText = false;
        if (this.statusControl.value === Status.approved && this.user.profile.sub === this.fundingRequestModel.createdsubject.subjectId) {
            this.showErrorText = true;
            return;
        }

        this.updateFundingRequestService.saveFundingRequestChanges(this.fundingRequestModel.authorizationNum, [
            {
                field: FundingRequestFields.status,
                oldValue: convertStatusToApiString(this.fundingRequestModel.status),
                newValue: convertStatusToApiString(this.statusControl.value),
            } as FundingRequestPatch,
        ]);
    }

    private showCurrentText(option: Status, currentStatus: Status): boolean {
        return option === currentStatus;
    }

    private canDisplayStatusOption(option: Status, currentStatus: Status): boolean {
        let canDisplay = false;
        switch (option) {
            case Status.approved:
            case Status.hold:
                if (currentStatus === Status.approved || currentStatus === Status.hold || currentStatus === Status.pending) {
                    canDisplay = true;
                }
                break;
            case Status.canceled:
                if (currentStatus === Status.approved || currentStatus === Status.hold || currentStatus === Status.pending || currentStatus === Status.canceled) {
                    canDisplay = true;
                }
                break;
            case Status.pending:
                if (currentStatus === Status.pending) {
                    canDisplay = true;
                }
                break;
        }
        return canDisplay;
    }

    private setDisplayValues(): void {
        this.statusControl.setValue(this.fundingRequestModel.status);

        this.displayedApplicantName = FundingRequestCalculations.getDisplayedApplicantName(this.fundingRequestModel);
        this.showApprovalCurrent = this.showCurrentText(Status.approved, this.fundingRequestModel.status);
        this.showHoldCurrent = this.showCurrentText(Status.hold, this.fundingRequestModel.status);
        this.showPendingCurrent = this.showCurrentText(Status.pending, this.fundingRequestModel.status);

        this.canShowApprovedOption = this.canDisplayStatusOption(Status.approved, this.fundingRequestModel.status);
        this.canShowHoldOption = this.canDisplayStatusOption(Status.hold, this.fundingRequestModel.status);
        this.canShowCanceledOption = this.canDisplayStatusOption(Status.canceled, this.fundingRequestModel.status);
        this.canShowPendingOption = this.canDisplayStatusOption(Status.pending, this.fundingRequestModel.status);
    }
}
