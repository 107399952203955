import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CircleIconModule } from '../circle-icon/circle-icon.module';

import { RequestLockedComponent } from './request-locked.component';

@NgModule({
    imports: [CommonModule, MatIconModule, CircleIconModule],
    declarations: [RequestLockedComponent],
    exports: [RequestLockedComponent],
})
export class RequestLockedModule {}
