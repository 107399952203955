import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AnnouncementDialogColor, AnnouncementDialogData, AnnouncementDialogIcon } from '@components/shared/announcement-dialog/announcement-dialog.component';
import { AchService } from '@services/ach/ach.abstract';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';
import { AnnouncementDialogService } from '@services/announcement-dialog/announcement-dialog.service';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { Subject, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { take, takeUntil } from 'rxjs/operators';

import { ConstantsGenericSidePanel, ConstantUploadACHFilesSteps } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

@Component({
    selector: 'eb-upload-ach-file-panel',
    templateUrl: './upload-ach-file-panel.component.html',
    styleUrls: ['./upload-ach-file-panel.component.scss'],
})
export class UploadAchFilePanelComponent implements OnInit, OnDestroy {
    @Input() achFile: AchFileMetadata;
    public achFiles: AchFileMetadata[];
    public selectedTab = 0;
    public isUploadingAch = false;

    // For QA Automation
    public constantsGenericSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;
    public constantsACHSidePanel: typeof ConstantUploadACHFilesSteps = ConstantUploadACHFilesSteps;

    // ng lint insists to specify ng disable snippet
    /* eslint-disable-next-line */
    private cleanSubscriptions: Subject<any> = new Subject<any>();
    private achSelectedItems: AchFileMetadata[] = [];
    private achServiceSubscription: Subscription;
    private sidePanelSubscription: Subscription;

    constructor(private achService: AchService, private sidePanelService: SidePanelService, public announcementService: AnnouncementDialogService) {
        this.achService.achFilesReadyForUploadSubject.pipe(takeUntil(this.cleanSubscriptions)).subscribe((achFiles) => {
            this.achFiles = achFiles;
        });
        this.sidePanelService.onSideNavOpenClosed.pipe(takeUntil(this.cleanSubscriptions)).subscribe(() => {
            this.achService.updateAchFilesReadyForUpload(true, false);
        });
    }

    /** Returns private _selectedItems variable */
    public get selectedItems(): AchFileMetadata[] {
        return this.achSelectedItems;
    }

    /** Returns true if 1 or more items are selected */
    public get itemsSelected(): boolean {
        return this.selectedItems.length > 0;
    }

    ngOnInit() {
        this.achSelectedItems.push(this.achFile);
    }

    /** Cleaning up subscriptions on destroy */
    ngOnDestroy(): void {
        if (this.achServiceSubscription) {
            this.achServiceSubscription.unsubscribe();
        }
        if (this.sidePanelSubscription) {
            this.sidePanelSubscription.unsubscribe();
        }
        this.cleanSubscriptions.next();
    }

    /** When called, updates value of stored private _selectedItems variable */
    public onSelectedItems(items: AchFileMetadata[]): void {
        this.achSelectedItems = items;
    }

    /** Called when submit action is selected, and attempts to mark ACH files for upload
     * using the ACH API. If successful, opens success popup, if failed opens failed popup.
     */
    public markUploadAch(): void {
        this.isUploadingAch = true;
        // Submit ACH files to ACH API once completed.
        this.achService.achUploadFailuresSubject.pipe(take(1)).subscribe(() => {
            this.isUploadingAch = false;
            this.sidePanelService.openCloseSideNav.next(false);
            // Open some failed popup
            this.announcementService.openDialog({
                label: 'One or more failures',
                message: 'One or more items failed to submit',
                backgroundColor: AnnouncementDialogColor.grey,
                icon: AnnouncementDialogIcon.failure,
                primaryButtonText: 'Okay',
            } as AnnouncementDialogData);
        });
        this.achServiceSubscription = this.achService
            .markForUpload(this.selectedItems)
            .pipe(take(1))
            .subscribe(() => {
                this.isUploadingAch = false;
                this.sidePanelService.openCloseSideNav.next(false);
                // Open success popup
                this.announcementService.openDialog({
                    label: 'Success',
                    message: 'All items submitted',
                    backgroundColor: AnnouncementDialogColor.blue,
                    icon: AnnouncementDialogIcon.success,
                    primaryButtonText: 'Okay',
                } as AnnouncementDialogData);
            });
    }
}
