import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AnnouncementDialogComponent } from './announcement-dialog.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [AnnouncementDialogComponent],
    exports: [AnnouncementDialogComponent],
})
export class AnnouncementPopupModule {}
