import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnnouncementDialogComponent, AnnouncementDialogData } from '@components/shared/announcement-dialog/announcement-dialog.component';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class AnnouncementDialogService {
    public dialogClosedSubject: Subject<string> = new Subject<string>();

    constructor(private dialog: MatDialog) { }
    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openDialog(data: AnnouncementDialogData): Subject<any> {
        const dialogRef = this.dialog.open(AnnouncementDialogComponent, { width: '440px', data });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((action: string) => {
                this.dialogClosedSubject.next(action);
            });
        return this.dialogClosedSubject;
    }
}
