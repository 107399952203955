import { NgModule } from '@angular/core';

import { FundingRequestDetailsPageResolver } from './funding-request-details-page/funding-request-details-page.resolver';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [RouterModule],
    providers: [FundingRequestDetailsPageResolver],
})
export class ResolverModule {}
