// Used to find elements on auth details pages

export class ConstantsOverviewPage {
    public static headerSectionAuthCount = 'QA-OverviewPage-HeaderSectionAuthCount';
    public static tabSectionContainer = 'QA-OverviewPage-TabSectionContainer';
    public static tabSectionOverviewClass = 'mat-tab-label-0-0';
    public static tabSectionHistoryClass = 'mat-tab-label-0-1';
    public static lockedAuthContainer = 'QA-OverviewPage-LockedAuthContainer';
    public static lockedAuthIcon = 'QA-OverviewPage-LockedAuthIcon';
    public static lockedAuthType = 'QA-OverviewPage-LockedAuthType';
    public static lockedAuthMessage = 'QA-OverviewPage-LockedAuthMessage';

}

export class ConstantsOverviewDetailsSection {
    public static superContainer = 'QA-OverviewDetailsSection-SuperContainer';

    public static containerBaseInfo = 'QA-OverviewDetailsSection-ContainerBaseInfo';
    public static applicationNumber = 'QA-OverviewDetailsSection-ApplicationNumber';
    public static contractor = 'QA-OverviewDetailsSection-Contractor';
    public static applicant = 'QA-OverviewDetailsSection-Applicant';
    public static loanDetails =  'QA-OverviewDetailsSection-LoanDetails';
    public static containerMoneyInfo = 'QA-OverviewDetailsSection-ContainerMoneyInfo';
    public static requestedAmount = 'QA-OverviewDetailsSection-RequestedAmount';
    public static dealerFee = 'QA-OverviewDetailsSection-DealerFee';
    public static netProceeds = 'QA-OverviewDetailsSection-NetProceeds';
    public static exceptions = 'QA-OverviewDetailsSection-Exceptions';

    public static containerStatusInfo = 'QA-OverviewDetailsSection-ContainerStatusInfo';
    public static created = 'QA-OverviewDetailsSection-Created';
    public static lastCall = 'QA-OverviewDetailsSection-LastCall';
    public static nextCall = 'QA-OverviewDetailsSection-NextCall';
    public static status = 'QA-OverviewDetailsSection-Status';
    public static statusDetail = 'QA-OverviewDetailsSection-StatusDetail';

    public static containerOtherInfo = 'QA-OverviewDetailsSection-ContainerOtherInfo';
    public static contractorDBA = 'QA-OverviewDetailsSection-ContractorDBA';
    public static spectrumAccountNumber = 'QA-OverviewDetailsSection-SpectrumAccountNumber';
    public static communicationMethod = 'QA-OverviewDetailsSection-CommunicationMethod';
    public static contractorName = 'QA-OverviewDetailsSection-ContractorName';
    public static disbursement = 'QA-OverviewDetailsSection-Disbursement';
    public static applicantPhone = 'QA-OverviewDetailsSection-ApplicantPhone';
    public static pcTrigger = 'QA-OverviewDetailsSection-PcTrigger';
    public static pcLevel = 'QA-OverviewDetailsSection-PcLevel';
    public static applicantAddress = 'QA-OverviewDetailsSection-ApplicantAddress';
    public static coApplicantAddress = 'QA-OverviewDetailsSection-CoApplicantAddress';
    public static preferredPhone = 'QA-OverviewDetailsSection-PreferredPhone';
    public static timeToRespond = 'QA-OverviewDetailsSection-TimeToRespond';
    public static commitmentExpirationDate = 'QA-OverviewDetailsSection-CommitmentExpirationDate';
    public static isFinalDisbursement = 'QA-OverviewDetailsSection-IsFinalDisbursement';
    public static fundingNoticeMethod = 'QA-OverviewDetailsSection-FundingNoticeMethod';
}

export class ConstantsHistoryPage {
    public static eventColumnHeader = 'QA-HistoryPage-EventColumnHeader';
    public static phoneNumColumnHeader = 'QA-HistoryPage-PhoneNumColumnHeader';
    public static dateTimeColumnHeader = 'QA-HistoryPage-DateTimeColumnHeader';
    public static modifiedByColumnHeader = 'QA-HistoryPage-ModifiedByColumnHeader';
    public static messageColumnHeader = 'QA-HistoryPage-MessageColumnHeader';
    public static statusColumnHeader = 'QA-HistoryPage-StatusColumnHeader';
}

export class ConstantsHistoryRow {
    public static rowContainer = 'QA-HistoryRow-RowContainer';
    public static mobileIcon = 'QA-HistoryRow-MobileIcon';
    public static eventRecordStatus = 'QA-HistoryRow-EventRecordStatus';
    public static eventRecordBy = 'QA-HistoryRow-EventRecordBy';
    public static phoneNumRecord = 'QA-HistoryRow-PhoneNumRecord';
    public static dateTimeRecord = 'QA-HistoryRow-DateTimeRecord';
    public static modifiedByText = 'QA-HistoryRow-ModifiedByText';
    public static modifiedBySubText = 'QA-HistoryRow-ModifiedBySubText';
    public static messageStatus = 'QA-HistoryRow-MessageStatus';
    public static message = 'QA-HistoryRow-Message';
}
