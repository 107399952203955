import { Inject, Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client';

import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';

import { AuthService } from './auth.abstract';

@Injectable()
export class AuthMockService extends AuthService {

    private user: User = null;

    constructor(@Inject(ENVIRONMENT) protected environment: Environment) {
        super(environment);
    }

    public initialize(newUser: User): void {
        this.setUser(newUser);
    }

    public setUser(newUser: User): void {
        this.user = newUser;
    }

    public getUser(): User {
        return { profile: { name: 'MockUser', sub: 'ffffffff-ffff-ffff-ffff-ffffffffffff' } } as User;
    }

    public isAuthenticated(): boolean {
        return true;
    }

    public startAuthentication(): Promise<void> {
        return Promise.resolve();
    }

    public async isUserLoggedIn(): Promise<boolean> {
        return Promise.resolve(true);
    }

    public async shouldRefreshToken(): Promise<boolean> {
        return Promise.resolve(false);
    }

    public async getAccessToken(): Promise<string> {
        return Promise.resolve('token');
    }

    public async getIdToken(): Promise<string> {
        return Promise.resolve('token');
    }

    public async loginUser(): Promise<void> {
        return Promise.resolve();
    }

    public async logoutUser(): Promise<void> {
        return Promise.resolve();
    }
}
