import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';

import { LoanService } from './loan.abstract';
import { LoanMockService } from './loan.mock-service';
import { LoanApiService } from './loan.service';

const loanFactory = (http: HttpClient, environment: Environment, authService: AuthService, googleAnalyticsService: GoogleAnalyticsService) => environment.mockServicesEnabled ? new LoanMockService(environment, http) : new LoanApiService(http, environment, authService, googleAnalyticsService);

export const loanServiceProvider: FactoryProvider = {
    deps: [HttpClient, ENVIRONMENT, AuthService, GoogleAnalyticsService],
    provide: LoanService,
    useFactory: loanFactory,
};
