import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CircleIconComponent } from './circle-icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [CircleIconComponent],
    exports: [CircleIconComponent],
})
export class CircleIconModule {}
