import { Component, Input } from '@angular/core';

import { ConstantsObjectsEllipsisField } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';

@Component({
    selector: 'eb-funding-request-summary-table-truncate',
    templateUrl: './funding-request-summary-table-truncate.component.html',
    styleUrls: ['./funding-request-summary-table-truncate.component.scss'],
})
export class FundingRequestSummaryTableTruncateComponent {
    @Input() public content: string;
    @Input() public maxLength = 20;
    // https://material.angular.io/components/tooltip/overview
    // Information under "Tooltip with custom position"
    @Input() public tooltipPosition = 'right';

    public constantsHeaderEllipsis: typeof ConstantsObjectsEllipsisField = ConstantsObjectsEllipsisField;
}
