<eb-side-panel-template *ngIf="displayedComponent" [attr.data-automation]="constantsGenericSidePanel.sidePanelContainer">
    <ng-container title-content>
      <div class="update-container">
        <div class="update-nav-title" [attr.data-automation]="constantsGenericSidePanel.header">Edit Authorization</div>
        <div class="update-detail-container" [attr.data-automation]="constantsGenericSidePanel.applicationNumber">
          <div class="update-detail-title" [attr.data-automation]="constantsObjectsLabelValuePair.label">APP #:</div>
          <div class="update-detail-text" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{applicationNumber}}</div>
        </div>
        <div class="update-detail-container" [attr.data-automation]="constantsGenericSidePanel.applicantName">
          <div class="update-detail-title" [attr.data-automation]="constantsObjectsLabelValuePair.label">APPLICANT INFO:</div>
          <div class="update-detail-text" [attr.data-automation]="constantsObjectsLabelValuePair.value">{{displayedApplicantName}}</div>
        </div>
      </div>
    </ng-container>
    <ng-container banner-content *ngIf="requestLocked">
      <eb-request-locked class="request-locked" [fundingRequest]="fundingRequest"></eb-request-locked>
    </ng-container>
    <ng-container main-content>
      <ng-content *ngComponentOutlet="displayedComponent"></ng-content>
    </ng-container>
</eb-side-panel-template>
