import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CircleIconModule } from '../circle-icon/circle-icon.module';

import { FundingExceptionIconGroupComponent } from './funding-exception-icon-group.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [CommonModule, CircleIconModule, MatTooltipModule],
    declarations: [FundingExceptionIconGroupComponent],
    exports: [FundingExceptionIconGroupComponent],
})
export class FundingExceptionIconGroupModule {}
