<div class="container">
  <mat-card>
    <div  [attr.data-automation]="constantsNoResultsFound.noResultsFoundContainer" class="sub-container">
      <div class="icon-container">
        <mat-icon class="icon" [attr.data-automation]="constantsNoResultsFound.magnifyGlass">search</mat-icon>
      </div>
      <div class="no-results-text" [attr.data-automation]="constantsNoResultsFound.noResultFoundText">No results found.</div>
      <div class="please-text" [attr.data-automation]="constantsNoResultsFound.updateSearchText">Please update your search and filter criteria.</div>
      <a href="#" (click)="onClearClicked()" class="clear-filters-button" [attr.data-automation]="constantsNoResultsFound.clearSearch">Clear search</a>
    </div>
  </mat-card>
</div>
