import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AchFileTileModule } from '@components/shared/ach-file-tile/ach-file-tile.module';

import { ReviewAndSubmitComponent } from './review-and-submit.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';

@NgModule({
    imports: [CommonModule, MatCardModule, MatListModule, MatIconModule, AchFileTileModule],
    declarations: [ReviewAndSubmitComponent],
    exports: [ReviewAndSubmitComponent],
})
export class ReviewAndSubmitModule {}
