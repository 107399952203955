import { NgModule } from '@angular/core';

import { ObjectKeysPipe } from './object-keys/object-keys.pipe';
import { OrdinalPipe } from './ordinal/ordinal.pipe';
import { CommunicationMethodPipe } from './communication-method.pipe';

@NgModule({
    imports: [],
    declarations: [ObjectKeysPipe, OrdinalPipe, CommunicationMethodPipe],
    exports: [ObjectKeysPipe, OrdinalPipe, CommunicationMethodPipe],
    providers: [],
})
export class PipesModule {}
