// Contains values for page element IDs found on the Funding Management dashboard page

export class ConstantsDashboardMisc {
    public static createACHButton = 'QA-Dashboard-AuthorizationCount';
    public static pageContainer = 'QA-Dashboard-PageContainer';
    public static searchAndFilterSectionContainer = 'QA-Dashboard-SearchAndFilterSectionContainer';
}

export class ConstantsDashboardAuthorizationsTable {
    public static container = 'QA-Dashboard-AuthTable-Container';
    public static headerAuthIcon = 'QA-Dashboard-AuthTable-headerAuthIcon';
    public static headerApplicationNumber = 'QA-Dashboard-AuthTable-headerApplicationNumber';
    public static headerContractor = 'QA-Dashboard-AuthTable-headerContractor';
    public static headerContractorState = 'QA-Dashboard-AuthTable-headerContractorState';
    public static headerApplicant = 'QA-Dashboard-AuthTable-headerApplicant';
    public static headerApplicantState = 'QA-Dashboard-AuthTable-headerApplicantState';
    public static headerCreatedDate = 'QA-Dashboard-AuthTable-headerCreatedDate';
    public static headerCommitmentExpirationDate = 'QA-Dashboard-AuthTable-headerCommitmentExpirationDate';
    public static headerLastCall = 'QA-Dashboard-AuthTable-headerLastCall';
    public static headerNextCall = 'QA-Dashboard-AuthTable-headerNextCall';
    public static headerStatus = 'QA-Dashboard-AuthTable-headerStatus';
    public static headerStatusDetail = 'QA-Dashboard-AuthTable-headerStatusDetail';
    public static headerException = 'QA-Dashboard-AuthTable-headerException';
}

export class ConstantsDashboardAuthorizationRow {
    public static rowContainer = 'QA-Dashboard-Authorization-rowContainer';
    public static lockedByAch = 'QA-Dashboard-Authorization-lockedByAch';
    public static lockedByAchIcon = 'QA-Dashboard-Authorization-lockedByAchIcon';
    public static lockedByUser = 'QA-Dashboard-Authorization-lockedByUser';
    public static lockedByUserIcon = 'QA-Dashboard-Authorization-lockedByUserIcon';
    public static applicationNumber = 'QA-Dashboard-Authorization-applicationNumber';
    public static positiveConfirmationLevel = 'QA-Dashboard-Authorization-positiveConfirmationLevel';
    public static contractor = 'QA-Dashboard-Authorization-contractor';
    public static applicant = 'QA-Dashboard-Authorization-applicant';
    public static applicantState = 'QA-Dashboard-Authorization-applicantState';
    public static createdDate = 'QA-Dashboard-Authorization-createdDate';
    public static commitmentExpirationDate = 'QA-Dashboard-Authorization-commitmentExpirationDate';
    public static lastCallContainer = 'QA-Dashboard-Authorization-lastCallContainer';
    public static nextCallContainer = 'QA-Dashboard-Authorization-nextCallContainer';
    public static status = 'QA-Dashboard-Authorization-status';
    public static statusText = 'QA-Dashboard-Authorization-statusText';
    public static statusDropDownArrow = 'QA-Dashboard-Authorization-statusDropDownArrow';
    public static statusDetail = 'QA-Dashboard-Authorization-statusDetail';
    public static actionIcon = 'QA-Dashboard-Authorization-actionIcon';
    public static authIcon = 'QA-Dashboard-Authorization-authIcon';

// ng lint insists to specify ng disable snippet
    /* eslint-disable-next-line */
    public static Value = 'QA-Dashboard-Authorization-callTimeValue';
    public static editPencilIcon = 'QA-Dashboard-Authorization-editPencilIcon';
    public static setTimeLinkLastCall = 'QA-Dashboard-Authorization-setTimeLinkLastCall';
    public static setTimeLinkNextCall = 'QA-Dashboard-Authorization-setTimeLinkNextCall';
    public static fundingNoticeMethod = 'QA-Dashboard-Authorization-fundingNoticeMethod';
}

// This section maps the header Id with corresponding column Id
export class ConstantsDashboardAuthorizationsHeaderColumnMap {
    public static constantsAppHeader: typeof ConstantsDashboardAuthorizationsTable = ConstantsDashboardAuthorizationsTable;
    public static constantsAppRow: typeof ConstantsDashboardAuthorizationRow = ConstantsDashboardAuthorizationRow;
    public static headerColumnMap = new Map([
        [
            ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerApplicationNumber,
            ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.applicationNumber,
        ],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerContractor, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.contractor],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerApplicant, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.applicant],
        [
            ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerApplicantState,
            ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.applicantState,
        ],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerCreatedDate, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.createdDate],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerCommitmentExpirationDate, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.commitmentExpirationDate],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerLastCall, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.lastCallContainer],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerNextCall, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.nextCallContainer],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerStatus, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.statusText],
        [ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppHeader.headerStatusDetail, ConstantsDashboardAuthorizationsHeaderColumnMap.constantsAppRow.statusDetail],
    ]);
}

export class ConstantsActionMenuOptions {
    public static actionMenuContainer = 'QA-Dashboard-ActionMenu-actionMenuContainer';
    public static statusOverride = 'QA-Dashboard-ActionMenu-statusOverride';
    public static viewAuthorizationHistory = 'QA-Dashboard-ActionMenu-viewAuthorizationHistory';
}

export class ConstantsDashboardSearchSection {
    public static searchSectionContainer = 'QA-Dashboard-Search-Section-SearchContainer';
    public static searchBoxContainer = 'QA-Dashboard-Search-Section-SearchBox';
    public static searchInputBox = 'QA-Dashboard-Search-Section-SearchInputBox';
    public static searchIcon = 'QA-Dashboard-Search-Section-SearchIcon';
    public static searchClearIcon = 'QA-Dashboard-Search-Section-SearchClearXButton';
    public static searchMagnifyingGlassIcon = 'QA-Dashboard-Search-Section-SearchMagnifyingGlassIcon';
    public static searchBarPlaceholderText = 'QA-Dashboard-Search-Section-Placeholder-Text';
    public static searchButtonTag = 'ng-reflect-color';
    public static searchButtonState = 'primary';
    public static searchBoxPlaceholderText = 'data-placeholder';
}

export class ConstantsDashboardFilters {
    public static filterSectionContainer = 'QA-StatusFilterRow';
    public static filterSectionListIcon = 'QA-StatusFilterRow-SectionListIcon';
    public static iconTextPairContainer = 'QA-IconTextPair-Container';
    public static icon = 'QA-IconTextPair-Icon';
    public static text = 'QA-IconTextPair-Text';
    public static pending = 'QA-IconTextPair-Container-Pending';
    public static hold = 'QA-IconTextPair-Container-Hold';
    public static approved = 'QA-IconTextPair-Container-Approved';
    public static clearAllFilters = 'QA-IconTextPair-ClearAllFilters';
    public static communicationMethod = 'QA-IconTextPair-Container-CommunicationMethod';
    public static fundingNoticeMethod = 'QA-IconTextPair-Container-FundingNoticeMethod';
    public static closedAuth = 'QA-IconTextPair-ClosedAuth';
    public static status = 'QA-IconTextPair-Status';
}

export class ConstantsFundingRequestBar {
    public static container = 'QA-FundingRequestBar-Container';
    public static authorizationCount = 'QA-FundingRequestBar-AuthorizationCount';
    public static achContainer = 'QA-FundingRequestBar-achContainer';
    public static achText = 'QA-FundingRequestBar-achText';
    public static newAuthButton = 'QA-FundingRequestBar-newAuthButton';
    public static achFilesButton = 'QA-FundingRequestBar-achFilesButton';
    public static fundingRequestsButton = 'QA-FundingRequestBar-fundingRequestButton';
    public static achDropdownElement = 'QA-FundingRequestBar-achDropdownElement';
    public static achDropdownOptionsContainer = 'QA-FundingRequestBar-achDropdownOptionsContainer';

    public static dropOptionCreateACH = 'QA-FundingRequestBar-dropOptionCreateACH';
    public static dropOptionMarkFilesUploaded = 'QA-FundingRequestBar-dropOptionMarkFilesUploaded';
}

export class ConstantsCommunicationMethodFilters {
    public static communicationMethodMenuContainer = 'QA-Menu-Container-communicationMethodMenuContainer';
    public static phoneCall = 'QA-CheckBox-PhoneCall';
    public static textMessage = 'QA-CheckBox-TextMessage';
    public static applyButton = 'QA-Menu-Button-ApplyButton';
}

export class ConstantsNoResultsFound {
    public static noResultsFoundContainer = 'QA-Dashboard-AuthTable-NoResultsFoundContainer';
    public static magnifyGlass = 'QA-Dashboard-AuthTable-MagnifyGlass';
    public static noResultFoundText = 'QA-Dashboard-AuthTable-NoResultFoundText';
    public static updateSearchText = 'QA-Dashboard-AuthTable-UpdateSearchText';
    public static clearSearch = 'QA-Dashboard-AuthTable-ClearSearch';
}

// Used to find the elements in the loan table's pagination
export class ConstantsDashboardTablePagination {
    public static container = 'QA-TablePagination-Container';
    public static containerClass = 'ng-trigger-transformPanel';
    public static recordsPerPageText = 'mat-paginator-page-size-label';
    public static recordsPerPageDropdown = 'mat-select-value-text';
    public static fundingRequestCountOptionsClass = 'mat-option-text';
    public static loanCountPositionText = 'mat-paginator-range-label';
    public static goToFirstPageIcon = 'mat-paginator-navigation-first';
    public static goToPreviousPageIcon = 'mat-paginator-navigation-previous';
    public static goToNextPageIcon = 'mat-paginator-navigation-next';
    public static goToLastPageIcon = 'mat-paginator-navigation-last';
}

export class ConstantsStatusFilters {
    public static communicationMethodMenuContainer = 'QA-Menu-Container-statusMenuContainer';
    public static hold = 'QA-CheckBox-Hold';
    public static pending = 'QA-CheckBox-Pending';
    public static approved = 'QA-CheckBox-Approved';
    public static new = 'QA-CheckBox-New';
    public static readyToFund = 'QA-CheckBox-ReadyToFund';
}

export class ConstantsAchFilesBar {
    public static achFilesBar = 'QA-AchFiles-Bar';
    public static newAchFileButton = 'QA-CreateAchFile-Button';
    public static pageContainer = 'QA-AchFiles-PageContainer';
    public static achFilesTable = 'QA-AchFilesTable';
}

export class ConstantsAchFilesTable {
    public static achFileId = 'QA-AchFilesTable-AchFileId';
    public static achFileDatetime = 'QA-AchFilesTable-AchDateTime';
    public static achUserName = 'QA-AchFilesTable-AchUsername';
    public static achStatus = 'QA-AchFilesTable-AchStatus';
    public static achActions = 'QA-AchFilesTable-AchActions';
    public static resetAchFileButton = 'QA-AchFilesTable-AchResetButton';
    public static regenerateAchFileButton = 'QA-AchFilesTable-AchRegenerateButton';
    public static uploadAchFileButton = 'QA-AchFilesTable-AchUploadButton';
    public static viewAchFileAuditButton = 'QA-AchFilesTable-AchViewAuditButton';
    public static headersAchFileId = 'QA-AchFilesTable-HeadersAchFileId';
    public static headersDateTime = 'QA-AchFilesTable-HeadersDateTime';
    public static headersUsername = 'QA-AchFilesTable-HeadersUsername';
    public static headersStatus = 'QA-AchFilesTable-HeadersStatus';
    public static headersReset = 'QA-AchFilesTable-HeadersReset';
    public static headersRegenerate = 'QA-AchFilesTable-HeadersRe';
    public static headersUpload = 'QA-AchFilesTable-HeadersUpload';
}
