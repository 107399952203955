/* eslint-disable no-shadow */
import { FundingAuthorizationSummary } from '@services/funding-request/funding-authorization.model';
import { Applicant } from '@services/funding-request/models/applicant.model';
import { FundingRequest, CreatedSubject } from '@services/funding-request/models/funding-request.model';
import { convertRawStatusResponseToStatus } from '@services/funding-request/models/response-status-converters';
import { Moment } from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';
import { Status } from './status.model';

export enum StatusDetail {
    contractor = 'Contractor',
    admin = 'Admin',
    pc = 'PC',
    age = 'Age',
    customer = 'Customer',
    undelivered = 'Undelivered Text',
}

export enum CommunicationMethod {
    sms,
    email,
    phone
}

export enum FundingNoticeMethod {
    request = 'Request',
    notification = 'Notification',
}

export enum FundingException {
    manualFunding = 'ManualFunding',
    nachaWait = 'NachaWait',
    rightOfRescission = 'RightOfRescission',
    frozen = 'Frozen',
}

export enum PositiveConfirmationDisbursementLevel {
    none = 'None',
    first = 'First',
    final = 'Final',
    all = 'All',
}
// ng lint is disabled to avoid fulluppercase lint error
/* eslint-disable */
// Communication Methods
const SMS_COMMUNICATION_METHOD_API_RESPONSE = 'sms';
const PHONE_COMMUNICATION_METHOD_API_RESPONSE = 'phone';

// Funding Notice Methods
const NOTIFICATION_FUNDING_NOTICE_METHOD_API_RESPONSE = 'notification';
const REQUEST_FUNDING_NOTICE_METHOD_API_RESPONSE = 'request';

// Status Details
const ADMIN_DETAIL_API_RESPONSE = 'admin';
const PC_DETAIL_API_RESPONSE = 'pc';
const AGE_DETAIL_API_RESPONSE = 'age';
const CUSTOMER_DETAIL_API_RESPONSE = 'customer';
const CONTRACTOR_DETAIL_API_RESPONSE = 'contractor';
const UNDELIVERED_SMS_EXCEPTION_API_RESPONSE = 'undelivered';

// Funding Exceptions
export const MANUAL_FUNDING_EXCEPTION_API_RESPONSE = 'manual_funding';
export const NACHA_WAIT_EXCEPTION_API_RESPONSE = 'nacha_wait';
export const RIGHT_OF_RESCISSION_EXCEPTION_API_RESPONSE = 'right_of_rescission';
export const FROZEN_EXCEPTION_API_RESPONSE = 'frozen';

// Positive Confirmation Disbursement Levels
export const NONE_DISBURSEMENT_LEVEL_API_RESPONSE = 'none';
export const FIRST_DISBURSEMENT_LEVEL_API_RESPONSE = 'first';
export const FINAL_DISBURSEMENT_LEVEL_API_RESPONSE = 'final';
export const ALL_DISBURSEMENT_LEVEL_API_RESPONSE = 'all';

/* eslint-enable */

// Converts API based string to a StatusDetail
export const convertRawDetailResponseToStatusDetail = (str: string): StatusDetail => {
    switch (str) {
        case ADMIN_DETAIL_API_RESPONSE:
            return StatusDetail.admin;
        case PC_DETAIL_API_RESPONSE:
            return StatusDetail.pc;
        case AGE_DETAIL_API_RESPONSE:
            return StatusDetail.age;
        case CUSTOMER_DETAIL_API_RESPONSE:
            return StatusDetail.customer;
        case CONTRACTOR_DETAIL_API_RESPONSE:
            return StatusDetail.contractor;
        case UNDELIVERED_SMS_EXCEPTION_API_RESPONSE:
            return StatusDetail.undelivered;
    }
};

const communicationRawResponseToEnumMap = {
    [PHONE_COMMUNICATION_METHOD_API_RESPONSE]: CommunicationMethod.phone,
    [SMS_COMMUNICATION_METHOD_API_RESPONSE]: CommunicationMethod.sms,
};


const fundingNoticeMethodRawResponseToEnumMap = {
    [NOTIFICATION_FUNDING_NOTICE_METHOD_API_RESPONSE]: FundingNoticeMethod.notification,
    [REQUEST_FUNDING_NOTICE_METHOD_API_RESPONSE]: FundingNoticeMethod.request,
};

// Converts API based string to a CommunicationMethod
export const convertRawFundingNoticeMethodResponseToFundingNoticeMethod = (str: string): FundingNoticeMethod => fundingNoticeMethodRawResponseToEnumMap[str];

// Converts CommunicationMethod to API based string
export const convertFundingNoticeMethodToRawFundingNoticeMethodResponse = (cm: FundingNoticeMethod): string => {
    const reverseMap = {};

    for (const apiResponse of Object.keys(fundingNoticeMethodRawResponseToEnumMap)) {
        reverseMap[fundingNoticeMethodRawResponseToEnumMap[apiResponse]] = apiResponse;
    }

    return reverseMap[cm];
};

export const convertRawPositiveConfirmationDisbursementLevelToEnum = (disbursementLevel: string): PositiveConfirmationDisbursementLevel => {
    switch (disbursementLevel) {
        case NONE_DISBURSEMENT_LEVEL_API_RESPONSE:
            return PositiveConfirmationDisbursementLevel.none;
        case FIRST_DISBURSEMENT_LEVEL_API_RESPONSE:
            return PositiveConfirmationDisbursementLevel.first;
        case FINAL_DISBURSEMENT_LEVEL_API_RESPONSE:
            return PositiveConfirmationDisbursementLevel.final;
        case ALL_DISBURSEMENT_LEVEL_API_RESPONSE:
            return PositiveConfirmationDisbursementLevel.all;
        default:
            return null;
    }
};

// Converts API based string to a Funding Exception
export const convertRawFundingExceptionResponseToFundingException = (str: string): FundingException => {
    switch (str) {
        case MANUAL_FUNDING_EXCEPTION_API_RESPONSE:
            return FundingException.manualFunding;
        case NACHA_WAIT_EXCEPTION_API_RESPONSE:
            return FundingException.nachaWait;
        case RIGHT_OF_RESCISSION_EXCEPTION_API_RESPONSE:
            return FundingException.rightOfRescission;
        case FROZEN_EXCEPTION_API_RESPONSE:
            return FundingException.frozen;
        default:
            return null;
    }
};

export const createFundingExeptionTrackerFromTags = (
    tags: string[],
    failedToFund: boolean,
    status: string,
    reamortizationDate: Moment,
    closedToPostingCode: string,
    accountStatus: string
): FundingExceptionTracker => {
    const fundingExceptionTracker: FundingExceptionTracker = {
        manualFunding: false,
        nachaWait: false,
        rightOfRescission: false,
        frozen: false,
        failedToFund: false,
        pastReamortizationDate: false,
        blockedBySpectrum: false,
    };

    if (status === 'hold' && reamortizationDate < moment()) {
        fundingExceptionTracker.pastReamortizationDate = true;
    }
    if (closedToPostingCode || accountStatus.toLowerCase() === 'closed') {
        fundingExceptionTracker.blockedBySpectrum = true;
    }
    if (tags && tags.length) {
        for (const tag of tags) {
            const fundingExceptionTag = convertRawFundingExceptionResponseToFundingException(tag);

            // if null, then it's not a Funding Exception
            switch (fundingExceptionTag) {
                case FundingException.manualFunding:
                    fundingExceptionTracker.manualFunding = true;
                    break;
                case FundingException.nachaWait:
                    fundingExceptionTracker.nachaWait = true;
                    break;
                case FundingException.rightOfRescission:
                    fundingExceptionTracker.rightOfRescission = true;
                    break;
                case FundingException.frozen:
                    fundingExceptionTracker.frozen = true;
                    break;
            }
        }
    } else if (
        status === 'approved' &&
        failedToFund &&
        !fundingExceptionTracker.manualFunding &&
        !fundingExceptionTracker.nachaWait &&
        !fundingExceptionTracker.rightOfRescission &&
        !fundingExceptionTracker.frozen
    ) {
        fundingExceptionTracker.failedToFund = true;
    }
    return fundingExceptionTracker;
};

// View Model representation of a Funding Request
export interface FundingRequestRowViewModel {
    applicationNumber: string;
    positiveConfirmationLevel: number;
    contractor: string;
    contractorState: string;
    applicantName: string;
    applicantState: string;
    applicantCommunicationMethod: CommunicationMethod;
    createdDate: Moment;
    lastCall: Moment;
    nextCall: Moment;
    status: Status;
    statusDetail: StatusDetail;
    authorizationNum: string;
    loanId: string;
    currentEditingBy?: string;
    dealerFees: number;
    netProceeds: number;
    fundingExceptions: FundingExceptionTracker;
    spectrumAccountNumber: string;
    createdsubject: CreatedSubject;
    fundingNoticeMethod: string;
}

export interface FundingExceptionTracker {
    manualFunding: boolean;
    nachaWait: boolean;
    rightOfRescission: boolean;
    frozen: boolean;
    failedToFund: boolean;
    pastReamortizationDate: boolean;
    blockedBySpectrum: boolean;
}

export const createNameStringFromApplicant = (applicant: Applicant): string => {
    if (!applicant) {
        return null;
    }

    if (applicant.firstName && applicant.lastName) {
        return `${applicant.firstName} ${applicant.lastName}`;
    }

    return applicant.firstName ? applicant.firstName : applicant.lastName;
};

export const createFormalNameString = (firstName: string, lastName: string): string => {
    if (lastName && firstName) {
        return `${lastName}, ${firstName}`;
    }

    return firstName ? firstName : lastName;
};

// Creates a new RowViewModel from a raw API Funding Authorization SUMMARY Response.
export const createFundingRequestRowViewModelFromFundingAuthorizationSummaryResponse = (
    fundingAuthorizationSummary: FundingAuthorizationSummary
): FundingRequestRowViewModel => ({
    applicationNumber: fundingAuthorizationSummary.applicationNumber,
    positiveConfirmationLevel: fundingAuthorizationSummary.positiveConfirmation,
    contractor: fundingAuthorizationSummary.contractor,
    contractorState: fundingAuthorizationSummary.contractorState,
    applicantName: createFormalNameString(fundingAuthorizationSummary.applicantFirstName, fundingAuthorizationSummary.applicantLastName),
    applicantState: fundingAuthorizationSummary.applicantState,
    applicantCommunicationMethod: fundingAuthorizationSummary.communicationMethod,
    createdDate: moment(fundingAuthorizationSummary.createdDate),
    lastCall: moment(fundingAuthorizationSummary.lastCall),
    nextCall: moment(fundingAuthorizationSummary.nextCall),
    status: convertRawStatusResponseToStatus(fundingAuthorizationSummary.status),
    statusDetail: convertRawDetailResponseToStatusDetail(fundingAuthorizationSummary.statusDetail),
    authorizationNum: fundingAuthorizationSummary.authorizationNumber,
    loanId: fundingAuthorizationSummary.loanId,
    fundingNoticeMethod: fundingAuthorizationSummary.fundingNoticeMethod,
    // TODO: These should eventually be removed
    currentEditingBy: 'NONE', // fundingAuthorizationSummary.currentEditingBy,
    dealerFees: 0, // fundingAuthorizationSummary.dealerFees,
    netProceeds: 0, // fundingAuthorizationSummary.netProceeds,
    fundingExceptions: createFundingExeptionTrackerFromTags(
        fundingAuthorizationSummary.tags,
        fundingAuthorizationSummary.failedToFund,
        fundingAuthorizationSummary.status,
        moment(fundingAuthorizationSummary.reamortizationDate),
        fundingAuthorizationSummary.closedToPostingCode,
        fundingAuthorizationSummary.accountStatus
    ),
    spectrumAccountNumber: fundingAuthorizationSummary.spectrumAccountNumber,
    createdsubject: {} as CreatedSubject,
});

// TODO: implement or use library that acts like the nameof keyword in C# to make this strongly typed
// rather than hardcoded string keys and values.
// addresses discrepancy between row view model name and the name of the field on a funding authorization summary response
// as some functionally (such as sorting) need to pass in the name of the field as on the response model
export const getFieldNameOnFundingAuthorizationSummaryResponseFromFundingRequestRowViewModel = (rowViewModelFieldName: string): string => {
    const viewModelNamesToFASummaryNames = {
        applicationNumber: 'applicationNumber',
        positiveConfirmationLevel: 'positiveConfirmation',
        contractor: 'contractor',
        contractorState: 'contractorState',
        applicantName: 'applicantLastName', // this is just whichever is presented first visually
        applicantState: 'applicantState',
        applicantCommunicationMethod: 'communicationMethod',
        createdDate: 'createdDate',
        lastCall: 'lastCall',
        nextCall: 'nextCall',
        status: 'status',
        statusDetail: 'statusDetail',
        authorizationNum: 'authorizationNumber',
        loanId: 'loanId',
        fundingExceptions: 'tags',
        fundingNoticeMethod: 'fundingNoticeMethod',
    };

    return viewModelNamesToFASummaryNames[rowViewModelFieldName];
};

// Creates a new RowViewModel from a raw API Funding Request Response.
// TODO: This is just a stopgap since there's update functionality that takes in a row view model derived
// from the response model that is returned when retrieving "Funding Request Details" aka (a Funding Authorization/Request By ID)
// Also the terminology should probably be straightened across the board, since FMS API returns what they call a Funding Authorization
// and they also have a Funding Request
// The update functionality should be using some other domain model, and not co-opting a view model
export const createFundingRequestRowViewModelFromFundingRequestResponse = (fundingRequest: FundingRequest): FundingRequestRowViewModel => ({
    applicationNumber: fundingRequest.applicationNumber,
    positiveConfirmationLevel: fundingRequest.positiveConfirmationLevel,
    contractor: fundingRequest.contractor,
    contractorState: fundingRequest.contractorState,
    applicantName: createFormalNameString(fundingRequest.applicant.firstName, fundingRequest.applicant.lastName),
    applicantState: fundingRequest.applicant.state,
    // TODO: Communication method will eventually be moved back to the top level due to some consistency issues
    applicantCommunicationMethod: fundingRequest.applicant.communicationMethod,
    createdDate: moment(fundingRequest.createdDate),
    lastCall: moment(fundingRequest.lastCall),
    nextCall: moment(fundingRequest.nextCall),
    status: convertRawStatusResponseToStatus(fundingRequest.status),
    statusDetail: convertRawDetailResponseToStatusDetail(fundingRequest.statusDetail),
    authorizationNum: fundingRequest.authorizationNumber,
    loanId: fundingRequest.loanId,
    fundingNoticeMethod: fundingRequest.fundingNoticeMethod,
    fundingExceptions: createFundingExeptionTrackerFromTags(
        fundingRequest.tags,
        fundingRequest.failedToFund,
        fundingRequest.status,
        moment(fundingRequest.reamortizationDate),
        fundingRequest.closedToPostingCode,
        fundingRequest.accountStatus
    ),
    // TODO: There will be a task to remove these fields here as they aren't used
    currentEditingBy: fundingRequest.currentEditingBy,
    dealerFees: fundingRequest.dealerFees,
    netProceeds: fundingRequest.netProceeds,
    spectrumAccountNumber: fundingRequest.spectrumAccountNumber,
    createdsubject: {} as CreatedSubject,
});
