import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatepickerModule } from '@components/datepicker/datepicker.module';
import { CircleIconModule } from '@components/shared/circle-icon/circle-icon.module';
import { FundingExceptionIconGroupModule } from '@components/shared/funding-exception-icon-group/funding-exception-icon-group.module';
import { FundingRequestActionsModule } from '@components/shared/funding-request-actions/funding-request-actions.module';
import { UpdateFundingRequestCallTimeModule } from '@components/update-funding-request/update-funding-request-call-time/update-funding-request-call-time.module';
import { MatPaginatorIntlCustom } from '@services/funding-request-summary-table/mat-paginator-intl-custom.service';

import { FundingRequestSummaryTableTruncateModule } from './funding-request-summary-table-truncate/funding-request-summary-table-truncate.module';
import { FundingRequestSummaryTableComponent } from './funding-request-summary-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        DatepickerModule,
        FundingRequestSummaryTableTruncateModule,
        MatCardModule,
        MatMenuModule,
        MatPaginatorModule,
        MatTableModule,
        MatIconModule,
        MatSortModule,
        MatTooltipModule,
        MatRippleModule,
        UpdateFundingRequestCallTimeModule,
        FundingExceptionIconGroupModule,
        FundingRequestActionsModule,
        CircleIconModule,
    ],
    declarations: [FundingRequestSummaryTableComponent],
    exports: [FundingRequestSummaryTableComponent],
    providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom }],
})
export class FundingRequestSummaryTableModule {}
