import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum SidePanelContent {
    none = 'none',
    updateFundingRequest = 'updateFundingRequest',
    authorizationDetails = 'authorizationDetails',
    createNewAuthorization = 'createNewAuthorization',
    achFileCreate = 'achFileCreate',
    achFileUpload = 'achFileUpload',
    achFileViewAudit = 'achFileViewAudit',
}

export interface SidePanelParameters {
    displayedComponent: SidePanelContent;
    fundingRequestModel?: FundingRequestRowViewModel;
}
