import { Pipe, PipeTransform } from "@angular/core";
import { CommunicationMethod } from "@components/funding-request-summary-table/models/funding-request-table.models";

@Pipe({ name: 'communicationMethod' })
export class CommunicationMethodPipe implements PipeTransform {
    transform(communicationMethod: CommunicationMethod | string): string {
        switch (communicationMethod) {
            case CommunicationMethod.sms:
            case CommunicationMethod[CommunicationMethod.sms]:
              return 'Text';
              case CommunicationMethod.email:
            case CommunicationMethod[CommunicationMethod.email]:
                return 'Email';
            case CommunicationMethod.phone:
            case CommunicationMethod[CommunicationMethod.phone]:
                return 'Phone call';
            default:
              return '';
        }
    }
}