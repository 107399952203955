// ng lint insists to specify ng disable snippet
/* eslint-disable */
export enum RoutingData {
    breadcrumb = 'breadcrumb',
    breadcrumbCustomPath = 'breadcrumbCustomPath',
    actionType = 'actionType',
}

export enum RoutingPaths {
    fundingRequestSummaries = 'funding-requests',
    achFiles = 'ach-files'
}

export enum RoutingResolvers {
    fundingRequestDetailsPageResolver = 'fundingRequestDetailsPageResolver',
}
/* eslint-disable-next-line */
export enum CallType {
    next = 'Next',
    last = 'Last',
}
/* eslint-disable-next-line */
export enum HttpMethods {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH',
}
/* eslint-disable-next-line */
export enum GoogleAnalyticsEventNames {
    pageView = 'page_view',
    webApiInvocation = 'web_api_invocation',
}
/* eslint-disable-next-line */
export enum PermissionEnum {
    canCreateACHRequests = 'FMS_CanCreateACHRequests',
}
/* eslint-disable-next-line */
export enum ValidationMessages {
    dateAndTimeValuesRequired = 'Date and time values are required.',
    lastCallTooFarInPast = 'Last call cannot be more than 24 months prior to today\'s date.',
    lastCallIsInFuture = 'Last call date/time cannot be set in the future.',
    nextCallIsBeforeLastCall = 'Next Call must be later than Last Call.',
    tooLongBetweenCalls = 'Next Call cannot be more than 24 months from the Last Call.',
    nextCallTooFarInFuture = 'Next Call cannot be more than 24 months from today\'s date.',
    nextCallInPast = 'Next Call cannot be earlier than the current date and time.',
}
/* eslint-disable-next-line */
export enum LoanStatus {
    requestPending = 'Request Pending',
    readyToFund = 'Ready to Fund',
}
/* eslint-disable-next-line */
export enum ApiLoanStatusResponse {
    requestPending = 'RequestPending',
    readyToFund = 'ReadyToFund',
}

export const uuidFormat = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';
