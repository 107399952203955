import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DatepickerModule } from '@components/datepicker/datepicker.module';

import { DateTimeInputComponent } from './date-time-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [DateTimeInputComponent],
    exports: [DateTimeInputComponent],
    imports: [CommonModule, ReactiveFormsModule, DatepickerModule, MatFormFieldModule, MatInputModule],
})
export class DateTimeInputModule {}
