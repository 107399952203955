import { EnvironmentName } from './environment-name.enum';

export interface Environment {
    apiBaseUrl: string;
    mdBaseUrl: string;
    servicingWeb: string;
    name: EnvironmentName;
    prodMode: boolean;
    mockServicesEnabled?: boolean;
    identity?: Identity;
    features: Record<string, unknown>;
    achApi: AchApi;
}

export interface Identity {
    authority: string;
    clientId: string;
    scope: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    responseType: string;
    accessTokenExpiringNotificationTime: number; // in seconds
    loadUserInfo: boolean; // disable an automatic call to the metadata endpoint again after logging in
    silentRedirectUrl: string;
    automaticSilentRenew: boolean;
    logLevel: number;

    /*
    If we want to implement silent token refreshing:
    https://www.scottbrady91.com/OpenID-Connect/Silent-Refresh-Refreshing-Access-Tokens-when-using-the-Implicit-Flow
    automaticSilentRenew: true,
    silent_redirect_uri: environment.identity.silentRedirectUrl,
    silentRequestTimeout: 10000,
  */

    // without the metadata property being assigned manually, the library will first invoke an endpoint
    // pertaining to IdentityServer's metadata (retrieves what all the various auth endpoints on it are),
    // but by populating it manually, then it won't make the call
    metadata: IdentityMetadata;
}

export interface IdentityMetadata {
    issuer: string;
    authorizationEndpoint: string;
    endSessionEndpoint: string;
    jwksUri: string;
}

export interface AchApi {
    enabled: boolean;
    serviceUrl: string;
}
