import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SidePanelContent, SidePanelParameters } from './models/side-panel.model';

@Injectable()
export class SidePanelService {
    public readonly onSidePanelContentChanged: BehaviorSubject<SidePanelParameters> = new BehaviorSubject<SidePanelParameters>({ displayedComponent: SidePanelContent.none });
    public readonly onSideNavOpenClosed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly openCloseSideNav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
