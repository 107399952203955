import { Inject, Injectable } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { Moment } from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

import { AchService } from './ach.abstract';
import { AchCreationStatus, AchFileGeneratedStatus } from './achapi.service';
import { AchEstimates } from './models/ach-estimates.model';
import { AchFileMetadata, AchFileMetadataDTO } from './models/ach-file-metadata.model';
import { mockAchEstimates } from './testing/mock-ach-data';

@Injectable({
    providedIn: 'root',
})
export class AchMockService extends AchService {
    public achFilesReadyForUploadSubject: BehaviorSubject<AchFileMetadata[]> = new BehaviorSubject<AchFileMetadata[]>([]);
    public achFilesAllSubject: BehaviorSubject<AchFileMetadata[]> = new BehaviorSubject<AchFileMetadata[]>([]);
    public achFilesSubmittedSubject: BehaviorSubject<AchFileMetadata[]> = new BehaviorSubject<AchFileMetadata[]>([]);
    public achFileGeneratedSubject: BehaviorSubject<AchFileGeneratedStatus> = new BehaviorSubject<AchFileGeneratedStatus>(null);
    public achEstimatesSubject: BehaviorSubject<AchEstimates> = new BehaviorSubject<AchEstimates>(undefined);

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public achEstimatesErrorSubject: Subject<any> = new Subject<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public achUploadFailuresSubject: Subject<any> = new Subject<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public achUploadCompleteSubject: Subject<any> = new Subject<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public achFileCancelSubject: Subject<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public achFileRegenerateSubject: Subject<any> = new Subject<any>();
    // Used for testing failed ACH uploads
    public failACHUpload = false;
    private cachedAchFiles: AchFileMetadata[] = [];
    private isAchGenerationInProgress = false;

    constructor(@Inject(ENVIRONMENT) environment: Environment) {
        super(environment, null, null);
        const achFileMetaData = [
            {
                id: '8e213505-b499-46c8-a3b9-f01b96a12a66',
                createdAt: moment().add(-1, 'days'),
                createdBy: 'Hannibal Lecter',
                status: 'Completed',
                submitted: true,
            },
            {
                id: 'ed3c6855-5373-4b13-a2e0-7b159cbc8f90',
                createdAt: moment(),
                createdBy: 'Clarice Starling',
                status: 'New',
                submitted: false,
            },
            {
                id: 'A7d785551-1cea-495e-aa5d-b6ee2d17de06',
                createdAt: moment().add(-2, 'days'),
                createdBy: 'Buffalo Bill',
                status: 'Failed',
                submitted: true,
            },
        ] as AchFileMetadata[];
        this.cachedAchFiles = [];
        this.cachedAchFiles = this.cachedAchFiles.concat(achFileMetaData);
    }

    public get achGenerationInProgress(): boolean {
        return this.isAchGenerationInProgress;
    }

    /** Updates the current list of ACH Files ready to be uploaded to the Fed */
    public updateAchFilesReadyForUpload(useSubmitted: boolean, submitted: boolean): void {
        let achFilesFilter = [...this.cachedAchFiles];
        this.achFilesAllSubject.next(achFilesFilter);
        if (useSubmitted && !submitted) {
            achFilesFilter = achFilesFilter.filter((a) => a.status === 'New');
            this.achFilesReadyForUploadSubject.next(achFilesFilter);
        } else if (useSubmitted && submitted) {
            achFilesFilter = achFilesFilter.filter((a) => a.status === 'Completed');
            this.achFilesSubmittedSubject.next(achFilesFilter);
        }
    }

    /** Sends a request to upload the given ACH files to the Fed */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public markForUpload(filesToMark: AchFileMetadata[]): Subject<any> {
        this.updateAchFilesReadyForUpload(true, false);
        if (this.failACHUpload) {
            this.achUploadFailuresSubject.next();
        }
        setTimeout(() => {
            this.achUploadCompleteSubject.next();
        }, 1000);
        filesToMark.forEach((f) => {
            f.status = 'Completed';
        });
        return this.achUploadCompleteSubject;
    }

    /** Returns mock subject that flips to true after 4 seconds */
    public createAchFile(achFile: AchFileMetadata): BehaviorSubject<AchFileGeneratedStatus> {
        this.isAchGenerationInProgress = true;
        setTimeout(() => {
            this.isAchGenerationInProgress = false;
            // Checking for a certain date so this can be tested with mock configuration enabled
            if (achFile.createdAt.format('MM-DD-YY') === '11-11-11') {
                this.achFileGeneratedSubject.error('error');
                achFile.status = 'Failed';
                achFile.submitted = true;
                this.achFileGeneratedSubject = new BehaviorSubject<AchFileGeneratedStatus>({ creationStatus: AchCreationStatus.failed, hasFile: false });
            } else if (achFile.createdAt.format('MM-DD-YY') === '12-12-12') {
                this.achFileGeneratedSubject.error(AchCreationStatus.failed);
                achFile.submitted = true;
                achFile.status = 'Failed';
                this.achFileGeneratedSubject = new BehaviorSubject<AchFileGeneratedStatus>({ creationStatus: AchCreationStatus.failed, hasFile: false });
            } else if (achFile.createdAt.format('MM-DD-YY') === '08-01-20') {
                achFile.submitted = true;
                achFile.status = 'Completed';
                this.cachedAchFiles = this.cachedAchFiles.concat([achFile]);
                this.achFileGeneratedSubject.next({ creationStatus: AchCreationStatus.completed, hasFile: false } as AchFileGeneratedStatus);
            } else {
                achFile.submitted = false;
                achFile.status = 'New';
                this.cachedAchFiles = this.cachedAchFiles.concat([achFile]);
                this.achFileGeneratedSubject.next({ creationStatus: AchCreationStatus.completed, hasFile: true } as AchFileGeneratedStatus);
            }
        }, 4000);
        return this.achFileGeneratedSubject;
    }

    public updateAchEstimates(approvalDate: Moment): void {
        let estimates: AchEstimates;
        const mockEstimates = mockAchEstimates.find((achMockData) => moment(achMockData.approvedOn, 'YYYY-MM-DD') <= approvalDate);
        if (mockEstimates) {
            estimates = mockEstimates.estimates;
        }
        setTimeout(() => {
            if (estimates) {
                this.achEstimatesSubject.next(estimates);
            } else {
                this.achEstimatesErrorSubject.next();
            }
        }, 2000);
    }

    stopSubscriptionsAndReset(): void {
        this.achFileGeneratedSubject.next({ creationStatus: AchCreationStatus.failed, hasFile: false } as AchFileGeneratedStatus);
        this.achEstimatesSubject.next(undefined);
        this.isAchGenerationInProgress = false;
    }

    public cancelAchFile(achFileId: string): void {
        const achFile = this.cachedAchFiles.find((a) => a.id === achFileId);
        achFile.status = 'Canceled';
    }

    public regenerateAchFile(achFileId: string): void {
        const achFile = this.cachedAchFiles.find((a) => a.id === achFileId);
        achFile.status = 'Transferred';
        achFile.createdAt = moment(new Date());
    }
}
