<button mat-raised-button (click)="toggleMenu($event.currentTarget)" [class.active]="active" [id]="filterButtonId">
  <span style="font-weight: 500;" [attr.data-automation]="constantsAbstractFilter.filterButtonText">   <!-- Added this span for automated testing-->
    {{buttonText}}
  </span>
  <mat-icon iconPositionEnd [attr.data-automation]="constantsAbstractFilter.filterButtonArrow">keyboard_arrow_down</mat-icon>
</button>

<mat-card *ngIf="menuOpen" class="content-filter-menu"
  [style.left.px]="filterX"
  [style.height.px]="height"
  [style.top.px]="filterY"
  [style.width.px]="width"
  [attr.data-automation]="constantsAbstractFilter.openFilterContainer">
  <ng-content></ng-content>
</mat-card>
<div *ngIf="menuOpen" class="content-filter-menu-overlay" (click)="clickedOutsideCardContent()" [attr.data-automation]="constantsAbstractFilter.filterOverlay"></div>
