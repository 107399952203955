import { FundingRequestEvent } from '../models/funding-request-event.model';
import { FundingRequestHistory } from '../models/funding-request-history-event-model';

export const mockFundingRequestHistory: FundingRequestHistory = {
    fundingRequestHistories :  [
    {
        eventName: 'Approved',
        eventDetails: 'By Admin',
        phoneNumber: null,
        createdDate: new Date(),
        createdSubject: {
            subjectId: '89D371DC-F1F7-5C07-BE70-1136E001B713',
            name: 'Maurice Araica',
            givenName: null,
            familyName: null,
            userPrincipalName: 'DAFowles@enerbankusa.com',
            createdDate: '2020-04-22T16:25:01.94+00:00'
        },
        applicant: {
            firstName: 'Matt',
            lastName: 'Test',
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            communicationMethod: null,
        }
    },
    {
        eventName: 'Approved',
        eventDetails: 'By PC',
        phoneNumber: null,
        createdDate: new Date(),
        createdSubject: {
            subjectId: '89D371DC-F1F7-5C07-BE70-1136E001B719',
            name: 'John Doe',
            givenName: null,
            familyName: null,
            userPrincipalName: null,
            createdDate: '2020-04-22T16:25:01.94+00:00'
        },
        applicant: {
            firstName: 'Daniel',
            lastName: 'Test',
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            communicationMethod: null,
        }
    },
    {
        eventName: 'Approved',
        eventDetails: 'By Contractor',
        phoneNumber: null,
        createdDate: new Date(),
        createdSubject: {
            subjectId: '89D371DC-F1F7-5C07-BE70-1136E001B714',
            name: 'Frances McClary',
            givenName: null,
            familyName: null,
            userPrincipalName: 'userPrincipalName',
            createdDate: '2020-04-22T16:25:01.94+00:00'
        },
        applicant: {
            firstName: 'Cort',
            lastName: 'Test',
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            communicationMethod: null,
        }
    },
    {
        eventName: 'Hold',
        eventDetails: 'By Age',
        phoneNumber: '(987) 654-3210',
        createdDate: new Date(),
        createdSubject: {
            subjectId: '89D371DC-F1F7-5C07-BE70-1136E001B711',
            name: 'Jello Biafra',
            givenName: null,
            familyName: null,
            userPrincipalName: null,
            createdDate: '2020-04-22T16:25:01.94+00:00'
        },
        applicant: {
            firstName: 'Brock',
            lastName: 'Test',
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            communicationMethod: null,
        }
    },
    {
        eventName: 'Cancelled',
        eventDetails: 'By Customer',
        phoneNumber: '(123) 456-7890',
        createdDate: new Date(),
        createdSubject: {
            subjectId: '89D371DC-F1F7-5C07-BE70-1136E001B716',
            name: 'East Bay Ray',
            givenName: null,
            familyName: null,
            userPrincipalName: null,
            createdDate: '2020-04-22T16:25:01.94+00:00'
        },
        applicant: {
            firstName: 'Jeff',
            lastName: 'Test',
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            communicationMethod: null,
        }
    },
    {
        eventName: 'Invalid Response',
        eventDetails: 'By event details',
        phoneNumber: '(607) 596-1029',
        createdDate: new Date(),
        createdSubject: {
            subjectId: '89D371DC-F1F7-5C07-BE70-1136E001B712',
            name: 'D.H. Peligro',
            givenName: null,
            familyName: null,
            userPrincipalName: null,
            createdDate: '2020-04-22T16:25:01.94+00:00'
        },
        applicant: {
            firstName: 'Troy',
            lastName: 'Test',
            phoneNumber: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,
            communicationMethod: null,
        }
    },
], messageStatusHistories : [
    {
        recipient: '+18888888888',
        messageDate: new Date('2020-04-22T16:25:01.94+00:00'),
        status: 'delivered',
        message: 'Thank you for your approval. We will fund this request',
        sender: '+15555555555',
        messageType: 'text',
        subject: null,
        errorCode: null,
        errorDescription: null
    },
    {
        recipient: 'lfhlkjfhsdlfjkshflakjsdfha;slfhasdjftest@enerbankusa.com',
        messageDate: new Date('2020-04-22T16:25:01.94+00:00'),
        status: 'delivered',
        message: '<p>Bonnie Bulow,</p><p>EnerBank USA has received a funding request for your home improvement project for $4,403.00. This amount will be funded to your contractor and added to your loan the next business day after 4:00 PM ET.</p><p>If you have any questions or concerns about this funding request, please call <a href="tel:+1-866-359-7500">866.359.7500.</a></p>Hours of operation (ET):<br/>Mon-Fri: 8:00 AM – 1:00 AM<br/>Sat-Sun: 11:00 AM – 7:00 PM<br/><br/>Sincerely,<br/> EnerBank USA<br/>Loan Operations Center<br/><br/><p>Please do not reply to this message. This message is sent from an unmonitored inbox.</p><p>Electronic Privacy Notice: This email contains information that is, or may be covered by electronic communications privacy laws, and is also confidential and proprietary in nature. If you are not the intended recipient, please be advised that you are legally prohibited from retaining, using, copying, distributing, or otherwise disclosing information in any manner.</p>',
        sender: 'noreply@enerbankusa.com',
        messageType: 'email',
        subject: 'This is an email subject',
        errorCode: null,
        errorDescription: null
    },
    {
        recipient: '+18888888888',
        messageDate: new Date('2020-04-22T16:25:01.94+00:00'),
        status: 'failed',
        message: 'Thank you. We will not process this request until we speak with you.  Please call us at (888)390-1220',
        sender: '+15555555555',
        messageType: 'text',
        subject: null,
        errorCode: 35642,
        errorDescription: 'Test error description'
    },
    {
        recipient: '+15555555555',
        messageDate: new Date('2020-04-22T16:25:01.94+00:00'),
        status: 'accepted',
        message: 'yes',
        sender: '+18888888888',
        messageType: 'text',
        subject: null,
        errorCode: null,
        errorDescription: null
    }
]
};
