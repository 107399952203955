import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';

import { FundingRequestHistoryComponent } from './funding-request-history.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    imports: [CommonModule, MatTableModule, MatSortModule],
    declarations: [FundingRequestHistoryComponent],
    exports: [FundingRequestHistoryComponent],
})
export class FundingRequestHistoryModule {}
