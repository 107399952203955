<div class="nav-bar">
  <mat-toolbar color="primary" class="nav-toolbar">
    <mat-toolbar-row>
      <div class="title-logo">
        <a [routerLink]="[baseRoute]">
          <img src="../../../assets/icons/Regions-Logo-Mark-Only_White.svg" class="eb-icon" [attr.data-automation]="constantsHeader.title"/>
        </a>
        <span class="main-title">FUNDING MANAGEMENT SYSTEM</span>
      </div>
      <div class="nav-links" style="margin-right: 10px !important;">
        <a [routerLink]="achFilesRoute" class="nav-link" [attr.data-automation]="constantsFundingRequestBar.achFilesButton">ACH Files</a>
        <a [routerLink]="baseRoute" class="nav-link" [attr.data-automation]="constantsFundingRequestBar.fundingRequestsButton">Funding Requests</a>
      </div>
      <div class="upper-right-corner-nav nav-link">{{name}} |
        <a href="#" (click)="onClickLogout()" [attr.data-automation]="constantsHeader.logOutButton">Log Out</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
