import { Inject, Injectable } from '@angular/core';
import { GoogleAnalyticsEventNames } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { LoggerService } from '@services/shared/logger/logger.abstract';

import { WINDOW } from '@services/shared/window/window.provider';

import { GoogleAnalyticsService } from './google-analytics.abstract';

@Injectable()
export class GoogleAnalyticsApiService extends GoogleAnalyticsService {
    private googleAnalyticsTrackingId = '';

    private eventConst = 'event';

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(protected logger: LoggerService, @Inject(ENVIRONMENT) environment: Environment, @Inject(WINDOW) protected window: any) {
        super(environment);

        this.googleAnalyticsTrackingId = this.window.googleAnalyticsTrackingId;
    }

    public sendPageViewEvent(path: string): void {
        // 'page_view' is a built-in Google Analytics event for page view tracking, separate from typical custom "events"
        const eventName: string = GoogleAnalyticsEventNames.pageView;
        // ng lint insists to specify ng disable snippet
        /* eslint-disable */
        this.window.gtag(this.eventConst, eventName, {
            send_to: this.googleAnalyticsTrackingId,
            page_path: path,

            // should probably be read from config to minimize use of things outside of the Angular context,
            // but that will require changes to the environment configs at a later time
            page_location: this.window.location.href,
        });
        /* eslint-enable */

        this.logger.log(`${eventName} event sent to Google Analytics for ${path}`);
    }

    public sendApiCallEvent(apiLabel: string, httpMethod: string, endpointLabel: string, endpointUrl: string): void {
        const eventName: string = GoogleAnalyticsEventNames.webApiInvocation;
        const action = `${apiLabel} - ${httpMethod}: ${endpointLabel}`;
        // ng lint insists to specify ng disable snippet
        /* eslint-disable */
        // custom event
        this.window.gtag(this.eventConst, eventName, {
            send_to: this.googleAnalyticsTrackingId,
            event_category: `${this.envName} - Web API Invocations`,
            event_action: action,
            event_label: endpointUrl,
        });
        /* eslint-enable */

        this.logger.log(`${eventName} event sent to Google Analytics for ${action} at ${endpointUrl}`);
    }
}
