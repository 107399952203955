import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { Status } from '@components/funding-request-summary-table/models/status.model';
import { FundingRequestSummaryTableService } from '@services/funding-request-summary-table/funding-request-summary-table.service';
import { SidePanelContent } from '@services/side-panel/models/side-panel.model';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { BehaviorSubject } from 'rxjs';

import {
    ConstantsDashboardAuthorizationsTable,
    ConstantsDashboardFilters,
    ConstantsDashboardMisc,
    ConstantsDashboardSearchSection,
    ConstantsFundingRequestBar,
} from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';
import { PermissionsApiService } from '@services/shared/permissions/permissions.service';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';
import { MatDialog } from '@angular/material/dialog';

interface DashboardStatusFilterButton {
    status: Status;
    buttonName?: string;
    filterState?: BehaviorSubject<boolean>;
}
@Component({
    selector: 'eb-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    @ViewChild('sideNav', { static: true }) public sideNav;
    public constantsDashboard: typeof ConstantsDashboardMisc = ConstantsDashboardMisc;
    public constantsFundingRequestBar: typeof ConstantsFundingRequestBar = ConstantsFundingRequestBar;
    public constantsFilters: typeof ConstantsDashboardFilters = ConstantsDashboardFilters;
    public constantsSearch: typeof ConstantsDashboardSearchSection = ConstantsDashboardSearchSection;
    public constantsAppTable: typeof ConstantsDashboardAuthorizationsTable = ConstantsDashboardAuthorizationsTable;

    public displayedComponent: SidePanelContent;
    public sidePanelContent: typeof SidePanelContent = SidePanelContent;
    public fundingRequestModel: FundingRequestRowViewModel = {} as FundingRequestRowViewModel;
    public hasActiveFilters: boolean;
    public hasNoFilteredResults = false;
    public achAuthSectionHidden = false;
    public searchPlaceholderText = 'Search - Auth. number, application number, account number, or applicant name';
    public searchBarWidth = '800px';
    public fundingRequestData = false;

    public readonly numFundingRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public readonly numFilteredFundingRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    private hasSearchValue = false;

    constructor(
        public dialog: MatDialog,
        private fundingRequestSummaryTableService: FundingRequestSummaryTableService,
        private sidePanelService: SidePanelService,
        private permissionService: PermissionsService,
    ) {
        this.numFundingRequests = this.fundingRequestSummaryTableService.numTotalFundingRequests;
        this.numFilteredFundingRequests = this.fundingRequestSummaryTableService.numFilteredFundingRequests;

        this.numFundingRequests.subscribe(() => this.determineNoResultsFoundVisibility());

        this.numFilteredFundingRequests.subscribe(() => this.determineNoResultsFoundVisibility());
    }

    public get hideClosed(): boolean {
        return this.fundingRequestSummaryTableService.hideClosed;
    }

    public get hideCloseText(): string {
        return this.hideClosed ? 'Closed Auth Hidden' : 'Closed Auth Shown';
    }

    public onSearchInputChanged(searchValue: string): void {
        this.fundingRequestData = false;
        this.hasSearchValue = searchValue !== '';
        this.fundingRequestSummaryTableService.filterBySearch(searchValue);
    }

    public onClearSearchAndFilters(): void {
        this.fundingRequestData = false;
        this.fundingRequestSummaryTableService.clearAllFilters();
        this.fundingRequestSummaryTableService.clearSearchBar();
    }

    public ngOnInit(): void {
        this.hasNoFilteredResults = false;
        this.sidePanelService.openCloseSideNav.subscribe((isSidePanelOpen) => {
            if (isSidePanelOpen) {
                this.openDrawer();
            } else {
                this.closeDrawer();
            }
        });
        this.sidePanelService.onSidePanelContentChanged.subscribe((sidePanelParameters) => {
            this.displayedComponent = sidePanelParameters.displayedComponent;
            this.fundingRequestModel = sidePanelParameters.fundingRequestModel;
        });
        this.sideNav.closedStart.subscribe(() => {
            if (this.displayedComponent !== SidePanelContent.none) {
                this.sidePanelService.onSidePanelContentChanged.next({ displayedComponent: SidePanelContent.none });
            }
            this.sidePanelService.onSideNavOpenClosed.next(false);
        });
        this.sideNav.openedStart.subscribe(() => this.sidePanelService.onSideNavOpenClosed.next(true));
        this.fundingRequestData = false;
        this.fundingRequestSummaryTableService.hasActiveFilters.subscribe((hasActiveFilters) => (this.hasActiveFilters = hasActiveFilters));
        this.permissionService.canCreateAchFile$.subscribe((canCreate) => (this.achAuthSectionHidden = canCreate));
    }

    public clearActiveFilters(): void {
        this.fundingRequestSummaryTableService.clearAllFilters();
    }

    public closeDrawer(): void {
        this.sideNav.close();
    }

    public openDrawer(): void {
        this.sideNav.open();
    }

    public newAuthButtonClicked(): void {
        this.sidePanelService.onSidePanelContentChanged.next({
            displayedComponent: SidePanelContent.createNewAuthorization,
            fundingRequestModel: this.fundingRequestModel,
        });
        this.sidePanelService.openCloseSideNav.next(true);
    }

    public onDataLoaded(value: boolean) {
        this.fundingRequestData = true;
    }

    private determineNoResultsFoundVisibility(): void {
        if (this.numFundingRequests.value === 0 || ((this.hasActiveFilters || this.hasSearchValue) && this.numFilteredFundingRequests.value === 0)) {
            this.hasNoFilteredResults = true;
        } else {
            this.hasNoFilteredResults = false;
        }
    }
}
