import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { RoutingData } from '@constants';
import { FundingRequestService } from '@services/funding-request/funding-request.abstract';
import { FundingRequest } from '@services/funding-request/models/funding-request.model';

@Injectable()
export class FundingRequestDetailsPageResolver  {
    constructor(private fundingRequestService: FundingRequestService) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<FundingRequest> {
        return this.fundingRequestService.getFundingRequestDetails(route.paramMap.get('authorizationNum')).pipe(
            mergeMap((fundingRequest: FundingRequest) => {
                // add data dynamically to the route configuration
                route.routeConfig.data = {
                    [RoutingData.breadcrumb]: `${fundingRequest.applicant.firstName} ${fundingRequest.applicant.lastName}`,
                    [RoutingData.breadcrumbCustomPath]: fundingRequest.authorizationNumber,
                };

                return of(fundingRequest);
            })
        );
    }
}
