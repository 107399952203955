import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpMethods, PermissionEnum } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { Observable } from 'rxjs';

import { AuthService } from '../auth/auth.abstract';

import { UserAuthorization } from './permissions.provider';
import { PermissionsService } from './permissions.abstract';
import { take, tap, map } from 'rxjs/operators';

@Injectable()
export class PermissionsApiService extends PermissionsService {
    private apiLabel = 'Permissions API';

    constructor(@Inject(ENVIRONMENT) environment: Environment, http: HttpClient, authService: AuthService, private googleAnalyticsService: GoogleAnalyticsService) {
        super(environment, http, authService);
    }

    public initialize(): Observable<void> {
        return this.getAuthorizations().pipe(
            take(1),
            map((authorizations) => {
                this.canCreateAchFile.next(authorizations.permissions.includes(PermissionEnum.canCreateACHRequests));
            })
        );
    }

    private getAuthorizations(): Observable<UserAuthorization> {
        const url = `${this.apiBaseUrl}/authorization`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.post, 'Get Authorizations', url);
        return this.endpointGet<UserAuthorization>(url);
    }
}
