import { Component, forwardRef, Injectable, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment, MomentInput } from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const CUSTOM_DATE_FORMAT = 'MM/DD/YY';
export const CUSTOM_DATEPICKER_FORMATS = {
    parse: {
        dateInput: CUSTOM_DATE_FORMAT,
    },
    display: {
        dateInput: CUSTOM_DATE_FORMAT,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: CUSTOM_DATE_FORMAT,
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Injectable()
export class EnerbankMomentDateAdapter extends MomentDateAdapter {
    public format(date: Moment): string {
        date = this.clone(date);

        if (!this.isValid(date)) {
            throw Error('MomentDateAdapter: Cannot format invalid date.');
        }

        // if no date is present in inputbox, then "date" will still contain date info, but not creation data
        // we want to return a date string only if creation data is present
        return date.creationData().input ? date.format(CUSTOM_DATE_FORMAT) : '';
    }

    sameDate(first: Moment | null, second: Moment | null): boolean {
        if (first && second) {
            const firstValid = this.isValid(first);
            const secondValid = this.isValid(second);
            const firstInput: MomentInput = first.creationData().input;
            const secondInput: MomentInput = second.creationData().input;
            const hasIdenticalInput: boolean = firstInput === secondInput;

            if (firstValid && secondValid) {
                return !this.compareDate(first, second) && hasIdenticalInput;
            }

            return firstValid === secondValid;
        }

        return first === second;
    }
}

// A reusable/configurable datepicker specific to Enerbank formatting requirements.
@Component({
    selector: 'eb-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
    ],
})
export class DatepickerComponent implements ControlValueAccessor {
    @Input() public placeHolderText = 'Choose date';
    // inner control, independent of the one associated to this component
    // by any parent components
    public datepickerFormControl: UntypedFormControl = new UntypedFormControl();

    constructor() {
        this.datepickerFormControl.valueChanges.subscribe((value: Moment) => {
            this.emitDateControlChange(value);
        });
    }

    public emitDateControlChange(value: Moment) {
        this.propagateChange(value);
    }

    // ControlValueAccessor interface implementation -----

    // gets called with the initial value provided in the form control
    // associated to this component through [formControl] from the parent component
    public writeValue(value: Moment) {
        this.datepickerFormControl.setValue(moment(value));
    }

    // the parameter value is something Reactive Forms passes such that
    // the form control associated to this component from the parent
    // will be able to receive a changed value
    public registerOnChange(parentFormControlCallback: () => unknown) {
        this.propagateChange = parentFormControlCallback;
    }

    public registerOnTouched() {
        // need to implement to fulfill the interface contract, but not needed for now
    }

    private propagateChange = (value: unknown) => {
        // this is intentional
    };
}
