import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Moment } from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';

export const valuesRequiredPropertyName = 'valuesRequired';
export const lastCallTooFarInPastPropertyName = 'lastCallTooFarInPast';
export const lastCallInFuturePropertyName = 'lastCallInFuture';
export const nextCallInPastPropertyName = 'nextCallInPast';
export const nextCallBeforeLastCallPropertyName = 'nextCallBeforeLastCall';
export const nextCallTooFarInFuturePropertyName = 'nextCallTooFarInFuture';
export const tooLongBetweenCallsPropertyName = 'tooLongBetweenCalls';

export const valuesRequiredValidator =
    (callDate: Moment): ValidatorFn =>
    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (control: AbstractControl): { [key: string]: any } => {
        const isInvalid: boolean = !control.value || !callDate.isValid();

        return isInvalid
            ? {
                  [valuesRequiredPropertyName]: {
                      callDate,
                  },
              }
            : null;
    };

export const lastCallTooFarInPastValidator =
    (lastCallDate: Moment): ValidatorFn =>
    // ng lint throws error for builtin variables , so disabled below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }

        const now = moment(new Date());

        const isInvalid: boolean = moment.duration(now.diff(lastCallDate)).asMonths() > 24;

        return isInvalid
            ? {
                  [lastCallTooFarInPastPropertyName]: {
                      currentDateTime: now,
                      lastCall: lastCallDate,
                  },
              }
            : null;
    };

export const lastCallInFutureValidator =
    (lastCallDate: Moment): ValidatorFn =>
    // ng lint throws error for builtin variables , so disabled below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }

        const now = moment(new Date());

        const isInvalid: boolean = lastCallDate > now;

        return isInvalid
            ? {
                  [lastCallInFuturePropertyName]: {
                      currentDateTime: now,
                      lastCall: lastCallDate,
                  },
              }
            : null;
    };

export const nextCallInPastValidator =
    (lastCallDate: Moment, nextCallDate: Moment): ValidatorFn =>
    // ng lint throws error for builtin variables , so disabled below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }

        const now = moment(new Date());

        // if next call is set before last call, only display one error
        const nextCallInPast: boolean = nextCallDate < now.seconds(0);
        const nextCallBeforeLastCall: boolean = lastCallDate && lastCallDate.isValid() && nextCallDate < lastCallDate;

        if (nextCallBeforeLastCall) {
            return {
                [nextCallBeforeLastCallPropertyName]: {
                    lastCall: lastCallDate,
                    nextCall: nextCallDate,
                },
            };
        } else if (nextCallInPast) {
            return {
                [nextCallInPastPropertyName]: {
                    currentDateTime: now,
                    nextCall: nextCallDate,
                },
            };
        } else {
            return null;
        }
    };

export const nextCallTooFarInFutureValidator =
    (nextCallDate: Moment): ValidatorFn =>
    // ng lint throws error for builtin variables , so disabled below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }

        const now = moment(new Date());

        const isInvalid: boolean = moment.duration(nextCallDate.diff(now)).asMonths() > 24;

        return isInvalid
            ? {
                  [nextCallTooFarInFuturePropertyName]: {
                      currentDateTime: now,
                      nextCall: nextCallDate,
                  },
              }
            : null;
    };

export const tooLongBetweenCallsValidator =
    (lastCallDate: Moment, nextCallDate: Moment): ValidatorFn =>
    // ng lint throws error for builtin variables , so disabled below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }

        const isInvalid: boolean = moment.duration(nextCallDate.diff(lastCallDate)).asMonths() > 24;

        return isInvalid
            ? {
                  [tooLongBetweenCallsPropertyName]: {
                      lastCall: lastCallDate,
                      nextCall: nextCallDate,
                  },
              }
            : null;
    };
