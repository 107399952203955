import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { FundingRequestService } from '@services/funding-request/funding-request.abstract';
import { FundingRequestEvent } from '@services/funding-request/models/funding-request-event.model';
import { map, take } from 'rxjs/operators';

import { ConstantsHistoryPage, ConstantsHistoryRow } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_DetailPages';
import { ConstantsObjectsLabelValuePair } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { FundingRequestHistory } from '../../../services/funding-request/models/funding-request-history-event-model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'eb-funding-request-history',
    templateUrl: './funding-request-history.component.html',
    styleUrls: ['./funding-request-history.component.scss'],
})

export class FundingRequestHistoryComponent implements OnInit {
    @Input() authorizationNumber: string;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    public displayedColumns: string[] = ['eventName', 'createdSubject.name', 'phoneNumber', 'createdDate'];
    public dataSource: MatTableDataSource<FundingRequestEvent>;
    public defaultSortedData: FundingRequestEvent[];

    // E2E TESTS
    public constantsHistoryPage: typeof ConstantsHistoryPage = ConstantsHistoryPage;
    public constantsHistoryRow: typeof ConstantsHistoryRow = ConstantsHistoryRow;
    public constantsObjectsLabelValuePair: typeof ConstantsObjectsLabelValuePair = ConstantsObjectsLabelValuePair;

    constructor(private fundingRequestService: FundingRequestService) {}

    ngOnInit() {
        this.setSortChangesSubscription();
        this.getFundingRequestEvents();
    }

    private getFundingRequestEvents() {
        this.fundingRequestService
            .getFundingRequestHistory(this.authorizationNumber)
            .pipe(
                take(1),
                map((res: FundingRequestHistory) => {
                    res.fundingRequestHistories.forEach((fre) => (fre.createdDate = new Date(fre.createdDate)));
                    return res;
                })
            )
            .subscribe((history: FundingRequestHistory) => {
                this.dataSource = new MatTableDataSource<FundingRequestEvent>([]);
                this.defaultSortedData = history.fundingRequestHistories.sort((a, b) => +new Date(b.createdDate) - +new Date(a.createdDate));
                this.dataSource.data = this.defaultSortedData;
                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) {
                        return property.split('.').reduce((o, i) => o[i], item);
                    }
                    return item[property];
                 };
                this.dataSource.sort = this.sort;
            });
    }

    private setSortChangesSubscription() {
        this.sort.sortChange.subscribe(() => {
            if (this.sort.direction === '') {
                this.dataSource.data = this.defaultSortedData;
                this.dataSource.sort = this.sort;
            }
        });
    }
}
