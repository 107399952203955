import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FileSelectionTableComponent } from './file-selection-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    imports: [CommonModule, MatTableModule, MatCheckboxModule, MatIconModule],
    declarations: [FileSelectionTableComponent],
    exports: [FileSelectionTableComponent],
})
export class FileSelectionTableModule {}
