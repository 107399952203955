import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateTimeInputModule } from '@components/shared/date-time-input/date-time-input.module';
import { ObjectKeysPipe } from '@components/utils/pipes/object-keys/object-keys.pipe';
import { PipesModule } from '@pipes/pipes.module';

import { UpdateFundingRequestCallTimeComponent } from './update-funding-request-call-time.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [CommonModule, MatCardModule, MatInputModule, PipesModule, ReactiveFormsModule, DateTimeInputModule],
    declarations: [UpdateFundingRequestCallTimeComponent],
    exports: [UpdateFundingRequestCallTimeComponent],
    providers: [ObjectKeysPipe],
})
export class UpdateFundingRequestCallTimeModule {}
