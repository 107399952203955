import { Moment } from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';

export class AchFileMetadata {
    id: string;
    createdAt: Moment;
    createdBy: string;
    generationStartedAt?: Moment;
    generationCompletedAt?: Moment;
    filename: string;
    file: string;
    submittedAt?: Moment;
    submittedBy?: string;
    submitted: boolean;
    totalDollarAmount: number;
    fundingRequestIds?: string[];
    status: string;
    auditResult: AuditResult;

    public constructor(src: AchFileMetadataDTO) {
        this.id = src.id ?? src.achFileResourceId;
        this.createdAt = moment.parseZone(src.createdAt ?? src.createdDate);
        this.createdBy = src.createdBy;
        this.generationStartedAt = moment.parseZone(src.generationStartedAt);
        this.generationCompletedAt = moment.parseZone(src.generationCompletedAt);
        this.filename = src.filename;
        this.file = src.file;
        this.submittedAt = moment.parseZone(src.submittedAt);
        this.submittedBy = src.submittedBy;
        this.submitted = src.submitted ?? src.submittedAt != null;
        this.totalDollarAmount = src.totalDollarAmount;
        this.fundingRequestIds = src.fundingRequestIds;
        this.status = src.status && src.status.charAt(0).toUpperCase() + src.status.slice(1);
        if (src.auditResult && src.auditResult.validationText) {
            this.auditResult = {
                hasError: src.auditResult.hasError,
                validationText: src.auditResult.validationText.replace(/\n/g, '<br>')
            };
        }
    }
}

export class AuditResult {
    hasError: boolean;
    validationText: string;
}

export interface AchFileMetadataDTO {
    id?: string;
    createdAt: string;
    createdBy: string;
    generationStartedAt?: string;
    generationCompletedAt?: string;
    filename: string;
    file: string;
    submittedAt?: string;
    submittedBy?: string;
    submitted: boolean;
    totalDollarAmount: number;
    fundingRequestIds?: string[];
    status: string;
    auditResult: AuditResult;
    achFileResourceId: string;
    createdDate: string;
}
