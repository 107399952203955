import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
 

import { FundingRequestActionsComponent } from './funding-request-actions.component';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, MatMenuModule, RouterModule],
    declarations: [FundingRequestActionsComponent],
    exports: [FundingRequestActionsComponent],
    providers: [],
})
export class FundingRequestActionsModule {}
