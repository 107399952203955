<div class="body">
  <div class="description" [attr.data-automation]="constantsCreateAchSidePanel.approvedHeaderTitle">
    Approved as of:
  </div>

  <div class="pickers">
    <eb-date-time-input (dateTimeUpdated)="onDateTimeUpdated($event)"></eb-date-time-input>

    <div [ngSwitch]="shownAchEstimatesTemplate">
      <div *ngSwitchCase="estimatesTemplateEnum.error" class="error">
        <span [attr.data-automation]="constantsCreateAchSidePanel.errorMessage">Failed to calculate the value of authorizations. </span>
        <a href="#" (click)="refreshEstimates()" [attr.data-automation]="constantsCreateAchSidePanel.authorizationsTryAgainLink">Try Again</a>
      </div>
      <div *ngSwitchCase="estimatesTemplateEnum.spinner" class="spinner">
        <mat-progress-spinner mode="indeterminate" diameter="30" [attr.data-automation]="constantsCreateAchSidePanel.spinnerAnimation"></mat-progress-spinner>
        <span [attr.data-automation]="constantsCreateAchSidePanel.spinnerMessage">Calculating Authorizations</span>
      </div>
      <div *ngSwitchCase="estimatesTemplateEnum.results" class="authorizations-text" [attr.data-automation]="constantsCreateAchSidePanel.authorizationsMessage">
        <div style="margin-bottom: 5px;">{{ estimates.fundingRequests }} Authorizations</div>{{ estimates.total | currency }} Total
      </div>
    </div>
  </div>
</div>
