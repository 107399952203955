import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SidePanelTemplateModule } from '@components/side-panel-template/side-panel-template.module';

import { FileSelectionTableModule } from './file-selection-table/file-selection-table.module';
import { ReviewAndSubmitModule } from './review-and-submit/review-and-submit.module';
import { UploadAchFilePanelComponent } from './upload-ach-file-panel.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [CommonModule, MatIconModule, MatTabsModule, FileSelectionTableModule, ReviewAndSubmitModule, SidePanelTemplateModule],
    declarations: [UploadAchFilePanelComponent],
    exports: [UploadAchFilePanelComponent],
})
export class UploadAchFilePanelModule {}
