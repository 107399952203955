import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CreateAchFilePanelComponent } from '@components/create-ach-file-panel/create-ach-file-panel.component';
import { CreateAchFormModule } from '@components/create-ach-file-panel/create-ach-form/create-ach-form.module';
import { SidePanelTemplateModule } from '@components/side-panel-template/side-panel-template.module';

import { CreateAchReviewModule } from './create-ach-review/create-ach-review.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [CommonModule, MatIconModule, MatTabsModule, SidePanelTemplateModule, CreateAchFormModule, CreateAchReviewModule],
    declarations: [CreateAchFilePanelComponent],
    exports: [CreateAchFilePanelComponent],
})
export class CreateAchFilePanelModule {}
