import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SidePanelTemplateModule } from '@components/side-panel-template/side-panel-template.module';

import { ViewAchFileAuditPanelComponent } from './view-ach-file-audit-panel.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [CommonModule, MatIconModule, MatTabsModule, SidePanelTemplateModule],
    declarations: [ViewAchFileAuditPanelComponent],
    exports: [ViewAchFileAuditPanelComponent],
})
export class ViewAchFileAuditPanelModule {}
