<mat-sidenav-container>
  <mat-sidenav #sideNav position="end">
    <eb-update-funding-request *ngIf="displayedComponent === sidePanelContent.updateFundingRequest">
    </eb-update-funding-request>
    <eb-create-new-authorization *ngIf="displayedComponent === sidePanelContent.createNewAuthorization"></eb-create-new-authorization>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="funding-request-bar">
      <div class="funding-request-count" [attr.data-automation]="constantsFundingRequestBar.authorizationCount">
        {{numFilteredFundingRequests | async}} Authorizations
      </div>
      <div class=pending-upload-files-container *ngIf="achAuthSectionHidden" [attr.data-automation]="constantsFundingRequestBar.achContainer">
         <button (click)="newAuthButtonClicked()" class="button-right ls-5" [attr.data-automation]="constantsFundingRequestBar.newAuthButton">Create New Auth</button>
      </div>
    </div>

    <div class="content-body" [attr.data-automation]="constantsDashboard.pageContainer">
      <div class="dashboard-filters" [attr.data-automation]="constantsDashboard.searchAndFilterSectionContainer">
        <eb-search-bar (searchInputChanged)="onSearchInputChanged($event)"
                       [attr.data-automation]="constantsSearch.searchSectionContainer"
                       [placeholderText]="searchPlaceholderText"
                       [width]="searchBarWidth"></eb-search-bar>
        <div class="filter-row" [attr.data-automation]="constantsFilters.filterSectionContainer">
          <mat-icon [attr.data-automation]="constantsFilters.filterSectionListIcon">filter_list</mat-icon>
          <eb-status-filter-menu [attr.data-automation]="constantsFilters.status"></eb-status-filter-menu>
          <eb-communication-method-filter-menu [attr.data-automation]="constantsFilters.communicationMethod"></eb-communication-method-filter-menu>
          <eb-funding-notice-method-filter-menu [attr.data-automation]="constantsFilters.fundingNoticeMethod"></eb-funding-notice-method-filter-menu>
          <a class="clear-filter" *ngIf="hasActiveFilters" (click)="clearActiveFilters()" [attr.data-automation]="constantsFilters.clearAllFilters"><mat-icon [attr.data-automation]="constantsFilters.icon">close</mat-icon><span [attr.data-automation]="constantsFilters.text">Clear All Filters</span></a>
        </div>
      </div>
      <div class="loadingSpinner">
        <mat-progress-spinner *ngIf="!fundingRequestData" mode="indeterminate"></mat-progress-spinner>
      </div>
      <div class="table-container">
        <eb-funding-request-summary-table (dataLoadedEvent)="onDataLoaded($event)" [attr.data-automation]="constantsAppTable.container"></eb-funding-request-summary-table>
      </div>    
      <eb-no-results-found *ngIf="fundingRequestData && hasNoFilteredResults" (clearSearchAndFilters)="onClearSearchAndFilters()"></eb-no-results-found>
      
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

