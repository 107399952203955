import { Component } from '@angular/core';
import { SidePanelService } from '@services/side-panel/side-panel.service';

import { ConstantsGenericSidePanel, ConstantStatusOverrideSection } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

@Component({
    selector: 'eb-side-panel-template',
    templateUrl: './side-panel-template.component.html',
    styleUrls: ['./side-panel-template.component.scss'],
})
export class SidePanelTemplateComponent {

    public constantsGenericSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;
    public constantStatusOverrideSection: typeof ConstantStatusOverrideSection = ConstantStatusOverrideSection;
    constructor(private sidePanelService: SidePanelService) {}

    public closeSidePanel(): void {
        this.sidePanelService.openCloseSideNav.next(false);
    }

    public openSidePanel(): void {
        this.sidePanelService.openCloseSideNav.next(true);
    }
}
