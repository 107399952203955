import { Routes } from '@angular/router';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { AuthGuardService } from '@services/shared/auth/auth-guard.service';

import { FundingRequestDetailsComponent } from '@components/funding-request-details/funding-request-details.component';
import { FundingRequestDetailsPageResolver } from '@components/resolvers/funding-request-details-page/funding-request-details-page.resolver';
import { RoutingPaths } from '@constants';
import { AchFilesComponent } from '@components/ach-files/ach-files.component';

export const appRoutes: Routes = [
    {
        path: RoutingPaths.fundingRequestSummaries,
        canActivate: [AuthGuardService],
        component: DashboardComponent,
    },
    {
        path: RoutingPaths.achFiles,
        canActivate: [AuthGuardService],
        component: AchFilesComponent,
    },
    {
        path: RoutingPaths.fundingRequestSummaries + `/:authorizationNum`, // funding-requests/:authorizationNum
        component: FundingRequestDetailsComponent,
        resolve: {
            // also dynamically assigns the breadcrumb label and URL to the config data for this route
            fundingRequestDetailsPageResolver: FundingRequestDetailsPageResolver,
        },
    },
    {
        path: '**',
        redirectTo: RoutingPaths.fundingRequestSummaries,
        pathMatch: 'full',
    },
];
