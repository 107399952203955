import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { LoggerService } from '@services/shared/logger/logger.abstract';

import { FundingRequestService } from './funding-request.abstract';
import { FundingRequestMockService } from './funding-request.mock-service';
import { FundingRequestApiService } from './funding-request.service';

const fundingRequestFactory = (
    http: HttpClient,
    environment: Environment,
    authService: AuthService,
    googleAnalyticsService: GoogleAnalyticsService,
    logger: LoggerService
) => environment.mockServicesEnabled
        ? new FundingRequestMockService(http, environment)
        : new FundingRequestApiService(http, environment, authService, googleAnalyticsService, logger);

export const fundingRequestServiceProvider: FactoryProvider = {
    deps: [HttpClient, ENVIRONMENT, AuthService, GoogleAnalyticsService, LoggerService],
    provide: FundingRequestService,
    useFactory: fundingRequestFactory,
};
