<div class="title-content">
  <span role="button" tabindex="0" (click)="closeSidePanel()" [attr.data-automation]="constantsGenericSidePanel.xButton">
    <mat-icon aria-label="Close">close</mat-icon>
  </span>
  <div>
    <ng-content select="[title-content]"></ng-content>
  </div>
</div>
<div>
  <ng-content select="[banner-content]"></ng-content>
</div>
<div class="main-content" [attr.data-automation]="constantStatusOverrideSection.actionSectionContainer">
  <ng-content select="[main-content]"></ng-content>
</div>
