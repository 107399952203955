import { Component, Inject } from '@angular/core';

import { ConstantsCompletionModal, ConstantsGenericModal } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Modal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface AnnouncementDialogData {
    icon?: AnnouncementDialogIcon;
    label?: string;
    message: string;
    primaryButtonText?: string;
    primaryButtonAction?: string;
    secondaryButtonText?: string;
    secondaryButtonAction?: string;
    backgroundColor?: AnnouncementDialogColor;
    buttonBarStyle?: AnnouncementDialogButtonBarStyle;
    secondaryMessage?: string;
}

/**
 * These options correspond to a class within the components scss file.
 * When adding options, ensure the equivalent class is all included.
 */
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum AnnouncementDialogColor {
    grey = 'grey',
    blue = 'blue',
    white = 'white',
}

/**
 * These options correspond to a class within the components scss file.
 * When adding options, ensure the equivalent class is all included.
 */
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum AnnouncementDialogButtonBarStyle {
    centerStretch = 'center-stretch',
    rightAlignedRibbon = 'right-aligned-ribbon',
}

/**
 * These options correspond to Angular Material mat-icon selectors.
 * When adding icon options, ensure they match the selector of a mat-icon.
 */
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum AnnouncementDialogIcon {
    success = 'check_circle',
    failure = 'cancel'
}

@Component({
    selector: 'eb-announcement-dialog',
    templateUrl: './announcement-dialog.component.html',
    styleUrls: ['./announcement-dialog.component.scss'],
})
export class AnnouncementDialogComponent {
    public icon: AnnouncementDialogIcon;
    public label: string;
    public message = 'Success';
    public primaryButtonText = 'Okay';
    public primaryButtonAction: string;
    public secondaryButtonText: string;
    public secondaryButtonAction: string;
    public secondaryMessage: string;
    public backgroundColor: AnnouncementDialogColor = AnnouncementDialogColor.blue;
    public buttonBarStyle: AnnouncementDialogButtonBarStyle = AnnouncementDialogButtonBarStyle.centerStretch;

    public constantsGenericModal: typeof ConstantsGenericModal = ConstantsGenericModal;
    public constantsCompletionModal: typeof ConstantsCompletionModal = ConstantsCompletionModal; 
    
    private defaultButtonClasses = 'button-row'; // corresponds to CSS classes
    
    constructor(public dialogRef: MatDialogRef<AnnouncementDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: AnnouncementDialogData) {
        // Set to passed in data, otherwise default to success dialog.
        if (data) {
            this.icon = data.icon;
            this.label = data.label;
            this.message = data.message ? data.message : 'Success';
            this.primaryButtonText = data.primaryButtonText ? data.primaryButtonText : 'Okay';
            this.primaryButtonAction = data.primaryButtonAction;
            this.secondaryButtonText = data.secondaryButtonText;
            this.secondaryButtonAction = data.secondaryButtonAction;
            this.backgroundColor = data.backgroundColor ? data.backgroundColor : AnnouncementDialogColor.blue;
            this.buttonBarStyle = data.buttonBarStyle ? data.buttonBarStyle : AnnouncementDialogButtonBarStyle.centerStretch;
            this.secondaryMessage = data.secondaryMessage;

        }
    }

    public get buttonClasses(): string {
        return [this.defaultButtonClasses, this.buttonBarStyle].join(' ');
    }

    public closeDialog(action: string): void {
        this.dialogRef.close(action);
    }
}
