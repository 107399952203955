// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum FundingRequestFields {
    nextCall = 'nextCall',
    lastCall = 'lastCall',
    status = 'status',
}

export interface FundingRequestPatch {
    field: FundingRequestFields;
    oldValue: string;
    newValue: string;
}
