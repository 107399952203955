import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SearchBarModule } from '../search-bar/search-bar.module';
import { SidePanelTemplateModule } from '../side-panel-template/side-panel-template.module';

import { CreateNewAuthorizationComponent } from './create-new-authorization.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    imports: [CommonModule, SidePanelTemplateModule, SearchBarModule, MatIconModule, MatRadioModule, MatTableModule],
    declarations: [CreateNewAuthorizationComponent],
    exports: [CreateNewAuthorizationComponent],
})
export class CreateNewAuthorizationModule {}
