import { Component, Input } from '@angular/core';
import { FundingExceptionTracker } from '@components/funding-request-summary-table/models/funding-request-table.models';

import { ConstantsExceptionIcons } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';

@Component({
    selector: 'eb-funding-exception-icons',
    templateUrl: './funding-exception-icon-group.component.html',
    styleUrls: ['./funding-exception-icon-group.component.scss'],
})
export class FundingExceptionIconGroupComponent {
    @Input()
    public fundingExceptions: FundingExceptionTracker;

    public constantsExceptionIcons: typeof ConstantsExceptionIcons = ConstantsExceptionIcons;
}
