import { Component, Input, OnInit } from '@angular/core';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { Status } from '@components/funding-request-summary-table/models/status.model';
import { RoutingPaths } from '@constants';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { UpdateFundingRequestService } from '@services/update-funding-request/update-funding-request.service';
import { ConstantsActionMenuOptions } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';

@Component({
    selector: 'eb-funding-request-actions',
    templateUrl: './funding-request-actions.component.html',
    styleUrls: ['./funding-request-actions.component.scss'],
})
export class FundingRequestActionsComponent implements OnInit {

    @Input()
    public fundingRequestModel: FundingRequestRowViewModel;
    public displayStatusOverride: boolean;
    
    public constantsActionMenuOptions: typeof ConstantsActionMenuOptions = ConstantsActionMenuOptions;    

    constructor(private sidePanelService: SidePanelService, private updateFundingRequestService: UpdateFundingRequestService) {}

    public get historyLink(): string {
        return `/${RoutingPaths.fundingRequestSummaries}/${this.fundingRequestModel.authorizationNum}`;
    }

    ngOnInit(): void {
        this.sidePanelService.onSideNavOpenClosed.subscribe((isSidePanelOpen) => {
            if (!isSidePanelOpen) {
                this.setDisplayOverride();
            }
        });
    }

    public setDisplayOverride(): void {
        this.displayStatusOverride =
            this.fundingRequestModel.status === Status.approved || this.fundingRequestModel.status === Status.pending || this.fundingRequestModel.status === Status.hold;
    }

    public openStatusOverride(): void {
        this.updateFundingRequestService.openUpdateStatus(this.fundingRequestModel);
    }
}
