import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatepickerModule } from '@components/datepicker/datepicker.module';
import { FundingExceptionIconGroupModule } from '@components/shared/funding-exception-icon-group/funding-exception-icon-group.module';
import { RequestLockedModule } from '@components/shared/request-locked/request-locked.module';
import { SidePanelTemplateModule } from '@components/side-panel-template/side-panel-template.module';
import { UpdateFundingRequestCallTimeModule } from '@components/update-funding-request/update-funding-request-call-time/update-funding-request-call-time.module';
import { UpdateFundingRequestModule } from '@components/update-funding-request/update-funding-request.module';
import { PipesModule } from '@components/utils/pipes/pipes.module';
import { FundingRequestDetailsComponent } from './funding-request-details.component';
import { FundingRequestHistoryModule } from './funding-request-history/funding-request-history.module';
import { MessageStatusHistoryModule } from './message-status-history/message-status-history.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        DatepickerModule,
        UpdateFundingRequestCallTimeModule,
        MatIconModule,
        MatTabsModule,
        MatSidenavModule,
        SidePanelTemplateModule,
        UpdateFundingRequestModule,
        RequestLockedModule,
        PipesModule,
        FundingExceptionIconGroupModule,
        FundingRequestHistoryModule,
        MessageStatusHistoryModule,
    ],
    declarations: [FundingRequestDetailsComponent],
    exports: [FundingRequestDetailsComponent],
})
export class FundingRequestDetailsModule {}
