<div class="dialog-content">
  <div class="dialog-title">
    <div class="bold" >{{data.subject}}</div>
    <div class="dialog-close" (click)="closeDialog()">&times;</div>
  </div>  
  <br><br>
  <div>
    <div [innerHTML]=data.message></div>
  </div>
</div>
