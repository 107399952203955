<div class="funding-exceptions" *ngIf="fundingExceptions">
  <eb-circle-icon
    *ngIf="fundingExceptions.manualFunding; else blankIcon"
    class="manual-funding-exception"
    matTooltip="Manual Funding"
    [attr.data-automation]="constantsExceptionIcons.manualExceptionIcon"
    >M</eb-circle-icon
  >
  <eb-circle-icon
    *ngIf="fundingExceptions.nachaWait; else blankIcon"
    class="nacha-wait-exception"
    matTooltip="NACHA"
    [attr.data-automation]="constantsExceptionIcons.nachaExceptionIcon"
    >N</eb-circle-icon
  >
  <eb-circle-icon
    *ngIf="fundingExceptions.frozen; else blankIcon"
    class="frozen-exception"
    matTooltip="Frozen"
    [attr.data-automation]="constantsExceptionIcons.frozenExceptionIcon"
    >F</eb-circle-icon
  >
  <eb-circle-icon
    *ngIf="fundingExceptions.failedToFund; else blankIcon"
    class="failed-to-fund-exception"
    matTooltip="Failed To Fund"
    [attr.data-automation]="constantsExceptionIcons.failedToFundExceptionIcon"
    >!</eb-circle-icon
  >
  <eb-circle-icon
    *ngIf="fundingExceptions.pastReamortizationDate; else blankIcon"
    class="past-reamortization-date-exception"
    matTooltip="Past The Reamortization Date"
    [attr.data-automation]="constantsExceptionIcons.pastReamortizationDateIcon"
    >R</eb-circle-icon
  >

  <eb-circle-icon
  *ngIf="fundingExceptions.blockedBySpectrum; else blankIcon"
  class="blocked-by-spectrum-exception"
  matTooltip="Blocked By Spectrum"
  [attr.data-automation]="constantsExceptionIcons.blockedBySpectrum"
  >S</eb-circle-icon
  >

  <ng-template #blankIcon>
    <eb-circle-icon class="blank"></eb-circle-icon>
  </ng-template>
</div>
