import { Component, EventEmitter, Injectable, Output } from '@angular/core';

import { ConstantsNoResultsFound } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';

@Component({
    selector: 'eb-no-results-found',
    templateUrl: './no-results-found.component.html',
    styleUrls: ['./no-results-found.component.scss'],
})
@Injectable()
export class NoResultsFoundComponent {
    // ng lint is disabled to avoid member-ordering error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() clearSearchAndFilters = new EventEmitter<any>();

    public constantsNoResultsFound: typeof ConstantsNoResultsFound = ConstantsNoResultsFound;
    public onClearClicked(): boolean {
        this.clearSearchAndFilters.emit();
        return false;
    }
}
