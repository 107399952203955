import { Component, Input, OnInit } from '@angular/core';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';

import { ConstantUploadACHFilesSteps } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

@Component({
    selector: 'eb-review-and-submit',
    templateUrl: './review-and-submit.component.html',
    styleUrls: ['./review-and-submit.component.scss'],
})
export class ReviewAndSubmitComponent {
    @Input() selectedItems: AchFileMetadata[];

    // For QA Automation
    public constantsACHSidePanel: typeof ConstantUploadACHFilesSteps = ConstantUploadACHFilesSteps;

    constructor() {}
}
