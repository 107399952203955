import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateTimeInputModule } from '@components/shared/date-time-input/date-time-input.module';

import { CreateAchFormComponent } from './create-ach-form.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule, ReactiveFormsModule, DateTimeInputModule],
    exports: [CreateAchFormComponent],
    declarations: [CreateAchFormComponent],
})
export class CreateAchFormModule {}
