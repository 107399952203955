import { HttpClient } from '@angular/common/http';
import { FactoryProvider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { AchService } from './ach.abstract';
import { AchMockService } from './ach.mock-service';
import { FundingRequestService } from '../funding-request/funding-request.abstract';
import { AchApiService } from './achapi.service';

const achServiceFactory = (http: HttpClient,
    environment: Environment,
    authService: AuthService,
    googleAnalyticsService: GoogleAnalyticsService,
    fundingRequestService: FundingRequestService) =>
    environment.mockServicesEnabled
        ? new AchMockService(environment) :
         new AchApiService(http, environment, authService, googleAnalyticsService, fundingRequestService);


export const achServiceProvider: FactoryProvider = {
    deps: [HttpClient, ENVIRONMENT, AuthService, GoogleAnalyticsService, FundingRequestService],
    provide: AchService,
    useFactory: achServiceFactory,
};
