import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { AnnouncementDialogColor, AnnouncementDialogData } from '@components/shared/announcement-dialog/announcement-dialog.component';
import { SetCallDateTimePanelData } from '@components/update-funding-request/update-funding-request-call-time/update-funding-request-call-time.component';
import { CallType, HttpMethods } from '@constants';
import { AnnouncementDialogService } from '@services/announcement-dialog/announcement-dialog.service';
import { FundingRequestService } from '@services/funding-request/funding-request.abstract';
import { FundingRequestPatch } from '@services/funding-request/models/funding-request-patch.model';
import { convertStatusToApiString } from '@services/funding-request/models/response-status-converters';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { SidePanelContent } from '@services/side-panel/models/side-panel.model';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { Moment } from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum UpdateFundingRequestComponentEnum {
    status = 'UpdateFundingRequestStatusComponent',
    callDateTime = 'SetCallDateTimeComponent',
    none = 'None',
}
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
enum ErrorActions {
    close = 'close',
    refresh = 'refresh',
}
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum ActiveField {
    lastCall = 'last call',
    nextCall = 'next call',
    status = 'status',
}

@Injectable()
export class UpdateFundingRequestService {

    public selectedComponent: BehaviorSubject<UpdateFundingRequestComponentEnum> = new BehaviorSubject<UpdateFundingRequestComponentEnum>(
        UpdateFundingRequestComponentEnum.none
    );

    

    public callTimeData: SetCallDateTimePanelData = {} as SetCallDateTimePanelData;

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updatedFundingRequest: Subject<any> = new Subject<any>();
    public messagingSubscription: Subscription;
    private apiLabel = 'Update Funding Request API';
    private selectedFundingRequestRow: FundingRequestRowViewModel = {} as FundingRequestRowViewModel;

    constructor(
        public sidePanelService: SidePanelService,
        public fundingRequestService: FundingRequestService,
        public announcementService: AnnouncementDialogService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.sidePanelService.onSideNavOpenClosed.subscribe((open: boolean) => {
            if (!open) {
                this.sidePanelService.onSidePanelContentChanged.next({
                    displayedComponent: SidePanelContent.none,
                });

                this.unsubscribeFromMonitoring();

                this.selectedFundingRequest = {} as FundingRequestRowViewModel;
                this.callTimeData = {} as SetCallDateTimePanelData;
            }
        });
    }

    public get selectedFundingRequest(): FundingRequestRowViewModel {
        return this.selectedFundingRequestRow;
    }

    public set selectedFundingRequest(fundingRequest: FundingRequestRowViewModel) {
        // this service needs the data that corresponds with the selected funding request,
        // but mutations in individual view models should not cause mutations elsewhere
        // as the API is the centralized source of truth
        // and we also want a centralized assurance of cloning, which is here
        this.selectedFundingRequestRow = Object.assign({}, fundingRequest);
    }

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openUpdateStatus(selectedFundingRequest: FundingRequestRowViewModel): Subject<any> {
        this.selectedFundingRequest = selectedFundingRequest;

        this.selectedComponent.next(UpdateFundingRequestComponentEnum.status);

        this.openSidePanel();
        return this.updatedFundingRequest;
    }

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openSetCallDateTime(callDateTimeData: SetCallDateTimePanelData): Subject<any> {
        this.callTimeData = callDateTimeData;
        this.selectedFundingRequest = callDateTimeData.selectedFundingRequest;
        this.selectedComponent.next(UpdateFundingRequestComponentEnum.callDateTime);;

        this.openSidePanel();
        return this.updatedFundingRequest;
    }

    public saveFundingRequestChanges(authorizationNum: string, patches: FundingRequestPatch[]): void {
        // if we're making the request then we want to unsubscribe, so that
        // we don't get the popup saying someone else changed the value when in actuality it was our change
        this.unsubscribeFromMonitoring();
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.patch, 'Save Funding Request Changes', '');

        this.fundingRequestService
            .patchFundingRequest(authorizationNum, patches)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.closeSidePanel();
                },
                (err: HttpErrorResponse) => {
                    const isChangedError = err.status === 409;
                    this.openErrorDialog(isChangedError);
                }
            );
    }

    public openErrorDialog(isChangedError: boolean, field?: string): void {
        const label = isChangedError ? 'Authorization has changed' : 'Request failed';
        const message = isChangedError
            ? `Another user modified the ${field ? field : this.getActiveField()} field of this funding request. Please refresh and try again`
            : 'Something went wrong with your request. Please try again or contact your administrator';
        this.announcementService
            .openDialog({
                label,
                message,
                backgroundColor: AnnouncementDialogColor.white,
                primaryButtonText: isChangedError ? 'Refresh' : 'Okay',
                primaryButtonAction: isChangedError ? ErrorActions.refresh : ErrorActions.close,
                secondaryButtonText: isChangedError ? 'Okay' : null,
                secondaryButtonAction: isChangedError ? ErrorActions.close : null,
            } as AnnouncementDialogData)
            .pipe(take(1))
            .subscribe((action: string) => {
                switch (action) {
                    case ErrorActions.close:
                        this.closeSidePanel(false);
                        break;
                    case ErrorActions.refresh:
                        window.location.reload();
                        break;
                }
            });
    }

    public getActiveField(): ActiveField {
        const activeComponent = this.selectedComponent.getValue();
        if (activeComponent === UpdateFundingRequestComponentEnum.status) {
            return ActiveField.status;
        } else if (activeComponent === UpdateFundingRequestComponentEnum.callDateTime) {
            return this.callTimeData.editedCallType === CallType.next ? ActiveField.nextCall : ActiveField.lastCall;
        }
    }

    // ng lint insists to specify ng disable snippet
    /* eslint-disable */
    /**
    * Helper function used to generate the ISO 8601 datTime string with
    * 6 subsecond decimals that the BE is expecting.
    *
    * @param time {Moment}
    */
    public formatTime(time: Moment): string {
        return time.format('YYYY-MM-DDThh:mm:ss.SSSSSSZ');
    }

    /* eslint-enable */
    private openSidePanel(): void {
        this.sidePanelService.onSidePanelContentChanged.next({
            displayedComponent: SidePanelContent.updateFundingRequest,
            fundingRequestModel: this.selectedFundingRequest,
        });
        this.sidePanelService.openCloseSideNav.next(true);
    }

    private unsubscribeFromMonitoring(): void {
        if (this.messagingSubscription) {
            this.messagingSubscription.unsubscribe();
        }
    }

    private closeSidePanel(fundingRequestUpdated: boolean = true): void {
        this.sidePanelService.onSidePanelContentChanged.next({ displayedComponent: SidePanelContent.none });
        this.sidePanelService.openCloseSideNav.next(false);
        if (fundingRequestUpdated) {
            this.updatedFundingRequest.next();
        }
    }

}
