import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageStatusHistory } from '@services/funding-request/models/message-status-history.model';

@Component({
    selector: 'eb-message-status-history-email-dialog',
    templateUrl: './message-status-history-email-dialog.component.html',
    styleUrls: ['./message-status-history-email-dialog.component.scss'],
})
export class MessageStatusHistoryEmailDialogComponent {

    constructor(public dialogRef: MatDialogRef<MessageStatusHistoryEmailDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: MessageStatusHistory) { }

    public closeDialog(): void {
        this.dialogRef.close();
    }
}
