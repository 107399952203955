import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';
import { AchService } from '@services/ach/ach.abstract';
import { SidePanelService } from '@services/side-panel/side-panel.service';

import { ConstantsGenericSidePanel } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

@Component({
  selector: 'eb-view-ach-file-audit-panel',
  templateUrl: './view-ach-file-audit-panel.component.html',
  styleUrls: ['./view-ach-file-audit-panel.component.scss']
})
export class ViewAchFileAuditPanelComponent implements OnInit, OnDestroy {
    @Input() achFile: AchFileMetadata;
    public selectedTab = 0;

    // For QA Automation
    public constantsGenericSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;

    private sidePanelSubscription: Subscription;
    constructor(
        public achService: AchService,
        public sidePanelService: SidePanelService
    ) {}

    ngOnInit(): void {
        this.sidePanelSubscription = this.sidePanelService.onSideNavOpenClosed.subscribe((open: boolean) => {
            if (!open) {
                this.achService.stopSubscriptionsAndReset();
            }
        });
    }

    /** Cleaning up subscriptions on destroy */
    ngOnDestroy(): void {
        if (this.sidePanelSubscription) {
            this.sidePanelSubscription.unsubscribe();
        }
        this.achService.stopSubscriptionsAndReset();
    }

}
