import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { FundingAuthorizationPageResult, FundingAuthorizationSummary } from './funding-authorization.model';
import { FundingRequestService } from './funding-request.abstract';
import { FundingRequestPatch } from './models/funding-request-patch.model';
import { FundingRequest } from './models/funding-request.model';
import { PagedDataRequest } from './models/paged-data-request.model';
import { ResponseStatus } from './models/response-status.model';
import { mockFundingRequestHistory } from './testing/mock-funding-request-history';
import { FundingRequestHistory } from './models/funding-request-history-event-model';
// ng lint insists to specify ng disable snippet
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';
import { AchEstimates } from '../ach/models/ach-estimates.model';
import { mockAchEstimates } from '../ach/testing/mock-ach-data';
import { CommunicationMethod } from '@components/funding-request-summary-table/models/funding-request-table.models';

@Injectable()
export class FundingRequestMockService extends FundingRequestService {

    public fundingRequests: BehaviorSubject<FundingRequest[]> = new BehaviorSubject<FundingRequest[]>([]);

    // Flag used to throw specific error responses from service call mocks. Leave for Augury editing.
    public throwErrorCode: number = null;
    private approvedFundingRequestCount = 0;

    constructor(http: HttpClient, @Inject(ENVIRONMENT) environment: Environment) {
        super(environment, http, null);

        this.fundingRequests.subscribe((requests: FundingRequest[]) => {
            this.approvedFundingRequestCount = requests.filter((item) => item.status === ResponseStatus.APPROVED_STATUS_API_RESPONSE).length;
        });
    }

    public get approvedFundingRequests(): number {
        return this.approvedFundingRequestCount;
    }

    public getFundingRequests(): Observable<FundingRequest[]> {
        this.http
            .get<FundingRequest[]>(`${this.apiBaseUrl}/assets/mock-data/funding-requests/get-funding-requests.json`)
            .pipe(take(1))
            .subscribe((fundingRequests: FundingRequest[]) => {
                this.fundingRequests.next(fundingRequests);
            });
        return this.fundingRequests;
    }

    public getFundingRequestsByPage(pagedDataRequest: PagedDataRequest): Observable<FundingAuthorizationPageResult> {
        return this.http.get<FundingRequest[]>(`${this.apiBaseUrl}/assets/mock-data/funding-requests/get-funding-requests.json`).pipe(
            mergeMap((allFundingRequests: FundingRequest[]) => {
                this.fundingRequests.next(allFundingRequests);

                // this works because a FundingAuthorizationSummary is a subset of Funding Authorization/Funding Request
                // and creating two separate files now will cause some consistency overhead when it isn't yet needed
                const allSummaries: FundingAuthorizationSummary[] = allFundingRequests.map(
                    (fundingRequest: FundingRequest) =>
                    ({
                        contractor: fundingRequest.contractor,
                        communicationPhoneNumber: '',
                        communicationMethod: fundingRequest.applicant.communicationMethod,
                        applicantState: fundingRequest.applicant.state,
                        applicantLastName: fundingRequest.applicant.lastName,
                        applicantFirstName: fundingRequest.applicant.firstName,
                        contractorState: fundingRequest.contractorState,
                        positiveConfirmation: fundingRequest.positiveConfirmationLevel,
                        lastCall: fundingRequest.lastCall,
                        statusDetail: fundingRequest.statusDetail,
                        status: fundingRequest.status,
                        loanId: fundingRequest.loanId,
                        applicationNumber: fundingRequest.applicationNumber,
                        authorizationNumber: fundingRequest.authorizationNumber,
                        nextCall: fundingRequest.nextCall,
                        tags: fundingRequest.tags,
                        spectrumAccountNumber: fundingRequest.spectrumAccountNumber,
                        failedToFund: fundingRequest.failedToFund,
                        reamortizationDate: fundingRequest.reamortizationDate,
                        closedToPostingCode: fundingRequest.closedToPostingCode,
                        accountStatus: fundingRequest.accountStatus,
                        fundingNoticeMethod: fundingRequest.fundingNoticeMethod,
                    } as FundingAuthorizationSummary)
                );

                let filteredSummaries: FundingAuthorizationSummary[] = [];

                for (const summary of allSummaries) {
                    if (pagedDataRequest.filterBy) {
                        // this tracks that for all fields to filter by, the summary matches them all
                        let matchAllFilters = false;

                        for (const field of Object.keys(pagedDataRequest.filterBy)) {
                            // this tracks if there's a match against ANY of the filter values for the given field to filter by
                            let fieldMatch = false;

                            let searchValue = pagedDataRequest.filterBy[field];
                            const value = summary[field];

                            if (field === 'textSearch') {
                                for (const key of Object.keys(summary)) {
                                    if (this.isString(summary[key]) && summary[key].toLowerCase().includes(searchValue.toLowerCase())) {
                                        fieldMatch = true;
                                        break;
                                    }
                                }
                            } else {
                                if (!Array.isArray(searchValue)) {
                                    searchValue = [searchValue];
                                }

                                for (const sVal of searchValue) {
                                    if ((this.isString(sVal) && this.isString(value) && sVal.toLowerCase() === value.toLowerCase()) || value === searchValue) {
                                        fieldMatch = true;
                                        break;
                                    }
                                }
                            }

                            if (fieldMatch) {
                                matchAllFilters = true;
                            } else {
                                matchAllFilters = false;
                                break;
                            }
                        }

                        if (matchAllFilters) {
                            filteredSummaries.push(summary);
                        }
                    }
                }

                const sortBy = pagedDataRequest.sortBy;
                const sortDirection = pagedDataRequest.sortDirection;
                if (sortBy && sortDirection) {
                    const direction = sortDirection === 'desc' ? -1 : 1;
                    // ng lint throws error for builtin variables , so disabled below
                    // eslint-disable-next-line
                    filteredSummaries = filteredSummaries.sort((a, b) => {
                        if (a[sortBy] < b[sortBy]) {
                            return -1 * direction;
                        } else if (a[sortBy] > b[sortBy]) {
                            return 1 * direction;
                        } else {
                            return 0;
                        }
                    });
                }

                const startIndex = (pagedDataRequest.page - 1) * pagedDataRequest.pageSize;
                const endIndex = pagedDataRequest.page * pagedDataRequest.pageSize;
                const pagedSummaries = filteredSummaries.slice(startIndex, endIndex);

                const fundingAuthorizationPageResult: FundingAuthorizationPageResult = {
                    totalCount: filteredSummaries.length,
                    summaries: pagedSummaries,
                };

                return of(fundingAuthorizationPageResult);
            })
        );
    }

    public getAllFundingRequestsCount(): Observable<number> {
        return this.http
            .get<FundingRequest[]>(`${this.apiBaseUrl}/assets/mock-data/funding-requests/get-funding-requests.json`)
            .pipe(mergeMap((allFundingRequests: FundingRequest[]) => of(allFundingRequests.length)));
    }

    public getFundingRequestDetails(authorizationNum: string): Observable<FundingRequest> {
        return this.http
            .get<FundingRequest[]>(`${this.environment.apiBaseUrl}/assets/mock-data/funding-requests/get-funding-requests.json`)
            .pipe(mergeMap((data) => of(data.find((fundingRequest) => fundingRequest.authorizationNumber === authorizationNum))));
    }

    public getFundingRequestHistory(authorizationNum?: string): Observable<FundingRequestHistory> {
        return of(mockFundingRequestHistory);
    }

    public updateFundingRequestStatus(authorizationNum: string, status: string): Observable<void> {
        return of(null);
    }

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public patchFundingRequest(authorizationNum: string, patches: FundingRequestPatch[]): Observable<any> {
        if (this.throwErrorCode) {
            return throwError(new HttpErrorResponse({ status: this.throwErrorCode }));
        } else {
            return of(true);
        }
    }

    public updateAchEstimates(approvalDate: moment.Moment): void {
        let estimates: AchEstimates;
        const mockEstimates = mockAchEstimates.find((achMockData) => moment(achMockData.approvedOn, 'YYYY-MM-DD') <= approvalDate);
        if (mockEstimates) {
            estimates = mockEstimates.estimates;
        }
        setTimeout(() => {
            if (estimates) {
                this.achEstimatesSubject.next(estimates);
            } else {
                this.achEstimatesErrorSubject.next();
            }
        }, 2000);
    }
    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isString(object: any) {
        return typeof object === 'string';
    }
}

// Creates a Mock Funding Request
export const createMockFundingRequest = (): FundingRequest => ({
    applicationNumber: '77777777',
    positiveConfirmationLevel: 0,
    positiveConfirmationDisbursementLevel: 'all',
    contractor: 'Krang',
    contractorDba: 'Big man, LLC',
    contractorState: 'PA',
    applicant: {
        firstName: 'Shredder',
        lastName: 'Jones',
        address1: '123 North ave',
        address2: 'apt 2',
        city: 'New York',
        state: 'NY',
        zipCode: '888888',
        phoneNumber: '9092867065',
        communicationMethod: CommunicationMethod.sms,
    },
    lastCall: null,
    nextCall: null,
    status: 'hold',
    statusDetail: 'pc',
    isFinalDisbursement: false,
    amount: 10000,
    authorizationNumber: '9001',
    communicationPhoneNumber: '1234567890',
    loanId: '57687c9d-39eb-4c07-8301-452ab8f2c1f3',
    createdDate: '05/10/2018',
    createdBy: 'Admin',
    disbursementNumber: 1,
    maxDisbursements: 4,
    dealerFees: 500,
    netProceeds: 9500,
    tags: [],
    spectrumAccountNumber: '123456789',
    fundingRequestAuthorizationDuration: 'P0Y0M7DT19H41M39S',
    createdSubject: {
        subjectId: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
        name: null,
        givenName: null,
        familyName: null,
        userPrincipalName: 'System',
        createdDate: '2020-04-16T22:08:30.6266667',
    },
    commitmentExpirationDate: '2021-12-16T05:59:59+00:00',
    failedToFund: false,
    reamortizationDate: '2019-12-16T05:59:59+00:00',
    closedToPostingCode: '',
    accountStatus: 'OPEN',
    fundingNoticeMethod: 'Notification',
});
