import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'objectKeys' })
export class ObjectKeysPipe implements PipeTransform {
    transform(objValue): string[] {
        if (!objValue) {
            return [];
        }
        return Object.keys(objValue);
    }
}
