<eb-side-panel-template [attr.data-automation]="constantsGenericSidePanel.sidePanelContainer">
  <ng-container title-content>
    <div [attr.data-automation]="constantsGenericSidePanel.header">Ach File Audit Validation Results</div>
  </ng-container>
  <ng-container main-content>
    <div class="content-body">
      <div class="message ng-star-inserted" data-automation="QA-AchFileAuditView-SidePanel-ResultsAreaText">
        <p *ngIf="achFile && achFile.auditResult" [innerHTML]="achFile.auditResult.validationText"></p>
      </div>
    </div>
  </ng-container>
</eb-side-panel-template>
