import { Pipe, PipeTransform } from '@angular/core';

const ordinals: string[] = ['th', 'st', 'nd', 'rd'];

/*
 * Appends ordinal suffix to the input number.
 * Example: 1 -> 1st
 */
@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
    transform(input: number): string {
        const val = input % 100;
        return `${val}${ordinals[(val - 20) % 10] || ordinals[val] || ordinals[0]}`;
    }
}
