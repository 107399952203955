import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ContentFilterMenuModule } from '@components/shared/content-filter-menu/content-filter-menu.module';

import { StatusFilterMenuComponent } from './status-filter-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';

@NgModule({
    imports: [CommonModule, ContentFilterMenuModule, MatButtonModule, MatCardModule, MatListModule, FormsModule, BrowserModule, BrowserAnimationsModule],
    declarations: [StatusFilterMenuComponent],
    exports: [StatusFilterMenuComponent],
})
export class StatusFilterMenuModule {}
