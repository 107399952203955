import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AchFileTileModule } from '@components/shared/ach-file-tile/ach-file-tile.module';

import { CreateAchReviewComponent } from './create-ach-review.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    imports: [CommonModule, MatCardModule, MatProgressBarModule, AchFileTileModule],
    declarations: [CreateAchReviewComponent],
    exports: [CreateAchReviewComponent],
})
export class CreateAchReviewModule {}
