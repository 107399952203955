<div class="main-container">
  <table mat-table [dataSource]="dataSource" [attr.data-automation]="constantsACHSidePanel.achTableContainer">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [disabled]="noFiles"
                      [attr.data-automation]="constantsACHSidePanel.checkBoxHeader">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? toggleItem(row) : null"
                      [checked]="isRowSelected(row)" [attr.data-automation]="constantsACHFileRow.checkbox">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- File Name Column -->
    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsACHSidePanel.filesHeader"> Files </th>
      <td mat-cell *matCellDef="let element"><span class="file-name" [attr.data-automation]="constantsACHFileRow.achFileText"><mat-icon [attr.data-automation]="constantsACHFileRow.fileIcon">insert_drive_file</mat-icon> {{element.fileName}}</span></td>
    </ng-container>

    <!-- Time Column -->
    <ng-container matColumnDef="dateTime">
      <th mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsACHSidePanel.dateTimeHeader"> Date & Time </th>
      <td mat-cell *matCellDef="let element" [attr.data-automation]="constantsACHFileRow.dateTimeText"> {{element.createdAt | date:'M/dd/yy h:mm a'}} </td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsACHSidePanel.userNameHeader"> Username </th>
      <td mat-cell *matCellDef="let element" [attr.data-automation]="constantsACHFileRow.username"> {{element.createdBy}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" [attr.data-automation]="constantsACHSidePanel.achHeaderContainer"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="toggleItem(row)" [attr.data-automation]="constantsACHSidePanel.achRowContainer">
    </tr>
  </table>
  <div *ngIf="noFiles" class="no-files-container">
    <b [attr.data-automation]="constantsACHSidePanel.noAchToUploadTitle">No ACH files ready to upload</b>
    <span [attr.data-automation]="constantsACHSidePanel.noAchToUploadMessage">There {{numberOfAuthorizationsText}} in Approved status ready for ACH file creation</span>
    <a [class.disabled]="!linkEnabled" href="#" (click)="openCreateAch()" [attr.data-automation]="constantsACHSidePanel.createAchLink">Create ACH file</a>
  </div>
</div>

