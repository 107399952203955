import { User } from 'oidc-client';

import { Environment } from '@environments/environment.interface';

export abstract class AuthService {
    constructor(protected environment: Environment) {}

    public abstract getUser(): User;

    public abstract isUserLoggedIn(): Promise<boolean>;

    public abstract shouldRefreshToken(): Promise<boolean>;

    public abstract getAccessToken(): Promise<string>;

    public abstract getIdToken(): Promise<string>;

    public abstract loginUser(): Promise<void>;

    public abstract logoutUser(): Promise<void>;

    public abstract initialize(newUser: User): void;

    public abstract setUser(newUser: User): void;

    public abstract isAuthenticated(): boolean;

    public abstract startAuthentication(): Promise<void>;
}
