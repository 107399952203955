import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CreateAchFilePanelModule } from '@components/create-ach-file-panel/create-ach-file-panel.module';
import { CreateNewAuthorizationModule } from '@components/create-new-authorization/create-new-authorization.module';
import { FundingRequestSummaryTableModule } from '@components/funding-request-summary-table/funding-request-summary-table.module';
import { NoResultsFoundModule } from '@components/no-results-found/no-results-found.module';
import { SearchBarModule } from '@components/search-bar/search-bar.module';
import { ContentFilterMenuModule } from '@components/shared/content-filter-menu/content-filter-menu.module';
import { SidePanelTemplateModule } from '@components/side-panel-template/side-panel-template.module';
import { UpdateFundingRequestModule } from '@components/update-funding-request/update-funding-request.module';
import { UploadAchFilePanelModule } from '@components/upload-ach-file-panel/upload-ach-file-panel.module';

import { AchFilesComponent } from './ach-files.component';
import { ACHFilesTableModule } from './ach-files-table/ach-files-table.module';
import { ViewAchFileAuditPanelModule } from '@components/view-ach-file-audit-panel/view-ach-file-audit-panel.module';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        ContentFilterMenuModule,
        FundingRequestSummaryTableModule,
        SidePanelTemplateModule,
        UpdateFundingRequestModule,
        SearchBarModule,
        MatSidenavModule,
        MatSelectModule,
        NoResultsFoundModule,
        CreateNewAuthorizationModule,
        MatButtonModule,
        MatIconModule,
        CreateAchFilePanelModule,
        RouterModule,
        ACHFilesTableModule,
        UploadAchFilePanelModule,
        ViewAchFileAuditPanelModule,
    ],
    declarations: [AchFilesComponent],
    exports: [AchFilesComponent],
    providers: [],
})
export class ACHFilesModule {}
