import { HttpClientModule } from '@angular/common/http';
import { NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ACHFilesModule } from '@components/ach-files/ach-files.module';
import { DashboardModule } from '@components/dashboard/dashboard.module';
import { FundingRequestDetailsModule } from '@components/funding-request-details/funding-request-details.module';
import { HeaderModule } from '@components/header/header.module';
import { ResolverModule } from '@components/resolvers/resolver.module';
import { AnnouncementPopupModule } from '@components/shared/announcement-dialog/announcement-dialog.module';
import { EnvironmentName } from '@environments/environment-name.enum';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { serviceProviders } from '@services/services.providers';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { ROUTER_CONFIGURATION, RouterModule } from '@angular/router';

const routerProvider: Provider = {
    provide: ROUTER_CONFIGURATION, // this corresponds to the type of the second optional parameter of RouterModule.forRoot()
    useFactory: (environment: Environment) => ({ enableTracing: environment.name === EnvironmentName.dev }), // <-- debugging purposes only
    deps: [ENVIRONMENT],
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        DashboardModule,
        HeaderModule,
        HttpClientModule,
        ResolverModule,
        RouterModule.forRoot(appRoutes, {}),
        AnnouncementPopupModule,
        FundingRequestDetailsModule,
        ACHFilesModule,
    ],
    providers: [serviceProviders, routerProvider],
    bootstrap: [AppComponent],
    exports: [AppComponent, RouterModule],
})
export class AppModule {}
