import { Component, ViewChild } from '@angular/core';
import { FundingNoticeMethod } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { FundingRequestSummaryTableService } from '@services/funding-request-summary-table/funding-request-summary-table.service';
import { ConstantsObjectsAbstractFilter, ConstantsObjectsFundingNoticeMethodFilter } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Filters';
import { MatListOption, MatSelectionList } from '@angular/material/list';

@Component({
    selector: 'eb-funding-notice-method-filter-menu',
    templateUrl: './funding-notice-method-filter-menu.component.html',
    styleUrls: ['./funding-notice-method-filter-menu.component.scss']
})
export class FundingNoticeMethodFilterMenuComponent {

    public fundingNoticeMethod: FundingNoticeMethod[] = [FundingNoticeMethod.notification, FundingNoticeMethod.request];
    // ng lint is disabled to avoid member-ordering error
    /* eslint-disable-next-line */
    @ViewChild('fundingNoticeMethodList', { static: true }) public fundingNoticeMethodList: MatSelectionList;


    public menuOpen = false;

    public numberOfFiltersApplied = 0;
    public hasFiltersApplied = false;

    public constantsAbstractFilter: typeof ConstantsObjectsAbstractFilter = ConstantsObjectsAbstractFilter;
    public constantsFundingNoticeMethodFilter: typeof ConstantsObjectsFundingNoticeMethodFilter = ConstantsObjectsFundingNoticeMethodFilter;

    // ng lint is disabled to avoid member-ordering error
    /* eslint-disable-next-line */
    private readonly defaultButtonDisplayText: string = 'Funding Notice Method';
    /* eslint-disable-next-line */
    public buttonDisplayText: string = this.defaultButtonDisplayText;
    constructor(private fundingRequestSummaryTableService: FundingRequestSummaryTableService) {
        this.fundingRequestSummaryTableService.fundingNoticeMethodListFilter.subscribe((list) => this.onUpdateMethod(list));
    }

    public menuToggled(isOpen: boolean): void {
        if (isOpen) {
            this.populateCurrentFilterState();
        }

        this.menuOpen = isOpen;
    }

    public onUpdateMethod(fundingNoticeMethodList: FundingNoticeMethod[]) {
        this.numberOfFiltersApplied = fundingNoticeMethodList.length;
        if (this.numberOfFiltersApplied > 0) {
            this.buttonDisplayText = this.fundingNoticeMethod.find((x) => fundingNoticeMethodList.includes(x));
            if (this.numberOfFiltersApplied > 1) {
                this.buttonDisplayText += ' +' + (this.numberOfFiltersApplied - 1);
            }
        } else {
            this.buttonDisplayText = this.defaultButtonDisplayText;
        }
        this.hasFiltersApplied = this.numberOfFiltersApplied !== 0;
    }

    public onApply(): void {
        const fundingNoticeMethodSelected: FundingNoticeMethod[] = this.fundingNoticeMethodList.selectedOptions.selected.map((x) => x.value);
        this.fundingRequestSummaryTableService.fundingNoticeMethodListFilter.next(fundingNoticeMethodSelected);
        this.fundingRequestSummaryTableService.populateDataSource();
        this.menuOpen = !this.menuOpen;
    }

    private populateCurrentFilterState(): void {
        const { options, selectedOptions } = this.fundingNoticeMethodList;

        const selectedFundingNoticeMethod: FundingNoticeMethod[] = this.fundingRequestSummaryTableService.fundingNoticeMethodListFilter.getValue();
        const optionsToSelect: MatListOption[] = options.filter((option: MatListOption) => selectedFundingNoticeMethod.includes(option.value));
        selectedOptions.clear();
        optionsToSelect.forEach((option) => selectedOptions.select(option));
    }

}
