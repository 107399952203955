<eb-content-filter-menu buttonText="{{buttonDisplayText}}"
                        [active]="hasFiltersApplied"
                        [height]="185"
                        [width]="185"
                        (menuToggled)="menuToggled($event)"
                        [menuOpen]="menuOpen">
  <mat-card-content>
    <mat-selection-list #fundingNoticeMethodList [attr.data-automation]="constantsFundingNoticeMethodFilter.fundingNoticeMethodContainer">
      <mat-list-option *ngFor="let method of fundingNoticeMethod"
                       checkboxPosition="before"
                       [value]="method" [attr.data-automation]="constantsFundingNoticeMethodFilter.fundingNoticeMethodRow">
        {{method}}
      </mat-list-option>
    </mat-selection-list>
  </mat-card-content>

  <!--Clear/Apply Buttons-->
  <mat-card-actions [attr.data-automation]="constantsAbstractFilter.bottomButtonsContainer">
    <button mat-button class="apply-btn" (click)="onApply()" [disableRipple]="true"
      [attr.data-automation]="constantsAbstractFilter.applyButton">Apply</button>
  </mat-card-actions>
</eb-content-filter-menu>
