import { BaseService } from '@services/base.service';
import { Moment } from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

import { AchEstimates } from './models/ach-estimates.model';
import { AchFileMetadata } from './models/ach-file-metadata.model';
import { AchFileGeneratedStatus } from './achapi.service';

export abstract class AchService extends BaseService {
    public abstract achEstimatesSubject: BehaviorSubject<AchEstimates>;
    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract achEstimatesErrorSubject: Subject<any>;
    public abstract achFilesReadyForUploadSubject: BehaviorSubject<AchFileMetadata[]>;
    public abstract achFilesAllSubject: BehaviorSubject<AchFileMetadata[]>;
    public abstract achFilesSubmittedSubject: BehaviorSubject<AchFileMetadata[]>;

    /* eslint-disable */
    public abstract achFileRegenerateSubject: Subject<any>;
    public abstract achUploadFailuresSubject: Subject<any>;
    public abstract achUploadCompleteSubject: Subject<any>;
    public abstract achFileCancelSubject: Subject<any>;
    /* eslint-enable */
    public abstract get achGenerationInProgress(): boolean;

    /** Updates the current list of ACH Files ready to be uploaded to the Fed */
    public abstract updateAchFilesReadyForUpload(useSubmitted: boolean, submitted: boolean): void;

    /** Sends a request to upload the given ACH files to the Fed */
    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract markForUpload(filesToMark: AchFileMetadata[]): Subject<any>;

    /** Sends a request to create a new ACH file */
    public abstract createAchFile(achFile: AchFileMetadata): BehaviorSubject<AchFileGeneratedStatus>;

    public abstract updateAchEstimates(approvalDate: Moment): void;

    public abstract stopSubscriptionsAndReset(): void;

    public abstract regenerateAchFile(achFileId: string): void;

    public abstract cancelAchFile(achFileId: string): void;
}
