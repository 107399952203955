import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ConstantsAchFilesTable } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';
import { AchService } from '@services/ach/ach.abstract';
import { AnnouncementDialogColor, AnnouncementDialogData } from '@components/shared/announcement-dialog/announcement-dialog.component';
import { AnnouncementDialogService } from '@services/announcement-dialog/announcement-dialog.service';
import { take } from 'rxjs/operators';
import { SidePanelContent } from '@services/side-panel/models/side-panel.model';
import { PermissionsService } from '@services/shared/permissions/permissions.abstract';
import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'eb-ach-files-table',
    templateUrl: './ach-files-table.component.html',
    styleUrls: ['./ach-files-table.component.scss'],
})
export class AchFilesTableComponent implements OnInit, OnChanges {
    @ViewChild(MatSort, { static: true }) public sort: MatSort;
    @Output() displayedComponentRefEvent = new EventEmitter<{ content: SidePanelContent; achFile: AchFileMetadata }>();
    @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
    @Input() reloadAchFiles: boolean;

    public constantsAchFilesTable: typeof ConstantsAchFilesTable = ConstantsAchFilesTable;
    public displayedColumns: string[] = ['id', 'createdAt', 'createdBy', 'status', 'actions'];
    public dataSource: MatTableDataSource<AchFileMetadata> = new MatTableDataSource<AchFileMetadata>();

    //   public displayedComponent: SidePanelContent;
    public sidePanelContent: typeof SidePanelContent = SidePanelContent;
    public achAuthSectionHidden = false;
    public fundingRequestModel: FundingRequestRowViewModel = {} as FundingRequestRowViewModel;
    public pageSize: number;
    public regenerateAchFileIdsInProgress = new Set<string>();

    public cancelAction = 'cancel file';

    constructor(private permissionService: PermissionsService,
        public achService: AchService,
        public announcementDialogService: AnnouncementDialogService,
        @Inject(ENVIRONMENT) private environment: Environment) {}


    ngOnInit() {
        this.populateAchFileDataModels();
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'createdAt': {
                    return moment(item.createdAt);
                }
                default: {
                    return item[property];
                }
            }
        };
        this.permissionService.canCreateAchFile$.subscribe((canCreate) => (this.achAuthSectionHidden = canCreate));
        this.dataSource.paginator = this.paginator;
    }

    ngOnChanges() {
        if (this.reloadAchFiles) {
            this.populateAchFileDataModels();
            this.reloadAchFiles = false;
        }
    }

    public regenerateAchFileButtonClicked(achFileId: string): void {
        if(!this.regenerateAchFileIdsInProgress.has(achFileId)) {
            this.regenerateAchFileIdsInProgress.add(achFileId);
            this.achService.regenerateAchFile(achFileId);
            this.achService.achFileRegenerateSubject.subscribe(() => {
                this.populateAchFileDataModels();
                this.regenerateAchFileIdsInProgress.delete(achFileId);
            });
        }
    }

    public cancelAchFileButtonClicked(achFileId: string): void {

        this.announcementDialogService
            .openDialog({
                backgroundColor: AnnouncementDialogColor.blue,
                label: 'Cancel ACH file',
                message: 'Are you sure you want to cancel the ACH file?',
                secondaryMessage: `Ach File Id: ${achFileId}`,
                primaryButtonText: 'Never Mind',
                secondaryButtonText: 'Yes Cancel It',
                secondaryButtonAction: this.cancelAction
            } as AnnouncementDialogData)
            .pipe(take(1))
            .subscribe((action: string) => {
                switch (action) {
                    case this.cancelAction:
                        this.cancelAchFileAction(achFileId);
                        break;
                    default:
                        break;
                }
            });
    }

    public cancelAchFileAction(achFileId: string): void {
        this.achService.cancelAchFile(achFileId);
        this.achService.achFileCancelSubject.subscribe(() => {
            this.populateAchFileDataModels();
        });
    }

    public uploadAchFileButtonClicked(achFile: AchFileMetadata) {
        this.displayedComponentRefEvent.emit({
            content: this.sidePanelContent.achFileUpload,
            achFile,
        });
        this.achService.achUploadCompleteSubject.subscribe(() => {
            this.populateAchFileDataModels();
        });
    }

    public viewAuditButtonClicked(achFile: AchFileMetadata) {
        this.displayedComponentRefEvent.emit({
            content: this.sidePanelContent.achFileViewAudit,
            achFile,
        });
        this.achService.achUploadCompleteSubject.subscribe(() => {
            this.populateAchFileDataModels();
        });
    }

    public isToday(dateToCheck: Moment){
        const today = moment(new Date());

        if (dateToCheck.isSame(today, 'day') ) {
           return true;
        }

        return false;
    }

    private populateAchFileDataModels(): void {
        this.achService.updateAchFilesReadyForUpload(false, false);
        this.achService.achFilesAllSubject.pipe(take(2)).subscribe((models: AchFileMetadata[]) => {
            this.dataSource.data = models;
            this.pageSize = models.length;
        });
    }
}
