import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AchService } from '@services/ach/ach.abstract';
import { AchEstimates } from '@services/ach/models/ach-estimates.model';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';
import { AuthService } from '@services/shared/auth/auth.abstract';
import * as moment from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import { Moment } from 'moment';
import { Subscription } from 'rxjs';

import { ConstantsGenericSidePanel } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

// ng lint is disabled to avoid no-duplicate-imports error
// ng lint is disabled to avoid no-shadow error
// eslint-disable-next-line no-shadow
export enum EstimatesTemplate {
    results = 'results',
    spinner = 'spinner',
    error = 'error',
}

@Component({
    selector: 'eb-create-ach-form',
    templateUrl: './create-ach-form.component.html',
    styleUrls: ['./create-ach-form.component.scss'],
})
export class CreateAchFormComponent implements OnInit, OnDestroy {
    @Output() achDetailsEntered = new EventEmitter<AchFileMetadata>();

    public form: UntypedFormGroup;
    public dateControl: UntypedFormControl;
    public timeControl: UntypedFormControl;
    public showStepOne: boolean;
    public headerText: string;
    public estimates: AchEstimates = {
        fundingRequests: 0,
        total: 0,
    };
    public achEstimatesError = false;
    public shownAchEstimatesTemplate: EstimatesTemplate = EstimatesTemplate.spinner;
    public selectedDateTime: Moment = moment();
    public username: string;

    public estimatesTemplateEnum = EstimatesTemplate;

    public constantsCreateAchSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;

    public achEstimatesSubscription: Subscription;
    public achEstimatesErrorSubscription: Subscription;
    public timeControlSubscription: Subscription;

    constructor(public achService: AchService, public authService: AuthService) {
        this.achEstimatesSubscription = this.achService.achEstimatesSubject.subscribe((estimates: AchEstimates) => {
            this.updateRequestTotals(estimates);
        });
        this.achEstimatesErrorSubscription = this.achService.achEstimatesErrorSubject.subscribe(() => {
            this.shownAchEstimatesTemplate = EstimatesTemplate.error;
            this.achDetailsEntered.next(null);
        });
    }

    ngOnInit() {
        const user = this.authService.getUser();
        if (user && user.profile && user.profile.name) {
            this.username = user.profile.name;
        }
    }

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onDateTimeUpdated(event: any): void {
        this.selectedDateTime = event;
        this.refreshEstimates();
    }

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public refreshEstimates(): boolean {
        this.shownAchEstimatesTemplate = EstimatesTemplate.spinner;
        this.achDetailsEntered.next(null);
        this.achService.updateAchEstimates(this.selectedDateTime);
        return false;
    }

    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ngOnDestroy(): void {
        if (this.achEstimatesSubscription) {
            this.achEstimatesSubscription.unsubscribe();
        }
        if (this.timeControlSubscription) {
            this.timeControlSubscription.unsubscribe();
        }
    }

    private outputAchMetadata(): void {
        const selectedDateTimeMinute = this.selectedDateTime.minute() === 0 ? '00' : this.selectedDateTime.minute();
        if (this.selectedDateTime && this.selectedDateTime.date() && this.selectedDateTime.year() && this.selectedDateTime.hour() && selectedDateTimeMinute) {
            this.achDetailsEntered.next({
                createdAt: this.selectedDateTime,
                id: 'ACH File',
                createdBy: this.username,
            } as AchFileMetadata);
        } else {
            this.achDetailsEntered.next(null);
        }
    }

    private updateRequestTotals(estimates?: AchEstimates): void {
        if (estimates) {
            this.estimates = estimates;
            this.shownAchEstimatesTemplate = EstimatesTemplate.results;
            this.outputAchMetadata();
        } else {
            this.shownAchEstimatesTemplate = EstimatesTemplate.spinner;
        }
    }
}
