import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { AnnouncementDialogColor, AnnouncementDialogData, AnnouncementDialogIcon } from '@components/shared/announcement-dialog/announcement-dialog.component';
import { AchService } from '@services/ach/ach.abstract';
import { AchCreationStatus } from '@services/ach/achapi.service';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';
import { AnnouncementDialogService } from '@services/announcement-dialog/announcement-dialog.service';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { Subscription } from 'rxjs';

import { ConstantsGenericSidePanel } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';
import { Environment } from '../../../environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { AchFileGeneratedStatus } from '../../services/ach/achapi.service';

@Component({
    selector: 'eb-create-ach-file-panel',
    templateUrl: './create-ach-file-panel.component.html',
    styleUrls: ['./create-ach-file-panel.component.scss'],
})
export class CreateAchFilePanelComponent implements OnDestroy, OnInit {
    @Output() refreshAchFileTableEvent = new EventEmitter<{ refresh: true }>();
    public selectedTab = 0;
    public achMetadataPopulated = false;
    public constantsGenericPanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;

    private achFileMetadata: AchFileMetadata = null;
    private achServiceSubscription: Subscription;
    private sidePanelSubscription: Subscription;

    private readonly successMessage: string = 'ACH File created successfully.';
    private readonly contactAdministratorMessage: string = 'ACH file generation was unsuccessful. Please contact your administrator.';
    private readonly tryAgainMessage: string = 'ACH file generation was unsuccessful. Please try again.';
    private readonly successNoFileMessage: string = 'ACH Request was completed';
    private readonly noFileGeneratedMessage: string = 'No file was generated';
    private readonly existingRequestMessage: string = 'Unable to create more than 1 ACH File in active status';

    constructor(public achService: AchService,
        public sidePanelService: SidePanelService,
        public announcementDialogService: AnnouncementDialogService,
        @Inject(ENVIRONMENT) private environment: Environment) {}

    /** Returns private _selectedItems variable */
    public get achMetadata(): AchFileMetadata {
        return this.achFileMetadata;
    }

    public get achGenerationInProgress(): boolean {
        if (this.achService) {
            return this.achService.achGenerationInProgress;
        }
    }

    ngOnInit(): void {
        this.sidePanelSubscription = this.sidePanelService.onSideNavOpenClosed.subscribe((open: boolean) => {
            if (!open) {
                this.achService.stopSubscriptionsAndReset();
            }
        });
    }

    /** Cleaning up subscriptions on destroy */
    ngOnDestroy(): void {
        if (this.achServiceSubscription) {
            this.achServiceSubscription.unsubscribe();
        }
        if (this.sidePanelSubscription) {
            this.sidePanelSubscription.unsubscribe();
        }
        this.achService.stopSubscriptionsAndReset();
    }

    /** When called, updates value of stored private _achMetadata variable */
    public onAchDetailsEntered(achDetails: AchFileMetadata): void {
        this.achFileMetadata = achDetails;
        this.achMetadataPopulated = !!this.achMetadata;
    }

    /** Called when submit action is selected, and attempts to create an ACH file
     * using the ACH API. If successful, opens success popup, if failed opens failed popup.
     */
    public submitAchCreation(): void {
        // Submit ACH file to be created.
        this.achServiceSubscription = this.achService.createAchFile(this.achMetadata).subscribe({
            next: (status: AchFileGeneratedStatus) => {
                if (status.creationStatus === AchCreationStatus.transferred) {
                    // Open success popup, which then closes side panel
                    this.sidePanelService.openCloseSideNav.next(false);
                    this.announcementDialogService
                        .openDialog({
                            icon: status.hasFile ? AnnouncementDialogIcon.success : AnnouncementDialogIcon.failure,
                            message: status.hasFile ? this.successMessage : this.successNoFileMessage,
                            backgroundColor: AnnouncementDialogColor.blue,
                            secondaryMessage: status.hasFile ? null : this.noFileGeneratedMessage,
                        } as AnnouncementDialogData)
                        .subscribe(() => {
                            this.achService.updateAchFilesReadyForUpload(true, false);
                        });
                    this.refreshAchFileTableEvent.emit({
                        refresh: true,
                    });
                }
            },
            // ng lint throws error for builtin variables , so disabled below
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (err: any) => {
                this.sidePanelService.openCloseSideNav.next(false);
                let message = null;
                if (err.status === 422) {
                    message = this.existingRequestMessage;
                } else if (AchCreationStatus.failed) {
                    message = this.contactAdministratorMessage;
                } else {
                    message = this.tryAgainMessage;
                }
                this.announcementDialogService
                    .openDialog({
                        icon: AnnouncementDialogIcon.failure,
                        message,
                        backgroundColor: AnnouncementDialogColor.grey,
                    } as AnnouncementDialogData)
                    .subscribe(() => {});
            },
        });
    }
}
