import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { FundingRequestService } from '@services/funding-request/funding-request.abstract';
import { map, take } from 'rxjs/operators';

import { ConstantsHistoryPage, ConstantsHistoryRow } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_DetailPages';
import { ConstantsObjectsLabelValuePair } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { FundingRequestHistory } from '../../../services/funding-request/models/funding-request-history-event-model';
import { MessageStatusHistory } from '../../../services/funding-request/models/message-status-history.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MessageStatusHistoryEmailDialogComponent } from './message-status-history-email-dialog/message-status-history-email-dialog.component';

@Component({
    selector: 'eb-message-status-history',
    templateUrl: './message-status-history.component.html',
    styleUrls: ['./message-status-history.component.scss'],
})
export class MessageStatusHistoryComponent implements OnInit {
    @Input() authorizationNumber: string;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    public displayedColumns: string[] = ['createdDate', 'type', 'status', 'parties', 'message'];
    public dataSource: MatTableDataSource<MessageStatusHistory>;
    public defaultSortedData: MessageStatusHistory[];

    // E2E TESTS
    public constantsHistoryPage: typeof ConstantsHistoryPage = ConstantsHistoryPage;
    public constantsHistoryRow: typeof ConstantsHistoryRow = ConstantsHistoryRow;
    public constantsObjectsLabelValuePair: typeof ConstantsObjectsLabelValuePair = ConstantsObjectsLabelValuePair;

    constructor(private fundingRequestService: FundingRequestService, private dialog: MatDialog) { }

    ngOnInit() {
        this.setSortChangesSubscription();
        this.getFundingRequestEvents();
    }

    public showText(messageStatusHistory: MessageStatusHistory) {
        messageStatusHistory.viewAll = !messageStatusHistory.viewAll;
    }

    public showEmail(messageStatusHistory: MessageStatusHistory) {
        this.dialog.open(MessageStatusHistoryEmailDialogComponent, { data: messageStatusHistory, maxHeight: '90vh' });
    }

    private getFundingRequestEvents() {
        this.fundingRequestService
            .getFundingRequestHistory(this.authorizationNumber)
            .pipe(
                take(1),
                map((res: FundingRequestHistory) => {
                    res.messageStatusHistories.forEach((msh) => (msh.messageDate = new Date(msh.messageDate)));
                    res.messageStatusHistories.forEach((msh) => (msh.recipient = this.formatPhoneNumber(msh.recipient)));
                    res.messageStatusHistories.forEach((msh) => (msh.sender = this.formatPhoneNumber(msh.sender)));
                    res.messageStatusHistories.forEach((msh) => (msh.status = this.formatMessageStatus(msh)));
                    res.messageStatusHistories.forEach((msh) => (msh.messageType = this.formatMessageType(msh)));
                    return res;
                })
            )
            .subscribe((history: FundingRequestHistory) => {
                this.dataSource = new MatTableDataSource<MessageStatusHistory>([]);
                // eslint-disable-next-line
                this.defaultSortedData = history.messageStatusHistories.sort((a, b) => +new Date(b.messageDate) - +new Date(a.messageDate));
                this.dataSource.data = this.defaultSortedData;
                this.dataSource.sortingDataAccessor = (item, property) => {
                    if (property.includes('.')) {
                        return property.split('.').reduce((o, i) => o[i], item);
                    }
                    return item[property];
                };
                this.dataSource.sort = this.sort;
            });
    }

    private setSortChangesSubscription() {
        this.sort.sortChange.subscribe(() => {
            if (this.sort.direction === '') {
                this.dataSource.data = this.defaultSortedData;
                this.dataSource.sort = this.sort;
            }
        });
    }

    private formatPhoneNumber(phNum: string): string {
        if (phNum == null || phNum.includes('@')) {
            return phNum;
        } else {
            const num = parseInt(phNum, 10);
            if (!isNaN(num)) {
                if (num.toString().length > 10) {
                    return `(${num.toString().slice(1, 4)}) ${num.toString().slice(4, 7)}-${num.toString().slice(7, 11)}`;
                } else {
                    return `(${num.toString().slice(0, 3)}) ${num.toString().slice(3, 6)}-${num.toString().slice(6, 10)}`;
                }
            }
        }
    }

    private capitalize(s: string): string {
        if (typeof s !== 'string') {
            return '';
        }
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    private formatMessageStatus(messageStatusHistory: MessageStatusHistory): string {
        return this.capitalize(messageStatusHistory.status);
    }

    private formatMessageType(messageStatusHistory: MessageStatusHistory): string {
        if (messageStatusHistory.messageType === 'sms') {
            messageStatusHistory.messageType = 'text';
        }
        return this.capitalize(messageStatusHistory.messageType);
    }
}
