import { Injectable } from '@angular/core';


import { LoggerService } from '@services/shared/logger/logger.abstract';

import { AuthService } from './auth.abstract';

@Injectable()
export class AuthGuardService  {
    constructor(private authService: AuthService, private logger: LoggerService) {}

    public canActivate(): boolean {
        if (this.authService.isAuthenticated()) {
            return true;
        }

        this.authService.startAuthentication();
        return false;
    }
}
