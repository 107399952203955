import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Status } from '@components/funding-request-summary-table/models/status.model';
import { FundingRequestSummaryTableService } from '@services/funding-request-summary-table/funding-request-summary-table.service';

import { ConstantsObjectsAbstractFilter, ConstantsObjectsStatusFilter } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Filters';
import { MatListOption, MatSelectionList } from '@angular/material/list';

@Component({
    selector: 'eb-status-filter-menu',
    templateUrl: './status-filter-menu.component.html',
    styleUrls: ['./status-filter-menu.component.scss'],
})
export class StatusFilterMenuComponent {
    public menuOpen = false;

    public numberOfFiltersApplied = 0;
    public hasFiltersApplied = false;

    // QA Config
    // TODO: any QA-specific ids/etc that are needed go here
    public constantsAbstractFilter: typeof ConstantsObjectsAbstractFilter = ConstantsObjectsAbstractFilter;
    public constantsStatusFilter: typeof ConstantsObjectsStatusFilter = ConstantsObjectsStatusFilter;
    public selectedStatuses: Status[];
    public status: Status[] = [Status.new, Status.pending, Status.hold, Status.approved, Status.readyForFunding, Status.canceled, Status.funded];
    // ng lint is disabled to avoid member-ordering error
    /* eslint-disable */
    @ViewChild('statusList', { static: true }) public statusList: MatSelectionList;
    private readonly defaultButtonDisplayText: string = 'Status';
    public buttonDisplayText: string = this.defaultButtonDisplayText;
    /* eslint-enable */
    constructor(private fundingRequestSummaryTableService: FundingRequestSummaryTableService) {
        this.fundingRequestSummaryTableService.statusListFilter.subscribe((list) => this.onUpdateMethod(list));
    }

    public menuToggled(isOpen: boolean): void {
        if (isOpen) {
            this.populateCurrentFilterState();
        }

        this.menuOpen = isOpen;
    }

    public onUpdateMethod(statusList: Status[]) {
        this.numberOfFiltersApplied = statusList.length;
        if (this.numberOfFiltersApplied > 0) {
            this.buttonDisplayText = this.status.find((x) => statusList.includes(x));
            if (this.numberOfFiltersApplied > 1) {
                this.buttonDisplayText += ' +' + (this.numberOfFiltersApplied - 1);
            }
        } else {
            this.buttonDisplayText = this.defaultButtonDisplayText;
        }
        this.hasFiltersApplied = this.numberOfFiltersApplied !== 0;
        this.selectedStatuses = this.fundingRequestSummaryTableService.statusListFilter.getValue();
    }

    public onApply(): void {
        const statusSelected: Status[] = this.statusList.selectedOptions.selected.map((x) => x.value);
        this.fundingRequestSummaryTableService.statusListFilter.next(statusSelected);
        this.fundingRequestSummaryTableService.populateDataSource();
        this.menuOpen = !this.menuOpen;
    }

    private populateCurrentFilterState(): void {
        const { options, selectedOptions } = this.statusList;

        const selectedStatuses: Status[] = this.fundingRequestSummaryTableService.statusListFilter.getValue();
        const optionsToSelect: MatListOption[] = options.filter((option: MatListOption) => selectedStatuses.includes(option.value));
        selectedOptions.clear();
        optionsToSelect.forEach((option) => selectedOptions.select(option));
    }
}
