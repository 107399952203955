// Contains values for page element IDs found on the Funding Management Modals

export class ConstantsGenericModal {
    public static modalContainer = 'QA-Modal-ModalContainer';
    public static headerTitle = 'QA-Modal-HeaderTitle';
    public static statement = 'QA-Modal-Statement';
    public static cancelButton = 'QA-Modal-CancelButton';
    public static proceedButton = 'QA-Modal-ProceedButton';
}

export class ConstantsCompletionModal {
    public static icon = 'QA-Modal-Icon';
    public static statement = 'QA-Modal-Statement';
    public static button = 'QA-Modal-OkayButton';
    public static label = 'QA-Modal-Label';
    public static secondaryStatement = 'QA-Modal-SecondaryStatement';
}

export class ConstantsCreateACHFile {
    // Step 1a
    public static step2Container = 'QA-ConstantsCreateACHFile-Step2Container';
    public static backButton = 'QA-ConstantsCreateACHFile-BackButton';
    public static backButtonIcon = 'QA-ConstantsCreateACHFile-BackButtonIcon';
    public static backButtonText = 'QA-ConstantsCreateACHFile-BackButtonText';
    public static confirmFiledCreationStatement = 'QA-ConstantsCreateACHFile-ConfirmFiledCreationStatement';
    public static createFileButton = 'QA-ConstantsCreateACHFile-CreateFileButton';
}
