import { Injectable } from '@angular/core';
import {
    CommunicationMethod,
    convertFundingNoticeMethodToRawFundingNoticeMethodResponse,
    FundingNoticeMethod,
    FundingRequestRowViewModel,
    getFieldNameOnFundingAuthorizationSummaryResponseFromFundingRequestRowViewModel,
} from '@components/funding-request-summary-table/models/funding-request-table.models';
import { closedStatuses, Status } from '@components/funding-request-summary-table/models/status.model';
import { convertStatusToApiString } from '@services/funding-request/models/response-status-converters';
import { BehaviorSubject, Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { FundingAuthorizationPageResult, FundingAuthorizationSummary } from '../funding-request/funding-authorization.model';
import { FundingRequestService } from '../funding-request/funding-request.abstract';
import { PagedDataRequest } from '../funding-request/models/paged-data-request.model';
import { MatTableDataSource } from '@angular/material/table';

interface DashboardStatusFilterStateMap {
    [status: string]: BehaviorSubject<boolean>;
}

@Injectable()
export class FundingRequestSummaryTableService {
    public dataSource: MatTableDataSource<FundingRequestRowViewModel> = new MatTableDataSource<FundingRequestRowViewModel>();

    public readonly numTotalFundingRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public readonly numFilteredFundingRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public readonly anyFilterChange: BehaviorSubject<string> = new BehaviorSubject<string>('');


    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public readonly clearSearch: Subject<any> = new Subject<any>();

    public readonly hasActiveFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public readonly fundingAuthorizationData: BehaviorSubject<FundingAuthorizationSummary[]> = new BehaviorSubject<FundingAuthorizationSummary[]>([]);

    public hideClosed = true;

    // sorting filter
    public sortBy: string;
    public sortDirection: string;

    // pagination filter
    public pageIndex = 0;
    public pageSize = 100;
    public currentFilterTriggeredByPageOptionsChange = false;

    // filters

    public searchFilter: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public communicationListFilter: BehaviorSubject<CommunicationMethod[]> = new BehaviorSubject<CommunicationMethod[]>([]);
    public statusListFilter: BehaviorSubject<Status[]> = new BehaviorSubject<Status[]>([]);
    public fundingNoticeMethodListFilter: BehaviorSubject<FundingNoticeMethod[]> = new BehaviorSubject<FundingNoticeMethod[]>([]);

    // filter constants
    private readonly statusFilterKey: string = 'status';
    private readonly communicationMethodFilterKey: string = 'communicationMethod';
    private readonly fundingNoticeMethodFilterKey: string = 'fundingNoticeMethod';
    private readonly textSearchFilterKey: string = 'textSearch';

    constructor(public fundingRequestService: FundingRequestService) {
        // this.initializeStatusAndCommunicationFilters();
        this.populateDataSource();
    }

    public populateDataSource(): void {
        this.hasActiveFilters.next(this.areAnyActiveFilters());

        const pagedDataRequest: PagedDataRequest = {
            filterBy: {},
            page: 1,
            pageSize: 100,
        };
        this.applySearchFilter(pagedDataRequest);
        this.applyActiveStatusFilters(pagedDataRequest);
        this.applyFundingNoticeMethodFilter(pagedDataRequest);
        this.applyCommunicationFilter(pagedDataRequest);

        // apply non-filtration options
        this.applySortingOptions(pagedDataRequest);
        this.applyPagingOptions(pagedDataRequest);

        this.fundingRequestService
            .getFundingRequestsByPage(pagedDataRequest)
            .pipe(
                take(1),
                mergeMap((fundingAuthorizationsPageResult: FundingAuthorizationPageResult) => {
                    const totalFilteredCount: number = fundingAuthorizationsPageResult.totalCount;

                    this.numFilteredFundingRequests.next(totalFilteredCount);

                    // refresh data to be displayed
                    this.fundingAuthorizationData.next(fundingAuthorizationsPageResult.summaries);

                    return this.fundingRequestService.getAllFundingRequestsCount();
                })
            )
            .subscribe((allCount: number) => {
                this.numTotalFundingRequests.next(allCount);

                // reset any per-request flags
                this.currentFilterTriggeredByPageOptionsChange = false;
            });
    }

    public filterBySearch(searchValue: string): void {
        // Don't initiate a search/filter unless the user enters
        // 2 or more characters into the search bar or we're clearing
        // the filter by passing in an empty string.
        if (searchValue.length !== 1) {
            this.searchFilter.next(searchValue);
            this.populateDataSource();
        }
    }

    public clearAllFilters(): void {
        this.communicationListFilter.next([]);
        this.fundingNoticeMethodListFilter.next([]);
        this.statusListFilter.next([]);
        this.hasActiveFilters.next(false);
        this.hideClosed = true;
        this.sortBy = null;
        this.sortDirection = null;
        this.populateDataSource();
    }

    public clearSearchBar(): void {
        this.clearSearch.next();
    }

    public changeSort(sortBy: string, sortDirection: string): void {
        this.sortBy = sortBy;
        this.sortDirection = sortDirection;
        this.populateDataSource();
    }

    public changePage(pageIndex: number, pageSize: number): void {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        this.currentFilterTriggeredByPageOptionsChange = true;
        this.populateDataSource();
    }

    private initializeStatusAndCommunicationFilters(): void {

        // this.communicationListFilter.pipe(skip(1)).subscribe(() => this.populateDataSource());
        // this.statusListFilter.pipe(skip(1)).subscribe(() => this.populateDataSource());
    }

    private applyActiveStatusFilters(pagedDataRequest: PagedDataRequest): void {
        const hasStatusFilter: boolean = this.statusListFilter.getValue().length > 0;

        if (hasStatusFilter) {
            const statuses: Status[] = [...this.statusListFilter.getValue()];

            pagedDataRequest.filterBy[this.statusFilterKey] = statuses.map((statusValue) => convertStatusToApiString(statusValue));
        } else {
            const statuses: Status[] = [Status.new, Status.pending, Status.hold, Status.approved, Status.readyForFunding, Status.inProcess, Status.canceled, Status.funded];

            pagedDataRequest.filterBy[this.statusFilterKey] = statuses.map((statusValue) => convertStatusToApiString(statusValue));
        }
    }

    // since transition to backened pagination, this is now basically a specialized status filter
    private applyHideClosedFilter(pagedDataRequest: PagedDataRequest): void {
        if (!this.hideClosed) {
            pagedDataRequest.filterBy[this.statusFilterKey] = pagedDataRequest.filterBy[this.statusFilterKey].concat(closedStatuses);
        }
    }

    private applySearchFilter(pagedDataRequest: PagedDataRequest): void {
        const filterVal: string = this.searchFilter.getValue();

        if (filterVal) {
            pagedDataRequest.filterBy[this.textSearchFilterKey] = filterVal;
        }
    }

    private applyCommunicationFilter(pagedDataRequest: PagedDataRequest): void {
        const hasCommunicationFilter: boolean = this.communicationListFilter.getValue().length > 0;

        if (hasCommunicationFilter) {
            const communicationMethods: CommunicationMethod[] = this.communicationListFilter.getValue();
            pagedDataRequest.filterBy[this.communicationMethodFilterKey] = communicationMethods;
        }
    }

    private applyFundingNoticeMethodFilter(pagedDataRequest: PagedDataRequest): void {
        const hasFundingNoticeMethodFilter: boolean = this.fundingNoticeMethodListFilter.getValue().length > 0;

        if (hasFundingNoticeMethodFilter) {
            const fundingNoticeMethods: FundingNoticeMethod[] = this.fundingNoticeMethodListFilter.getValue();
            pagedDataRequest.filterBy[this.fundingNoticeMethodFilterKey] = fundingNoticeMethods.map((method) => convertFundingNoticeMethodToRawFundingNoticeMethodResponse(method));
        }
    }

    private applySortingOptions(pagedDataRequest: PagedDataRequest): void {
        // only include in the request if all sort fields are present
        if (this.sortBy && this.sortDirection) {
            const mappedField: string = getFieldNameOnFundingAuthorizationSummaryResponseFromFundingRequestRowViewModel(this.sortBy);
            pagedDataRequest.sortBy = mappedField;
            pagedDataRequest.sortDirection = this.sortDirection;
        } else {
            pagedDataRequest.sortBy = 'createdDate';
            pagedDataRequest.sortDirection = 'desc';
        }
        this.sortBy = pagedDataRequest.sortBy;
        this.sortDirection = pagedDataRequest.sortDirection;
    }
    private applyPagingOptions(pagedDataRequest: PagedDataRequest): void {
        if (!this.currentFilterTriggeredByPageOptionsChange) {
            // want to reset the index in all cases except for when teh page itself was changed
            this.pageIndex = 0;
        }
        pagedDataRequest.page = this.pageIndex + 1; // API takes page number, whereas frontend tracks index
        pagedDataRequest.pageSize = this.pageSize;
    }

    private areAnyActiveFilters(): boolean {
        return this.communicationListFilter.getValue().length > 0 || this.statusListFilter.getValue().length > 0 || this.fundingNoticeMethodListFilter.getValue().length > 0 || !this.hideClosed;
    }
}
