import { Component, Input, OnInit } from '@angular/core';
import {
    AnnouncementDialogButtonBarStyle,
    AnnouncementDialogColor,
    AnnouncementDialogComponent,
    AnnouncementDialogData,
} from '@components/shared/announcement-dialog/announcement-dialog.component';
import { RoutingPaths } from '@constants';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { take } from 'rxjs/operators';

import { ConstantsHeader } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { User } from 'oidc-client';
import { ConstantsFundingRequestBar } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Dashboard';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'eb-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

    @Input()
    public routerLink: string;
    // E2E Tests
    public constantsHeader: typeof ConstantsHeader = ConstantsHeader;
    public constantsFundingRequestBar: typeof ConstantsFundingRequestBar = ConstantsFundingRequestBar;

    public user: User;
    public name: string;

    public baseRoute = RoutingPaths.fundingRequestSummaries;
    public achFilesRoute = RoutingPaths.achFiles;
    constructor(private authService: AuthService,
        private dialog: MatDialog,
        private router: Router
        ) {}

    public ngOnInit(): void {
        this.user =  this.authService.getUser();
        this.name = Array.isArray(this.user.profile.name) ? this.user.profile.name[0] : this.user.profile.name;
    }

    public onClickLogout(): boolean {
        const logoutAction = 'logout';
        const logoutDialog = this.dialog.open(AnnouncementDialogComponent, {
            data: {
                title: 'Log Out',
                message: 'Are you sure you want to logout of EnerBank Funding Management System?',
                primaryButtonText: 'Log Out',
                primaryButtonAction: logoutAction,
                secondaryButtonText: 'Cancel',
                backgroundColor: AnnouncementDialogColor.white,
                buttonBarStyle: AnnouncementDialogButtonBarStyle.rightAlignedRibbon,
            } as AnnouncementDialogData,
        });
        logoutDialog
            .afterClosed()
            .pipe(take(1))
            .subscribe((action: string) => {
                if (action === logoutAction) {
                    this.authService.logoutUser();
                }
            });
        return false;
    }
}
