<div class = "content-container" [attr.data-automation]="constantsGenericPanel.sidePanelContainer">
  <div class="title-content">
    <mat-icon class="close" (click)="closeSidePanel()" [attr.data-automation]="constantsGenericPanel.xButton">close</mat-icon>
    <div [attr.data-automation]="constantsGenericPanel.header">New Authorization</div>
  </div>
  <div class="main-content">
    <div class="search-bar" [attr.data-automation]="constantsGenericPanel.newAuthSearchSection">
      <eb-search-bar (searchInputChanged)="onSearchInputChanged($event)"
                      [placeholderText]="searchPlaceholderText"
                      [width]="searchBarWidth"></eb-search-bar>
    </div>
    <div>
      <mat-table [dataSource]="dataSource">
      <!-- Column for radio buttons -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsGenericPanel.resultsRadioButton">
          <mat-radio-button
            name="select"
            [value]="mainRowModel.loanId"
            (click)="selection.select(mainRowModel)"
            [attr.data-automation]="constantsObjectsRadioButton.radioButton">
          </mat-radio-button>
        </mat-cell>
      </ng-container>

      <!-- Column for application number -->
      <ng-container matColumnDef="applicationNumber">
        <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsGenericPanel.appNumberResultHeader">App #</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsGenericPanel.rowApplicationNumber">
          <a>
            {{mainRowModel.applicationNumber}}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Column for loan/account number -->
      <ng-container matColumnDef="loanNum">
        <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsGenericPanel.spectrumAccountNumberResultHeader">Account #</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsGenericPanel.rowSpectrumAccountNumber">
          <a>
            {{mainRowModel.spectrumAccountNumber}}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Column for Contractor -->
      <ng-container matColumnDef="contractor">
        <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsGenericPanel.contractorResultHeader">Contractor</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsGenericPanel.rowContractor">
          <a>
            {{mainRowModel.contractor}}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Column for Applicant -->
      <ng-container matColumnDef="applicantName">
        <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsGenericPanel.applicantResultHeader">Applicant</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsGenericPanel.rowApplicant">
          <a>
            {{mainRowModel.applicantName}}
          </a>
        </mat-cell>
      </ng-container>

      <!-- Column for Status -->
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsGenericPanel.statusResultHeader">Status</mat-header-cell>
        <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsGenericPanel.rowStatus">
          <a>
            {{mainRowModel.status}}
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" [attr.data-automation]="constantsGenericPanel.newAuthorizationRowContainer"></mat-row>
      </mat-table>
      <div *ngIf="!hasSearched" class="message" [attr.data-automation]="constantsGenericPanel.resultsAreaText">
        <p><b>Search criteria required</b></p>
        <p>Start by searching for an app # or acct # to create a new authorization on that application</p>
      </div>
      <div *ngIf="hasNoFilteredResults" class="message" [attr.data-automation]="constantsGenericPanel.noResults">
        <p>No results found</p>
      </div>
    </div>
  </div>
  <div class="footer">
    <button mat-button class="ls-5"
    [disabled]="!selection.hasValue()"
    (click)="onEditInWorkCenterClicked()" [attr.data-automation]="constantsGenericPanel.proceedButton">Edit in Work Center</button>
  </div>
</div>
