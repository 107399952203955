<mat-table [hidden]="!dataSource" [dataSource]="dataSource" matSort>

  <!-- Event Column -->
  <ng-container matColumnDef="eventName">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.eventColumnHeader" mat-sort-header> Event </mat-header-cell>
    <mat-cell class="two-row-cell" *matCellDef="let event">
      <span class="main-cell-content" [attr.data-automation]="constantsHistoryRow.eventRecordStatus">{{event.eventName}}</span>
      <span class="secondary-cell-content" [attr.data-automation]="constantsHistoryRow.eventRecordBy">{{event.eventDetails}}</span>
    </mat-cell>
  </ng-container>

  <!-- Modified By Column -->
  <ng-container matColumnDef="createdSubject.name">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.modifiedByColumnHeader" mat-sort-header> Modified By </mat-header-cell>
    <mat-cell [ngSwitch]="event.eventDetails" class="two-row-cell" *matCellDef="let event">
        <span *ngSwitchCase="'By PC'" class="main-cell-content" [attr.data-automation]="constantsHistoryRow.modifiedByText">System</span>
        <span *ngSwitchCase="'By Customer'" class="main-cell-content" [attr.data-automation]="constantsHistoryRow.modifiedByText">{{event.applicant.firstName}} {{event.applicant.lastName}}</span>
        <span *ngSwitchCase="'By Admin'" class="main-cell-content" [attr.data-automation]="constantsHistoryRow.modifiedByText">{{event.createdSubject.userPrincipalName}}</span>
        <span *ngSwitchCase="'By Age'" class="main-cell-content" [attr.data-automation]="constantsHistoryRow.modifiedByText">Age</span>
        <span *ngSwitchCase="'By Contractor'" class="main-cell-content" [attr.data-automation]="constantsHistoryRow.modifiedByText">{{event.createdSubject.userPrincipalName}}</span>
        <span *ngSwitchCase="'By Contractor'" class="secondary-cell-content" [attr.data-automation]="constantsHistoryRow.modifiedBySubText">{{event.createdSubject.name}}</span>
        <span *ngSwitchDefault class="main-cell-content" [attr.data-automation]="constantsHistoryRow.modifiedByText">System</span>
      </mat-cell>
  </ng-container>

  <!-- Phone Number Column -->
  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.phoneNumColumnHeader" mat-sort-header> Phone Number </mat-header-cell>
    <mat-cell class="main-cell-content" *matCellDef="let event" [attr.data-automation]="constantsHistoryRow.phoneNumRecord"> {{event.phoneNumber}} </mat-cell>
  </ng-container>

  <!-- Date/Time Column -->
  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsHistoryPage.dateTimeColumnHeader" mat-sort-header> Date and Time </mat-header-cell>
    <mat-cell class="main-cell-content" *matCellDef="let event" [attr.data-automation]="constantsHistoryRow.dateTimeRecord"> {{event.createdDate | date: 'M/d/yy h:mm a'}} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" [attr.data-automation]="constantsHistoryRow.rowContainer"></mat-row>
</mat-table>
