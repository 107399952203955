import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';

import { UpdateFundingRequestStatusComponent } from './update-funding-request-status.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    imports: [CommonModule, MatIconModule, MatRadioModule, MatGridListModule, ReactiveFormsModule],
    declarations: [UpdateFundingRequestStatusComponent],
    exports: [UpdateFundingRequestStatusComponent],
})
export class UpdateFundingRequestStatusModule {}
