<p [attr.data-automation]="constantsACHSidePanel.filesMarkedUploadedText">
  The following file(s) will be marked as uploaded to the Fed:
</p>
<mat-card>
  <mat-list [attr.data-automation]="constantsACHSidePanel.filesListContainer">
    <mat-list-item *ngFor="let item of selectedItems" [attr.data-automation]="constantsACHSidePanel.fileContainer">
      <eb-ach-file-tile [achFile]="item"></eb-ach-file-tile>
    </mat-list-item>
  </mat-list>
</mat-card>
