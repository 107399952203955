<mat-sidenav-container>
  <mat-sidenav #sideNav position="end">
    <eb-create-ach-file-panel *ngIf="displayedComponent === sidePanelContent.achFileCreate" (refreshAchFileTableEvent)="refreshAchFilesTable()"></eb-create-ach-file-panel>
    <eb-upload-ach-file-panel *ngIf="displayedComponent === sidePanelContent.achFileUpload" [achFile]="achFile"></eb-upload-ach-file-panel>
    <eb-view-ach-file-audit-panel *ngIf="displayedComponent === sidePanelContent.achFileViewAudit" [achFile]="achFile"></eb-view-ach-file-audit-panel>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="ach-files-bar">
      <div class=ach-files-container *ngIf="achAuthSectionHidden" [attr.data-automation]="constantsAchFilesBar.achFilesBar">
        <button (click)="newAchFileButtonClicked()" class="button-right ls-5" [attr.data-automation]="constantsAchFilesBar.newAchFileButton">Create ACH File</button>
      </div>
    </div>
    <div class="content-body" [attr.data-automation]="constantsAchFilesBar.pageContainer">
      <div class="table-container">
        <eb-ach-files-table (displayedComponentRefEvent)="onUploadAchFileButtonClicked($event)" [reloadAchFiles]="reloadAchFiles" [attr.data-automation]="constantsAchFilesBar.achFilesTable">
        </eb-ach-files-table>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
