import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { SearchBarComponent } from './search-bar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    imports: [CommonModule, MatIconModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    declarations: [SearchBarComponent],
    exports: [SearchBarComponent],
})
export class SearchBarModule {}
