import { FactoryProvider, InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('window');

const factory = () =>
    // javascript DOM window
    // must be returned in a factory because it's only available during runtime, so "useValue" won't work in the provider
     window
;

export const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: factory,
};
