import { FundingRequestRowViewModel } from '@components/funding-request-summary-table/models/funding-request-table.models';

export class FundingRequestCalculations {
    public static getDisplayedApplicantName(fundingRequest: FundingRequestRowViewModel): string {
        const applicantName = typeof fundingRequest.applicantName === 'string' ? fundingRequest.applicantName : '';
        const splitString = applicantName.split(',');

        let displayedApplicantName = '';
        if (splitString.length === 2) {
            displayedApplicantName += splitString[1] + ' ' + splitString[0];
        } else if (splitString.length === 1) {
            displayedApplicantName += splitString[0];
        }

        return displayedApplicantName.trim();
    }
}
