import { Component, Input } from '@angular/core';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';

import { ConstantUploadACHFilesSteps } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

@Component({
    selector: 'eb-ach-file-tile',
    templateUrl: './ach-file-tile.component.html',
    styleUrls: ['./ach-file-tile.component.scss'],
})
export class AchFileTileComponent {
    @Input() achFile: AchFileMetadata;

    // For QA Automation
    public constantsACHSidePanel: typeof ConstantUploadACHFilesSteps = ConstantUploadACHFilesSteps;

    constructor() {}
}
