import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpMethods } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { Moment } from 'moment';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription, zip } from 'rxjs';
import { catchError, mergeMap, startWith, take } from 'rxjs/operators';
import { AchService } from './ach.abstract';
import { AchEstimates } from './models/ach-estimates.model';
import { AchFileMetadata, AchFileMetadataDTO } from './models/ach-file-metadata.model';
import { AchFilesPagedModel } from './models/ach-files-paged-model';
import {FundingRequestService} from '../funding-request/funding-request.abstract';
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum AchCreationStatus {
    completed = 'completed',
    failed = 'failed',
    transferred = 'transferred',
    canceled = 'canceled'
}

export interface AchFileGeneratedStatus {
    creationStatus: AchCreationStatus;
    hasFile: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AchApiService extends AchService {

    public achFilesReadyForUploadSubject: BehaviorSubject<AchFileMetadata[]> = new BehaviorSubject<AchFileMetadata[]>([]);
    public achFilesAllSubject: BehaviorSubject<AchFileMetadata[]> = new BehaviorSubject<AchFileMetadata[]>([]);
    public achFilesSubmittedSubject: BehaviorSubject<AchFileMetadata[]> = new BehaviorSubject<AchFileMetadata[]>([]);
    public achFileGeneratedSubject: BehaviorSubject<AchFileGeneratedStatus> = new BehaviorSubject<AchFileGeneratedStatus>(null);
    /* eslint-disable */ 
    public achUploadFailuresSubject: Subject<any> = new Subject<any>();
    public achUploadCompleteSubject: Subject<any> = new Subject<any>();
    public achEstimatesSubject: BehaviorSubject<AchEstimates> = new BehaviorSubject<AchEstimates>(null);
    public achEstimatesErrorSubject: Subject<any> = new Subject<any>();
    public achFileResetSubject: Subject<any> = new Subject<any>();
    public achFileCancelSubject: Subject<any> = new Subject<any>();
    public achFileRegenerateSubject: Subject<any> = new Subject<any>();
    /* eslint-enable */
    createAchSubscription: Subscription;
    pingAchSubscription: Subscription;
    authToken: string;
    authHeaders: { headers: HttpHeaders };
    achRequestsUrl: string;
    batchEntriesUrl: string;
    private apiLabel = 'ACH Service API';
    private isAchGenerationInProgress = false;

    constructor(http: HttpClient,
        @Inject(ENVIRONMENT) environment: Environment,
        authService: AuthService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private fundingRequestService: FundingRequestService
    ) {
        super(environment, http, authService);
        this.achRequestsUrl = environment.achApi.serviceUrl + 'v1/achfiles';
        this.batchEntriesUrl = environment.achApi.serviceUrl + 'v1/batchentries';
    }

    public get achGenerationInProgress(): boolean {
        return this.isAchGenerationInProgress;
    }

    /** Returns the current list of ACH Files ready to be uploaded to the Fed */
    public updateAchFilesReadyForUpload(useSubmitted: boolean, submitted: boolean): void {
        let url = this.achRequestsUrl;
        let achFilesSubject = this.achFilesAllSubject;
        if (useSubmitted && !submitted) {
            url = `${this.achRequestsUrl}?status=transferred&pageSize=${2147483647}`;
            achFilesSubject = this.achFilesReadyForUploadSubject;
        } else {
            url = `${this.achRequestsUrl}?pageSize=${2147483647}`;
        }

        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Update ACH File Ready For Upload', url);
        this.endpointGet<AchFilesPagedModel>(`${url}`).subscribe((x: AchFilesPagedModel) => {
            const results = x.items.map((item: AchFileMetadataDTO) => new AchFileMetadata(item));
            achFilesSubject.next(results);
        });
    }

    /** Sends a request to upload the given ACH files to the Fed */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public markForUpload(filesToMark: AchFileMetadata[]): Subject<any> {
        const listOfObservables = filesToMark.map((x) => {
            const url = `${this.achRequestsUrl}/${x.id}/status`;
            this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.put, 'Mark For Upload', url);

            const body = {
                achFileStatus: 'uploaded'
            };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return this.endpointPut<any>(url, body).pipe(catchError(() => of(this.achUploadFailuresSubject.next())));
        });

        const observableList = zip(...listOfObservables);
        observableList.pipe(take(1)).subscribe(() => {
            this.achUploadCompleteSubject.next();
        });
        return this.achUploadCompleteSubject;
    }

    /** Sends a request to create a new ACH file */
    public createAchFile(achFile: AchFileMetadata): BehaviorSubject<AchFileGeneratedStatus> {
        this.isAchGenerationInProgress = true;
        const url = `${this.achRequestsUrl}`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.post, 'Create ACH File', url);
        const body = {
            approvedBefore: `${achFile.createdAt.format().replace('+', '%2B')}`
        };

        this.createAchSubscription = this.endpointPost(url, body).subscribe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (response: any) => {
                if (response) {
                    this.pingForAchFileStatus(`${this.achRequestsUrl}/${response.achFileResourceId}`);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (err: any) => {
                // Close Subject with error state so it can be handled by caller
                this.achFileGeneratedSubject.error(err);
                this.achFileGeneratedSubject = new BehaviorSubject<AchFileGeneratedStatus>({ creationStatus: AchCreationStatus.failed, hasFile: false });
            }
        );
        return this.achFileGeneratedSubject;
    }

    /** Sends a request to cancel and ach file */
    public cancelAchFile(achResourceId: string): void {
        const url = `${this.achRequestsUrl}/${achResourceId}/cancel`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.put, 'Cancel Ach File', url);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.endpointPut<any>(url).subscribe(() => {
            this.pingForAchFileStatus(`${this.achRequestsUrl}/${achResourceId}`);
        });
    }

    public updateAchEstimates(approvalDate: Moment): void {
        const url = `${this.batchEntriesUrl}/estimate`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Update ACH Estimates', url);
        this.fundingRequestService.updateAchEstimates(approvalDate);
        this.fundingRequestService.achEstimatesSubject
            .subscribe(
                (response: AchEstimates) => {
                    if (response) {
                        this.achEstimatesSubject.next({
                            fundingRequests: response.fundingRequests ? response.fundingRequests : 0,
                            total: response.total ? response.total : 0,
                        } as AchEstimates);
                    }
                },
                () => {
                    this.achEstimatesErrorSubject.next();
                }
            );
    }

    public stopSubscriptionsAndReset(): void {
        if (this.createAchSubscription) {
            this.createAchSubscription.unsubscribe();
        }
        if (this.pingAchSubscription) {
            this.pingAchSubscription.unsubscribe();
        }
        this.achFileGeneratedSubject = new BehaviorSubject<AchFileGeneratedStatus>({ creationStatus: AchCreationStatus.failed, hasFile: false });
        this.achEstimatesSubject.next(undefined);
        this.isAchGenerationInProgress = false;
    }

    public resetFailedAchFile(achFileResourceId: string): void {
        const url = `${this.achRequestsUrl}/${achFileResourceId}/reset`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.put, 'Reset Ach File', url);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.endpointPut<any>(url).subscribe(() => {
            this.achFileResetSubject.next();
        });
    }

    public regenerateAchFile(achFileResourceId: string): void {
        const url = `${this.achRequestsUrl}/${achFileResourceId}/regenerate`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.put, 'Regenerate Ach File', url);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.endpointPut<any>(url).subscribe(() => {
            this.achFileRegenerateSubject.next();
        });
    }

    private pingForAchFileStatus(url: string): void {
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Ping for ACH File Status', url);
        this.pingAchSubscription = interval(2000)
            .pipe(
                startWith(0),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mergeMap(() => this.endpointGet<any>(url))
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .subscribe((response: any) => {
                if (response && response.status === AchCreationStatus.transferred) {
                    this.achFileGeneratedSubject.next({ creationStatus: AchCreationStatus.transferred, hasFile: true } as AchFileGeneratedStatus);
                    this.pingAchSubscription.unsubscribe();
                } else if (response && response.status === AchCreationStatus.failed) {
                    this.achFileGeneratedSubject.error(AchCreationStatus.failed);
                    this.achFileGeneratedSubject = new BehaviorSubject<AchFileGeneratedStatus>({ creationStatus: AchCreationStatus.failed, hasFile: false });
                } else if (response && response.status === AchCreationStatus.canceled) {
                    this.achFileCancelSubject.next();
                } else {
                    this.achFileGeneratedSubject.next({ creationStatus: AchCreationStatus.failed, hasFile: false } as AchFileGeneratedStatus);
                }
            });
    }
}
