import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Moment } from 'moment';
// ng lint is disabled to avoid no-duplicate-imports error
// eslint-disable-next-line no-duplicate-imports
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { ConstantsGenericSidePanel } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

@Component({
    selector: 'eb-date-time-input',
    templateUrl: './date-time-input.component.html',
    styleUrls: ['./date-time-input.component.scss'],
})
export class DateTimeInputComponent implements OnInit, OnDestroy {
    @Input() selectedDateTime: Moment = moment();
    @Output() dateTimeUpdated: EventEmitter<Moment> = new EventEmitter<Moment>(true);
    public form: UntypedFormGroup;
    public dateControl: UntypedFormControl;
    public timeControl: UntypedFormControl;

    public dateControlSubscription: Subscription;
    public timeControlSubscription: Subscription;

    public constantsGenericSidePanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;

    constructor(private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        const month = (this.selectedDateTime.month() + 1).toString().padStart(2, '0');
        const day = this.selectedDateTime.date().toString().padStart(2, '0');
        const fullYearStr = this.selectedDateTime.year().toString();

        this.dateControl = new UntypedFormControl(`${fullYearStr}-${month}-${day}`);
        this.timeControl = new UntypedFormControl(this.selectedDateTime.format('HH:mm'));

        this.onDateChanged(this.dateControl.value);
        this.dateControlSubscription = this.dateControl.valueChanges.subscribe((value: Moment) => {
            // register change for all other changes
            this.onDateChanged(value);
        });

        this.onTimeChanged(this.timeControl.value); // register change with initial value
        this.timeControlSubscription = this.timeControl.valueChanges.subscribe((value: string) => {
            // register change for all other changes
            this.onTimeChanged(value);
        });

        this.form = this.formBuilder.group({});

        this.form.addControl('dateControl', this.dateControl);
        this.form.addControl('timeControl', this.timeControl);
    }

    ngOnDestroy(): void {
        if (this.dateControlSubscription) {
            this.dateControlSubscription.unsubscribe();
        }
        if (this.timeControlSubscription) {
            this.timeControlSubscription.unsubscribe();
        }
    }

    public onDateChanged(selectedDate: Moment): void {
        const selectedMoment = moment(selectedDate);
        if (selectedDate) {
            this.selectedDateTime = this.selectedDateTime.month(selectedMoment.month()).date(selectedMoment.date()).year(selectedMoment.year());
        } else {
            this.selectedDateTime.month(0);
            this.selectedDateTime.date(0);
            this.selectedDateTime.year(0);
        }
        this.dateTimeUpdated.emit(this.selectedDateTime);
    }

    public onTimeChanged(selectedTime: string): void {
        this.selectedDateTime = this.selectedDateTime.hour(parseInt(selectedTime.split(':')[0], 10)).minute(parseInt(selectedTime.split(':')[1], 10));
        this.dateTimeUpdated.emit(this.selectedDateTime);
    }
}
