import * as moment from 'moment';

import { AchEstimates } from '../models/ach-estimates.model';
import { AchFileMetadata } from '../models/ach-file-metadata.model';

export const mockAchFiles = [
    {
        id: '1',
        createdAt: moment(),
        createdBy: 'dockguy',
        status: 'New',
    } as AchFileMetadata,
    {
        id: '2',
        createdAt: moment(),
        createdBy: 'b.good',
        status: 'Completed',
    } as AchFileMetadata,
    {
        id: '3',
        createdAt: moment(),
        createdBy: 'super.loaner19',
        status: 'Failed',
    } as AchFileMetadata,
    {
        id: '4',
        createdAt: moment(),
        createdBy: 'lender123',
        status: 'Completed',
    } as AchFileMetadata,
];

export interface MockAchEstimatesData {
    estimates: AchEstimates;
    approvedOn: string;
}
export const mockAchEstimates = [
    {
        estimates: {
            fundingRequests: 98,
            total: 2345346.3,
        } as AchEstimates,
        approvedOn: '2018-06-15',
    } as MockAchEstimatesData,
    {
        estimates: {
            fundingRequests: 10,
            total: 5000,
        } as AchEstimates,
        approvedOn: '2018-05-28',
    } as MockAchEstimatesData,
    {
        estimates: {
            fundingRequests: 123,
            total: 12345.67,
        } as AchEstimates,
        approvedOn: '2018-04-08',
    } as MockAchEstimatesData,
    {
        estimates: {
            fundingRequests: 250,
            total: 2346463,
        } as AchEstimates,
        approvedOn: '2018-02-06',
    } as MockAchEstimatesData,
    {
        estimates: {
            fundingRequests: 570,
            total: 900000.07,
        } as AchEstimates,
        approvedOn: '2018-01-09',
    } as MockAchEstimatesData,
];
