<p class="panel-title" [attr.data-automation]="constantsCreateAchSidePanel.reviewAndSubmitHeader">
  Create the following ACH File?
</p>
<mat-card class="file-details">
  <div *ngIf="estimates" class="detail-row">
    <div class="detail-column" [attr.data-automation]="constantsCreateAchSidePanel.approvedAsOf">
      <span class="detail-column-header" [attr.data-automation]="constantsObjectsLabelValuePair.label">APPROVED AS OF</span>
      <span [attr.data-automation]="constantsObjectsLabelValuePair.value">{{achFile.createdAt.format('M/DD/YY h:mmA')}}</span>
    </div>
    <div class="detail-column" [attr.data-automation]="constantsCreateAchSidePanel.authorizations">
      <span class="detail-column-header" [attr.data-automation]="constantsObjectsLabelValuePair.label">AUTHORIZATIONS</span>
      <span [attr.data-automation]="constantsObjectsLabelValuePair.value">{{estimates.fundingRequests}}</span>
    </div>
    <div class="detail-column" [attr.data-automation]="constantsCreateAchSidePanel.estimatedAchTotal">
      <span class="detail-column-header" [attr.data-automation]="constantsObjectsLabelValuePair.label">ESTIMATED ACH TOTAL</span>
      <span [attr.data-automation]="constantsObjectsLabelValuePair.value">{{estimates.total | currency}}</span>
    </div>
  </div>
  <eb-ach-file-tile [achFile]="achFile" [attr.data-automation]="constantsCreateAchSidePanel.achRowContainer"></eb-ach-file-tile>
</mat-card>
<mat-card *ngIf="!achGenerationInProgress" class="lock-disclaimer" [attr.data-automation]="constantsCreateAchSidePanel.achGenerationMessage">
  <p style="margin-left: 20px;">
    Generating this ACH file will lock the eligible authorizations until they are marked as uploaded to the Federal Reserve.</p>
</mat-card>
<mat-card *ngIf="achGenerationInProgress" class="generation-progress">
  <p>
    <b>Creating ACH Files</b> Please do not close this window.
  </p>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</mat-card>