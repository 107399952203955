<form *ngIf="selectedDateTime" name="form" [formGroup]="form">
  <div class="pickers">
    <div class="datepicker-container" [attr.data-automation]="constantsGenericSidePanel.datePickerContainer">
      <div [attr.data-automation]="constantsGenericSidePanel.dateTimePickerHeader">Date</div>
      <eb-datepicker ngDefaultControl
        formControlName="dateControl">
      </eb-datepicker>
    </div>

    <div [attr.data-automation]="constantsGenericSidePanel.timePickerContainer">
      <div [attr.data-automation]="constantsGenericSidePanel.dateTimePickerHeader">Time</div>
      <input class="timepicker" type="time" [formControl]="timeControl" (timeChange)="onTimeChanged($event)">
    </div>
  </div>
</form>
