import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FundingRequestSummaryTableTruncateComponent } from './funding-request-summary-table-truncate.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [CommonModule, MatTooltipModule],
    declarations: [FundingRequestSummaryTableTruncateComponent],
    exports: [FundingRequestSummaryTableTruncateComponent],
})
export class FundingRequestSummaryTableTruncateModule {}
