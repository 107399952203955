import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SearchBarComponent } from '@components/search-bar/search-bar.component';
import { LoanStatus } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { LoanService } from '@services/loan/loan.abstract';
import { NewAuthTableService } from '@services/new-auth-table/new-auth-table.service';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { ConstantsObjectsRadioButton } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { ConstantsGenericSidePanel } from '../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';

import { createNewAuthRowViewModelFromLoanResponse, NewAuthRowViewModel } from './models/create-new-auth-table.models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'eb-create-new-authorization',
    templateUrl: './create-new-authorization.component.html',
    styleUrls: ['./create-new-authorization.component.scss'],
})
export class CreateNewAuthorizationComponent implements OnInit {
    @ViewChild(SearchBarComponent, { static: true }) searchBar;

    public searchPlaceholderText = 'Enter app # or acct # to search for Ready to Fund and Request Pending applications';
    public searchBarWidth = '80%';

    public dataSource: MatTableDataSource<NewAuthRowViewModel>;

    public displayedColumns: string[] = ['select', 'applicationNumber', 'loanNum', 'contractor', 'applicantName', 'status'];

    public selection: SelectionModel<NewAuthRowViewModel> = new SelectionModel(false, []);
    public hasSearched = false;
    public hasNoFilteredResults = false;
    public constantsGenericPanel: typeof ConstantsGenericSidePanel = ConstantsGenericSidePanel;
    public constantsObjectsRadioButton: typeof ConstantsObjectsRadioButton = ConstantsObjectsRadioButton;
    private models: NewAuthRowViewModel[] = [];
    
    constructor(
        private loanService: LoanService,
        private newAuthTableService: NewAuthTableService,
        private sidePanelService: SidePanelService,
        @Inject(ENVIRONMENT) private environment: Environment) { 
            this.dataSource = newAuthTableService.dataSource; 
        }

    public get mdBaseUrl(): string {
        return this.environment.mdBaseUrl;
    }

    ngOnInit() {
        this.getFundingRequestRowViewModels()
            .pipe(take(2))
            .subscribe((models: NewAuthRowViewModel[]) => {
                const { dataSourceUpdated, numTotalFundingRequests } = this.newAuthTableService;

                this.models = models;
                this.dataSource.data = [];

                dataSourceUpdated.next([]);
                numTotalFundingRequests.next(this.dataSource.data.length);
            });

        this.newAuthTableService.numFilteredFundingRequests.subscribe((num) => (this.hasNoFilteredResults = num === 0 && this.hasSearched));
    }

    public onSearchInputChanged(searchValue: string): void {
        if (searchValue.length > 1) {
            this.hasSearched = true;
            this.dataSource.data = this.models;
        } else if (searchValue.trim().length === 0) {
            this.dataSource.data = [];
            this.hasSearched = false;
            this.selection.clear();
        }
        this.newAuthTableService.filterBySearch(searchValue);

        if (this.selection.hasValue() && !this.newAuthTableService.matchesSearchFilter(this.selection.selected[0], searchValue)) {
            this.selection.clear();
        }
    }

    public closeSidePanel(): void {
        this.searchBar.onClearButtonClicked();
        this.sidePanelService.openCloseSideNav.next(false);
    }

    public onEditInWorkCenterClicked(): void {
        // button should be disabled if there is no selected value, but just in case
        if (this.selection.hasValue) {
            const loanId = this.selection.selected[0].loanId;

            const loanStatus = this.selection.selected[0].status;
            if (loanStatus === LoanStatus.readyToFund) {
                window.open(`${this.mdBaseUrl}/loans/${loanId}/actions/request-funds`, '_blank');
            } else if (loanStatus === LoanStatus.requestPending) {
                window.open(`${this.mdBaseUrl}/loans/${loanId}`, '_blank');
            }
        }
    }

    private getFundingRequestRowViewModels(): Observable<NewAuthRowViewModel[]> {
        const loanResult = this.loanService.getNewAuthLoans();

        const masterRowViewModels: Observable<NewAuthRowViewModel[]> = loanResult.pipe(
            mergeMap((loans) => {
                const rows: NewAuthRowViewModel[] = [];

                // Map funding requests.
                loans.forEach((loan) => {
                    const masterRowViewModel = createNewAuthRowViewModelFromLoanResponse(loan);
                    if (masterRowViewModel.status != null) {
                        rows.push(masterRowViewModel);
                    }
                });
                return of(rows);
            })
        );
        return masterRowViewModels;
    }
}
