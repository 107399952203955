<mat-table [dataSource]="dataSource" class="mat-elevation-z2 funding-request-table" matSort (matSortChange)="onSortChanged($event)" matSortActive="{{sortBy}}" matSortDirection="{{sortDirection}}">
  <!-- Row Type Icon -->
  <ng-container matColumnDef="icon">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsAppHeader.headerAuthIcon">&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <ng-container [ngSwitch]="mainRowModel.status">
        <mat-icon *ngSwitchCase="statusType.funded" [attr.data-automation]="constantsAppRow.authIcon">check_circle</mat-icon>
        <mat-icon *ngSwitchCase="statusType.canceled" [attr.data-automation]="constantsAppRow.authIcon">cancel</mat-icon>
        <eb-circle-icon *ngSwitchCase="statusType.inProcess" class="ach"><mat-icon [attr.data-automation]="constantsAppRow.lockedByAchIcon">lock</mat-icon></eb-circle-icon>
        <eb-circle-icon *ngSwitchCase="statusType.working" class="user"><mat-icon [attr.data-automation]="constantsAppRow.lockedByUserIcon">person</mat-icon></eb-circle-icon>
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Application Number -->
  <ng-container matColumnDef="applicationNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerApplicationNumber">Application #</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <span *ngIf="mainRowModel.status === statusType.inProcess" class="locked-text ach" [attr.data-automation]="constantsAppRow.lockedByAch">Locked by ACH process</span>
      <span *ngIf="mainRowModel.status === statusType.working" class="locked-text user" [attr.data-automation]="constantsAppRow.lockedByUser">Locked by {{mainRowModel.currentEditingBy}}</span>
      <a class="application-num-text" [attr.data-automation]="constantsAppRow.applicationNumber" (click)="onApplicationNumberClicked($event, mainRowModel.authorizationNum)">
        {{mainRowModel.applicationNumber}}
      </a>
    </mat-cell>
  </ng-container>

  <!-- Positive Confirmation Level -->
  <ng-container matColumnDef="positiveConfirmationLevel">
    <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
        <span
          *ngIf="mainRowModel.positiveConfirmationLevel > 0"
          class="confirmation-level"
          matTooltip="Positive Confirmation Level {{mainRowModel.positiveConfirmationLevel}}"
          matTooltipPosition="right" matTooltipClass="mt-tooltip"
          [attr.data-automation]="constantsAppRow.positiveConfirmationLevel">
          {{mainRowModel.positiveConfirmationLevel}}
        </span>
      <mat-icon
        *ngIf="(!mainRowModel.positiveConfirmationLevel || mainRowModel.positiveConfirmationLevel === 0) && mainRowModel.fundingNoticeMethod === 'Notification'"
        [inline]="true"
        class="funding-notice"
        matTooltip="Funding Notice Method: Notification"
        matTooltipPosition="right"  matTooltipClass="mt-tooltip"
        [attr.data-automation]="constantsAppRow.fundingNoticeMethod">N</mat-icon>
      <mat-icon
        *ngIf="mainRowModel.positiveConfirmationLevel === 0 && mainRowModel.fundingNoticeMethod !== 'Notification'"
        [inline]="true"
        class="confirmation-level"
        matTooltip="Contact by phone"
        matTooltipPosition="right"  matTooltipClass="mt-tooltip"
        [attr.data-automation]="constantsAppRow.positiveConfirmationLevel">phone
      </mat-icon>
    </mat-cell>
  </ng-container>

  <!-- Contractor -->
  <ng-container matColumnDef="contractor">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerContractor">Contractor</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAppRow.contractor">
        {{mainRowModel.contractor}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Applicant Name -->
  <ng-container matColumnDef="applicantName">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerApplicant">Applicant</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAppRow.applicant">
        {{mainRowModel.applicantName}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Applicant's State -->
  <ng-container matColumnDef="applicantState">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      <eb-funding-request-summary-table-truncate content='Applicant State' maxLength=4 [attr.data-automation]="constantsAppHeader.headerApplicantState">
      </eb-funding-request-summary-table-truncate>
    </mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAppRow.applicantState">
        {{mainRowModel.applicantState}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Created Date -->
  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      <eb-funding-request-summary-table-truncate content='Created Date' maxLength=12 [attr.data-automation]="constantsAppHeader.headerCreatedDate">
      </eb-funding-request-summary-table-truncate>
    </mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAppRow.createdDate">
        {{mainRowModel.createdDate | date:'M/dd/yy h:mm a'}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Last Call -->
  <ng-container matColumnDef="lastCall">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerLastCall">Last Call</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsAppRow.lastCallContainer">
      <ng-container
        *ngIf="mainRowModel.lastCall && mainRowModel.lastCall.isValid(); then showDate; else setTime">
      </ng-container>
      <ng-template #showDate>
        <div class="call-date-time">
          <span [attr.data-automation]="constantsAppRow.Value">{{mainRowModel.lastCall | date:'M/dd/yy h:mm a'}}</span>
          <mat-icon (click)="onSetCallDateTimeClicked(mainRowModel, callType.last)" [attr.data-automation]="constantsAppRow.editPencilIcon">edit</mat-icon>
        </div>
      </ng-template>
      <ng-template #setTime>
        <a class="set-time-text-link" (click)="onSetCallDateTimeClicked(mainRowModel, callType.last)" [attr.data-automation]="constantsAppRow.Value">
          Set Time
        </a>
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Next Call -->
  <ng-container matColumnDef="nextCall">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerNextCall">Next Call</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsAppRow.nextCallContainer">
      <ng-container
        *ngIf="mainRowModel.nextCall && mainRowModel.nextCall.isValid(); then showDate; else setTime">
      </ng-container>
      <ng-template #showDate>
        <div class="call-date-time">
          <span [attr.data-automation]="constantsAppRow.Value">{{mainRowModel.nextCall | date:'M/dd/yy h:mm a'}}</span>
          <mat-icon (click)="onSetCallDateTimeClicked(mainRowModel, callType.next)" [attr.data-automation]="constantsAppRow.editPencilIcon">edit</mat-icon>
        </div>

      </ng-template>
      <ng-template #setTime>
        <a class="set-time-text-link" (click)="onSetCallDateTimeClicked(mainRowModel, callType.next)" [attr.data-automation]="constantsAppRow.Value">
          Set Time
        </a>
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Status -->
  <ng-container matColumnDef="status" class="standard-text-size">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerStatus">Status</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel" [attr.data-automation]="constantsAppRow.status">
      <ng-container
        *ngIf="mainRowModel.status !== statusType.inProcess &&
        mainRowModel.status !== statusType.readyForFunding && mainRowModel.status !== statusType.canceled && mainRowModel.status !== statusType.funded; then linkStatus; else status">
      </ng-container>
      <ng-template #linkStatus>
          <eb-funding-request-summary-table-truncate class="status-text-link" (click)="openStatusOverride(mainRowModel)" [content]="mainRowModel.status" maxLength=14 [attr.data-automation]="constantsAppRow.statusText">
          </eb-funding-request-summary-table-truncate>
      </ng-template>
      <ng-template #status>
        <div class="status-text">
          <eb-funding-request-summary-table-truncate [content]="mainRowModel.status" maxLength=14 [attr.data-automation]="constantsAppRow.statusText">
          </eb-funding-request-summary-table-truncate>
        </div>
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Status Detail -->
  <ng-container matColumnDef="statusDetail">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerStatusDetail">Status Detail</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div *ngIf="mainRowModel.statusDetail" [attr.data-automation]="constantsAppRow.statusDetail">
        By {{mainRowModel.statusDetail}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Exception -->
  <ng-container matColumnDef="exception">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAppHeader.headerException">Exception</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <eb-funding-exception-icons [fundingExceptions]="mainRowModel.fundingExceptions"></eb-funding-exception-icons>
    </mat-cell>
  </ng-container>

  <!--[...] Column-->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <mat-icon class="more-text" [matMenuTriggerFor]="moreActions" [attr.data-automation]="constantsAppRow.actionIcon">more_horiz</mat-icon>
      <mat-menu #moreActions="matMenu" [overlapTrigger]="false">
         <eb-funding-request-actions [fundingRequestModel]="mainRowModel" [attr.data-automation]="constantsActionMenu.actionMenuContainer"></eb-funding-request-actions>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns" [attr.data-automation]="constantsAppHeader.container"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [class.closed]="row.status === statusType.funded || row.status === statusType.canceled"  [attr.data-automation]="constantsAppRow.rowContainer"></mat-row>

</mat-table>
<mat-paginator
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  [length]="fundingRequestSummaryTableService.numFilteredFundingRequests | async"
  showFirstLastButtons
  (page)="onPageOptionsChanged($event)"
  [attr.data-automation]="constantsPagination.container"
  [pageSize]="pageSize">
</mat-paginator>

 
