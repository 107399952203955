// ng lint is disabled to do not change HTML variables parameters
/* eslint-disable-next-line */
export enum Status {
    pending = 'Pending',
    hold = 'Hold',
    approved = 'Approved',
    funded = 'Funded',
    canceled = 'Canceled',
    inProcess = 'In-Process',
    readyForFunding = 'Ready for Funding',
    new = 'New',
}

// a Funding Authorization/Request with these statuses are considered to be "closed"
export const closedStatuses: Status[] = [Status.funded, Status.canceled];
