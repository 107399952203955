import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BaseService } from '@services/base.service';

import { FundingAuthorizationPageResult } from './funding-authorization.model';
import { FundingRequestPatch } from './models/funding-request-patch.model';
import { FundingRequest } from './models/funding-request.model';
import { PagedDataRequest } from './models/paged-data-request.model';
import { FundingRequestHistory } from './models/funding-request-history-event-model';
import { AchEstimates } from '../ach/models/ach-estimates.model';
import { Moment } from 'moment';

export abstract class FundingRequestService extends BaseService {
    public achEstimatesSubject: BehaviorSubject<AchEstimates> = new BehaviorSubject<AchEstimates>(null);
    // ng lint is disabled to avoid no-duplicate-imports error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public achEstimatesErrorSubject: Subject<any> = new Subject<any>();
    public abstract get approvedFundingRequests(): number;
    public abstract getFundingRequests(): Observable<FundingRequest[]>;
    public abstract getFundingRequestsByPage(pagedDataRequest: PagedDataRequest): Observable<FundingAuthorizationPageResult>;
    public abstract getAllFundingRequestsCount(): Observable<number>;
    public abstract getFundingRequestDetails(authorizationNum: string): Observable<FundingRequest>;
    public abstract getFundingRequestHistory(authorizationNum: string): Observable<FundingRequestHistory>;
    public abstract updateFundingRequestStatus(authorizationNum: string, status: string): Observable<void>;
    // ng lint is disabled to avoid no-duplicate-imports error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract patchFundingRequest(authorizationNum: string, patches: FundingRequestPatch[]): Observable<any>;
    public abstract updateAchEstimates(approvalDate: Moment): void;
}
