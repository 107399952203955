import { Observable, BehaviorSubject } from 'rxjs';

import { BaseService } from '@services/base.service';

export abstract class PermissionsService extends BaseService {

    protected canCreateAchFile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // ng lint insists to specify ng disable snippet
    /* eslint-disable-next-line */
    public canCreateAchFile$: Observable<boolean> = this.canCreateAchFile.asObservable();

    public abstract initialize(): Observable<void>;

}
