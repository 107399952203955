import { FactoryProvider, Provider } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';

import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { LoggerService } from '@services/shared/logger/logger.abstract';

import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.abstract';
import { AuthMockService } from './auth.mock-service';
import { AuthApiService } from './auth.service';
import { UserManager } from 'oidc-client';
import { WINDOW } from '../window/window.provider';

const factory = (logger: LoggerService, environment: Environment, googleAnalyticsService: GoogleAnalyticsService, userManager: UserManager, window: Window) => environment.mockServicesEnabled ? new AuthMockService(environment) : new AuthApiService(logger, environment, googleAnalyticsService, userManager, window);

export const authServiceProvider: FactoryProvider = {
    deps: [LoggerService, ENVIRONMENT, GoogleAnalyticsService, UserManager, WINDOW],
    provide: AuthService,
    useFactory: factory,
};

export const authGuardServiceProvider: Provider = {
    provide: AuthGuardService,
    useClass: AuthGuardService,
};
