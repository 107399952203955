import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';

@Component({
    selector: 'eb-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(private router: Router, private googleAnalyticsService: GoogleAnalyticsService) {}

    public ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.googleAnalyticsService.sendPageViewEvent(event.urlAfterRedirects);
            }
        });
    }
}
