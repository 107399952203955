import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { Observable } from 'rxjs';

import { LoanService } from './loan.abstract';
import { Loan } from './models/loan.model';

@Injectable()
export class LoanMockService extends LoanService {
    constructor(@Inject(ENVIRONMENT) environment: Environment, http: HttpClient) {
        super(environment, http, null);
    }

    public getNewAuthLoans(): Observable<Loan[]> {
        return this.http.get<Loan[]>(`${this.apiBaseUrl}/assets/mock-data/loans/new-auth-loans.json`);
    }
}
