import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Environment } from '@environments/environment.interface';
import { from, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from './shared/auth/auth.abstract';

// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum HeaderName {
    accept = 'Accept',
    authorization = 'Authorization',
    contentType = 'Content-Type',
}
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum ContentTypeHeaderValue {
    json = 'application/json',
}
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum AcceptHeaderValue {
    json = 'application/json',
}
// ng lint insists to specify ng disable snippet
/* eslint-disable-next-line */
export enum AuthorizationHeaderTokenType {
    bearer = 'Bearer',
}

export abstract class BaseService {
    public apiBaseUrl = '';

    protected headers: HttpHeaders = new HttpHeaders();

    constructor(public environment: Environment, public http: HttpClient, public authService: AuthService) {
        this.apiBaseUrl = this.environment.apiBaseUrl;
    }

    protected endpointGet<T>(url: string): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.get<T>(url, httpOptions))
        );
    }

    protected endpointDelete<T>(url: string): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.delete<T>(url, httpOptions))
        );
    }// ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected endpointPost<T>(url: string, body?: any): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.post<T>(url, body, httpOptions))
        );
    }
    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected endpointPut<T>(url: string, body?: any): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.put<T>(url, body, httpOptions))
        );
    }
    // ng lint insists to specify ng disable snippet
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected endpointPatch<T>(url: string, body?: any): Observable<T> {
        return this.getAccessToken().pipe(
            mergeMap((token: string) => this.generateHeadersUsingToken(token)),
            mergeMap((httpOptions) => this.http.patch<T>(url, body, httpOptions))
        );
    }

    private generateHeadersUsingToken(token: string): Observable<{ headers: HttpHeaders }> {
        const httpOptions: { headers: HttpHeaders } = this.withAcceptHeader(AcceptHeaderValue.json)
            .withAuthorizationHeader(token)
            .withContentTypeHeader(ContentTypeHeaderValue.json).build();
        return of(httpOptions);
    }

    private getAccessToken(): Observable<string> {
        const getToken: Promise<string> = this.authService.getAccessToken().then((token: string) => {
            if (!token) {
                // redirects the user to login, so the returned value doesn't matter
                this.authService.loginUser();
                return '';
            }

            return token;
        });

        return from(getToken);
    }

    private withAcceptHeader(type: AcceptHeaderValue): this {
        this.headers = this.headers.set(HeaderName.accept, type);
        return this;
    }

    private withAuthorizationHeader(authToken: string): this {
        if (!authToken) {
            throw new Error(`Can't build an Authentication header without an auth token!`);
        }

        this.headers = this.headers.set(HeaderName.authorization, `${AuthorizationHeaderTokenType.bearer} ${authToken}`);
        return this;
    }
    // ng lint insists to specify ng disable snippet
    // not used as of now, but a useful function to have
    /* eslint-disable @typescript-eslint/no-unused-vars */
    private withContentTypeHeader(type: ContentTypeHeaderValue): this {
        this.headers = this.headers.set(HeaderName.contentType, type);
        return this;
    }

    private build(): { headers: HttpHeaders } {
        return {
            headers: this.headers,
        };
    }
}
