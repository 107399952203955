import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { NoResultsFoundComponent } from './no-results-found.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [CommonModule, MatCardModule, MatIconModule],
    declarations: [NoResultsFoundComponent],
    exports: [NoResultsFoundComponent],
})
export class NoResultsFoundModule {}
