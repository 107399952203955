<div [class]="backgroundColor" [class.with-label]="!!label" [attr.data-automation]="constantsGenericModal.modalContainer">
  <mat-icon *ngIf="icon" [attr.data-automation]="constantsCompletionModal.icon">{{icon}}</mat-icon>
  <span class="label" [attr.data-automation]="constantsCompletionModal.label" *ngIf="label">{{label}}</span>
  <p *ngIf="message" [attr.data-automation]="constantsCompletionModal.statement" [innerHtml]="message"></p>
  <p *ngIf="secondaryMessage" class="secondary-statement" [attr.data-automation]="constantsCompletionModal.secondaryStatement" [innerHtml]="secondaryMessage"></p>
  <div [class]="buttonClasses">
    <button *ngIf="secondaryButtonText" class="secondary" (click)="closeDialog(secondaryButtonAction)">{{secondaryButtonText}}</button>
    <button *ngIf="primaryButtonText" class="primary" (click)="closeDialog(primaryButtonAction)" [attr.data-automation]="constantsCompletionModal.button">{{primaryButtonText}}</button>
  </div>
</div>
