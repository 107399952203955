// Used in elements found in all filters
export class ConstantsObjectsAbstractFilter {
    public static filterOverlay = 'QA-Filter-Overlay';
    public static filterButtonText = 'QA-Filter-ButtonText';
    public static filterButtonArrow = 'QA-Filter-ButtonArrow';
    public static openFilterContainer = 'QA-Filter-Container';
    public static bottomButtonsContainer = 'QA-Filter-BottomButtonsContainer';
    public static applyButton = 'QA-Filter-ApplyButton';
    public static applyButtonTag = 'ng-reflect-disabled';
}

// Used to identify objects in the Communication Method filter
export class ConstantsObjectsCommunicationMethodFilter {
    public static communicationMethodContainer = 'QA-CommunicationMethodFilter-Container';
    public static communicationMethodRow = 'QA-CommunicationMethodFilter-Row';
    public static checkBoxStateTag = 'ng-reflect-state';
    public static expectedLabel = 'Communication Method';
    public static displayedOptionCount = 2;
}
/* eslint-disable */
// Used for the individual communication method options found
export class ConstantsObjectsCommunicationMethodOption {
    public static Checkbox = 'mdc-checkbox__native-control';
    public static Name = 'mdc-list-item__primary-text';
} 


export class ConstantsObjectsFundingNoticeMethodOption {
    public static Checkbox = 'mdc-checkbox__native-control';
    public static Name = 'mdc-list-item__primary-text';
}

/* eslint-enable */

// Used to identify objects in the Communication Method filter
export class ConstantsObjectsStatusFilter {
    public static statusContainer = 'QA-StatusFilter-Container';
    public static statusRow = 'QA-StatusFilter-Row';
    public static checkBoxStateTag = 'ng-reflect-state';
    public static expectedLabel = 'Status';
    public static displayedOptionCount = 7;
}

export class ConstantsObjectsFundingNoticeMethodFilter {
    public static fundingNoticeMethodContainer = 'QA-FundingNoticeMethodFilter-Container';
    public static fundingNoticeMethodRow = 'QA-FundingNoticeMethodFilter-Row';
    public static checkBoxStateTag = 'ng-reflect-state';
    public static expectedLabel = 'Funding Notice Method';
    public static displayedOptionCount = 2;
}
