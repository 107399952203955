import { Status } from '@components/funding-request-summary-table/models/status.model';

import { ResponseStatus } from './response-status.model';

// Converts Status Enum based string to an API based string
export const convertStatusToApiString = (status: Status): string => {
    switch (status) {
        case Status.pending:
            return ResponseStatus.PENDING_STATUS_API_RESPONSE;
        case Status.hold:
            return ResponseStatus.HOLD_STATUS_API_RESPONSE;
        case Status.approved:
            return ResponseStatus.APPROVED_STATUS_API_RESPONSE;
        case Status.funded:
            return ResponseStatus.FUNDED_STATUS_API_RESPONSE;
        case Status.canceled:
            return ResponseStatus.CANCELED_STATUS_API_RESPONSE;
        case Status.inProcess:
            return ResponseStatus.INPROCESS_STATUS_API_RESPONSE;
        case Status.readyForFunding:
            return ResponseStatus.READYFORFUNDING_STATUS_API_RESPONSE;
        case Status.new:
            return ResponseStatus.NEW_STATUS_API_RESPONSE;
    }
};

// Converts API based string to a Status enum.
export const convertRawStatusResponseToStatus = (str: string): Status => {
    switch (str) {
        case ResponseStatus.PENDING_STATUS_API_RESPONSE:
            return Status.pending;
        case ResponseStatus.HOLD_STATUS_API_RESPONSE:
            return Status.hold;
        case ResponseStatus.APPROVED_STATUS_API_RESPONSE:
            return Status.approved;
        case ResponseStatus.FUNDED_STATUS_API_RESPONSE:
            return Status.funded;
        case ResponseStatus.CANCELED_STATUS_API_RESPONSE:
            return Status.canceled;
        case ResponseStatus.INPROCESS_STATUS_API_RESPONSE:
            return Status.inProcess;
        case ResponseStatus.READYFORFUNDING_STATUS_API_RESPONSE:
            return Status.readyForFunding;
        case ResponseStatus.NEW_STATUS_API_RESPONSE:
            return Status.new;
    }
};
