import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RequestLockedModule } from '@components/shared/request-locked/request-locked.module';
import { SidePanelTemplateModule } from '@components/side-panel-template/side-panel-template.module';
import { UpdateFundingRequestCallTimeModule } from './update-funding-request-call-time/update-funding-request-call-time.module';
import { UpdateFundingRequestStatusModule } from './update-funding-request-status/update-funding-request-status.module';
import { UpdateFundingRequestComponent } from './update-funding-request.component';

@NgModule({
    imports: [CommonModule, SidePanelTemplateModule, UpdateFundingRequestStatusModule, UpdateFundingRequestCallTimeModule, RequestLockedModule],
    declarations: [UpdateFundingRequestComponent],
    exports: [UpdateFundingRequestComponent],
})
export class UpdateFundingRequestModule {}
