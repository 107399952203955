export class ConstantsGenericSidePanel {
    public static sidePanelContainer = 'QA-Dashboard-SidePanel-SidePanelContainer';
    public static header = 'QA-Dashboard-SidePanel-Header';
    public static xButton = 'QA-Dashboard-SidePanel-XButton';
    public static proceedButton = 'QA-Dashboard-SidePanel-ProceedButton';
    public static applicationNumber = 'QA-Dashboard-SidePanel-ApplicationNumber';
    public static applicantName = 'QA-Dashboard-SidePanel-ApplicantName';
    public static spinnerAnimation = 'QA-Dashboard-SidePanel-SpinnerAnimation';
    public static spinnerMessage = 'QA-Dashboard-SidePanel-SpinnerMessage';
    public static errorMessage = 'QA-Dashboard-SidePanel-ErrorMessage';

    // Locked Record
    public static lockedAuthIcon = 'QA-Dashboard-SidePanel-LockedAuthIcon';
    public static lockedCheckBox = 'QA-Dashboard-SidePanel-LockedCheckBox';

    // Upload ACH Files
    public static selectACHFileTab = 'QA-Dashboard-SidePanel-SelectACHFileTab';
    public static reviewAndSubmitACHFileTab = 'QA-Dashboard-SidePanel-ReviewAndSubmitACHFileTab';

    // Create ACH File
    public static nextButton = 'QA-Dashboard-SidePanel-NextButton';
    public static backButton = 'QA-Dashboard-SidePanel-BackButton';

    // New Authorization Side Panel
    public static newAuthSearchSection = 'QA-NewAuthorization-SidePanel-NewAuthSearchSection';
    public static appNumberResultHeader = 'QA-NewAuthorization-SidePanel-AppNumberResultColumn';
    public static spectrumAccountNumberResultHeader = 'QA-NewAuthorization-SidePanel-SpectrumAccountNumberResultHeader';
    public static contractorResultHeader = 'QA-NewAuthorization-SidePanel-ContractorResultColumn';
    public static applicantResultHeader = 'QA-NewAuthorization-SidePanel-ApplicantResultColumn';
    public static statusResultHeader = 'QA-NewAuthorization-SidePanel-StatusResultColumn';
    public static resultsAreaText = 'QA-NewAuthorization-SidePanel-ResultsAreaText';
    public static noResults = 'QA-NewAuthorization-SidePanel-NoResults';

    // New Authorization Side Panel - Results Row
    public static newAuthorizationRowContainer = 'QA-NewAuthorization-SidePanelRow-NewAuthorizationRowContainer';
    public static resultsRadioButton = 'QA-NewAuthorization-SidePanelRow-ResultsRadioButton';
    public static rowApplicationNumber = 'QA-NewAuthorization-SidePanelRow-RowApplicationNumber';
    public static rowSpectrumAccountNumber = 'QA-NewAuthorization-SidePanelRow-RowAccountNumber';
    public static rowContractor = 'QA-NewAuthorization-SidePanelRow-RowContractor';
    public static rowApplicant = 'QA-NewAuthorization-SidePanelRow-RowApplicant';
    public static rowStatus = 'QA-NewAuthorization-SidePanelRow-RowStatus';

    // Create ACH File Side Panel
    public static createAchFileTabsClass = 'mat-tab-label-content';
    public static approvedHeaderTitle = 'QA-CreateAchFile-SidePanel-ApprovedHeaderTitle';
    public static authorizationsMessage = 'QA-CreateAchFile-SidePanel-AuthorizationsMessage';
    public static authorizationsFailedMessage = 'QA-CreateAchFile-SidePanel-AuthorizationsFailedMessage';
    public static authorizationsTryAgainLink = 'QA-CreateAchFile-SidePanel-AuthorizationsTryAgainLink';

    // Create ACH File Review and Submit
    public static reviewAndSubmitHeader = 'QA-CreateAchFile-SidePanel-ReviewAndSubmitTitle';
    public static approvedAsOf = 'QA-CreateAchFile-SidePanel-ApprovedAsOf';
    public static authorizations = 'QA-CreateAchFile-SidePanel-Authorizations';
    public static estimatedAchTotal = 'QA-CreateAchFile-SidePanel-EstimatedAchTotal';
    public static achRowContainer = 'QA-SidePanel-AchSelect-AchRowContainer';
    public static achGenerationMessage = 'QA-CreateAchFile-SidePanel-AchGenerationMessage';

    // Set Last_Next Call Side Panel
    public static callStatementTitle = 'QA-SetLastNextCall-SidePanel-CallHeaderTitle';
    public static callStatement = 'QA-SetLastNextCall-SidePanel-CallStatement';
    public static futureError = 'QA-SetLastNextCall-SidePanel-FutureError';

    // Date_Time Pickers
    public static dateTimePickerSuperContainer = 'QA-SidePanel-DatePickerSuperContainer';
    public static datePickerContainer = 'QA-SidePanel-DatePickerContainer';
    public static timePickerContainer = 'QA-SidePanel-TimePickerContainer';
    public static dateTimePickerHeader = 'QA-SidePanel-DateTimePickerHeader';
    public static dateTimePickerInputBoxClass = 'mat-input-element';
    public static dateTimePickerIconClass = 'mat-form-field-suffix';
    public static dateTimePickerErrorMessage = 'QA-SidePanel-DatePickerErrorMessage';

    public static timePickerPopUpInputBoxClass = 'owl-dt-timer-input';
    public static timePickerMeridianClass = 'owl-dt-timer-divider';
    public static timePickerArrowsClass = 'owl-dt-control-arrow-button';
    public static timePickerTimeSelectorClass = 'owl-dt-timer-hour12-box';
    public static timePickerButtonsClass = 'owl-dt-container-control-button';
}

export class ConstantStatusDatePickerPopUp {
    public static datePickerContainerTag = 'mat-calendar';
    public static datePickerPeriodButton = 'mat-calendar-period-button';
    public static datePickerPreviousArrowButton = 'mat-calendar-previous-button';
    public static datePickerNextArrowButton = 'mat-calendar-next-button';
    public static datePickerSelectableYMDOption = 'mat-calendar-body-cell-content';
}

export class ConstantStatusOverrideSection {
    public static actionSectionContainer = 'QA-Dashboard-ActionSection-ActionSectionContainer';
    public static statusOptionLabel = 'QA-Dashboard-ActionSection-StatusOptionLabel';
    public static pendingStatus = 'QA-Dashboard-ActionSection-PendingStatus';
    public static approvedStatus = 'QA-Dashboard-ActionSection-ApprovedStatus';
    public static holdStatus = 'QA-Dashboard-ActionSection-HoldStatus';
    public static canceledStatus = 'QA-Dashboard-ActionSection-CanceledStatus';
}

export class ConstantUploadACHFilesSteps {
    // ng lint insists to specify ng disable snippet
    /* eslint-disable */
    // Used for both steps
    public static ACHFileTabsClass = 'mat-tab-label-content';
    /* eslint-enable  */

    // step one - Select
    public static achTableContainer = 'QA-SidePanel-AchSelect-AchTableContainer';
    public static achHeaderContainer = 'QA-SidePanel-AchSelect-AchHeaderContainer';
    public static achRowContainer = 'QA-SidePanel-AchSelect-AchRowContainer';
    public static checkBoxHeader = 'QA-SidePanel-AchSelect-CheckBoxHeader';
    public static filesHeader = 'QA-SidePanel-AchSelect-FilesHeader';
    public static dateTimeHeader = 'QA-SidePanel-AchSelect-DateTimeHeader';
    public static userNameHeader = 'QA-SidePanel-AchSelect-UserNameHeader';
    public static noAchToUploadTitle = 'QA-SidePanel-AchSelect-NoAchToUpload-Title';
    public static noAchToUploadMessage = 'QA-SidePanel-AchSelect-NoAchToUpload-Message';
    public static createAchLink = 'QA-SidePanel-AchSelect-NoAchToUpload-CreateAchLink';
    public static nextButton = ConstantsGenericSidePanel.proceedButton;

    // step two - Review And Submit
    public static filesMarkedUploadedText = 'QA-SidePanel-AchReviewAndSubmit-FilesMarkedUploadedText';
    public static filesListContainer = 'QA-SidePanel-AchReviewAndSubmit-FilesListContainer';
    public static fileContainer = 'QA-SidePanel-AchReviewAndSubmit-FileContainer';
    public static fileIcon = 'QA-SidePanel-AchReviewAndSubmit-FileIcon';
    public static fileDescriptionContainer = 'QA-SidePanel-AchReviewAndSubmit-FileDescriptionContainer';
    public static fileName = 'QA-SidePanel-AchReviewAndSubmit-FileName';
    public static fileDate = 'QA-SidePanel-AchReviewAndSubmit-FileDate';
    public static backButton = 'QA-SidePanel-AchReviewAndSubmit-BackButton';
}
