<mat-table [dataSource]="dataSource" class="mat-elevation-z2 ach-files-table" matSort matSortActive="createdAt" matSortDirection="desc">

  <!-- Ach File Id -->
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAchFilesTable.headersAchFileId">ACH File Id</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAchFilesTable.achFileId">
        {{mainRowModel.id}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Date Time -->
  <ng-container matColumnDef="createdAt">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAchFilesTable.headersDatetime">Date Time</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAchFilesTable.achDatetime">
        {{mainRowModel.createdAt | date:'M/dd/yy h:mm a'}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- User Name -->
  <ng-container matColumnDef="createdBy">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAchFilesTable.headersUsername">User Name</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAchFilesTable.achUsername">
        {{mainRowModel.createdBy}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Status -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header [attr.data-automation]="constantsAchFilesTable.headersStatus">Status</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div [attr.data-automation]="constantsAchFilesTable.achStatus">
        {{mainRowModel.status}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Actions -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef [attr.data-automation]="constantsAchFilesTable.headersActions">Actions</mat-header-cell>
    <mat-cell *matCellDef="let mainRowModel">
      <div *ngIf="!mainRowModel.submitted && achAuthSectionHidden && mainRowModel.status !== 'Canceled'" class="button-div" [attr.data-automation]="constantsAchFilesTable.achUpload">
        <button style="width: 102%;" (click)="uploadAchFileButtonClicked(mainRowModel)" class="button-right" [attr.data-automation]="constantsAchFilesTable.uploadAchFileButton">Mark as Uploaded</button>
      </div>
      <div *ngIf="mainRowModel.status === 'Uploaded' && achAuthSectionHidden && isToday(mainRowModel.createdAt) " class="button-div" [attr.data-automation]="constantsAchFilesTable.achRegenerate">        
        <button (click)="regenerateAchFileButtonClicked(mainRowModel.id)" class="button-right button-row" [attr.data-automation]="constantsAchFilesTable.regenerateAchFileButton">
          <mat-progress-spinner *ngIf="regenerateAchFileIdsInProgress.has(mainRowModel.id)" mode="indeterminate" diameter="15"></mat-progress-spinner>
          {{ regenerateAchFileIdsInProgress.has(mainRowModel.id) ? 'Regenerating' : 'Regenerate' }}
        </button>
      </div>
      <div *ngIf="mainRowModel.auditResult && mainRowModel.auditResult.validationText !== null" class="button-view-audit">
        <button (click)="viewAuditButtonClicked(mainRowModel)" class="button-right" [attr.data-automation]="constantsAchFilesTable.viewAchFileAuditButton">View Audit</button>
        <mat-icon *ngIf="mainRowModel.auditResult.hasError" class="md-18 red-text text-darken-4">cancel</mat-icon>
      </div>
      <div *ngIf="(mainRowModel.status !== 'Uploaded'  && mainRowModel.status !== 'Canceled')" class="button-view-audit">
        <button (click)="cancelAchFileButtonClicked(mainRowModel.id)" class="button-right" [attr.data-automation]="constantsAchFilesTable.cancelAchFileButton">Cancel</button>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-paginator style="font-size: 13px !important;"
  [length]="pageSize"
  [pageSize]="10"
  [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
