<eb-side-panel-template [attr.data-automation]="constantsGenericPanel.sidePanelContainer">
  <ng-container title-content><div [attr.data-automation]="constantsGenericPanel.header">Create ACH File</div></ng-container>
  <ng-container main-content>
    <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTab = $event">
      <mat-tab [disabled]="achGenerationInProgress">
        <ng-template mat-tab-label><div class="number-circle">1</div> Select</ng-template>
        <eb-create-ach-form (achDetailsEntered)="onAchDetailsEntered($event)"></eb-create-ach-form>
      </mat-tab>
      <mat-tab [disabled]="!achMetadataPopulated">
        <ng-template mat-tab-label><div class="number-circle">2</div> Review and Submit</ng-template>
        <eb-create-ach-review *ngIf="achMetadata && achMetadata.createdAt" [achFile]="achMetadata"></eb-create-ach-review>
      </mat-tab>
    </mat-tab-group>
    <div class="button-container">
      <button *ngIf="selectedTab === 0" class="next ls-5" [disabled]="!achMetadataPopulated" [class.disabled]="!achMetadataPopulated" (click)="selectedTab = 1" [attr.data-automation]="constantsGenericPanel.proceedButton">Next <mat-icon>chevron_right</mat-icon></button>
      <button *ngIf="selectedTab === 1" class="back ls-5" [disabled]="achGenerationInProgress" [class.disabled]="achGenerationInProgress" (click)="selectedTab = 0" [attr.data-automation]="constantsGenericPanel.backButton"><mat-icon>chevron_left</mat-icon> Back</button>
      <button *ngIf="selectedTab === 1" class="submit ls-5" [disabled]="achGenerationInProgress" (click)="submitAchCreation()" [attr.data-automation]="constantsGenericPanel.proceedButton">Create File</button>
    </div>
  </ng-container>
</eb-side-panel-template>
