<eb-side-panel-template [attr.data-automation]="constantsGenericSidePanel.sidePanelContainer">
  <ng-container title-content><div [attr.data-automation]="constantsGenericSidePanel.header">Mark file(s) as Uploaded</div></ng-container>
  <ng-container main-content>
    <mat-tab-group [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTab = $event">
      <mat-tab>
        <ng-template mat-tab-label><div class="number-circle">1</div> Select</ng-template>
        <eb-file-selection-table [achFiles]="achFiles" [achFile]="achFile" (selectedItems)="onSelectedItems($event)"></eb-file-selection-table>
      </mat-tab>
      <mat-tab [disabled]="!itemsSelected">
        <ng-template mat-tab-label><div class="number-circle">2</div> Review and Submit</ng-template>
        <eb-review-and-submit [selectedItems]="selectedItems"></eb-review-and-submit>
      </mat-tab>
    </mat-tab-group>
    <div class="button-container">
      <button *ngIf="selectedTab === 0" class="next" [disabled]="!itemsSelected" [class.disabled]="!itemsSelected" (click)="selectedTab = 1" [attr.data-automation]="constantsACHSidePanel.nextButton">Next <mat-icon>chevron_right</mat-icon></button>
      <button *ngIf="selectedTab === 1" class="back" (click)="selectedTab = 0" [attr.data-automation]="constantsACHSidePanel.backButton"><mat-icon>chevron_left</mat-icon> Back</button>
      <button *ngIf="selectedTab === 1" class="submit" [disabled]="isUploadingAch" [class.disabled]= "isUploadingAch" (click)="markUploadAch()" [attr.data-automation]="constantsGenericSidePanel.proceedButton">Submit</button>
    </div>
  </ng-container>
</eb-side-panel-template>
