import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpMethods } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { LoggerService } from '@services/shared/logger/logger.abstract';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AuthService } from '../shared/auth/auth.abstract';
import { FundingRequestService } from './funding-request.abstract';
import { ResponseStatus } from './models/response-status.model';
import { FundingAuthorizationPageResult } from './funding-authorization.model';
import { FundingRequestPatch } from './models/funding-request-patch.model';
import { FundingRequest } from './models/funding-request.model';
import { PagedDataRequest } from './models/paged-data-request.model';
import { FundingRequestHistory } from './models/funding-request-history-event-model';
import { AchEstimates } from '../ach/models/ach-estimates.model';
import { Moment } from 'moment';

@Injectable()
export class FundingRequestApiService extends FundingRequestService {
    public fundingRequests: BehaviorSubject<FundingRequest[]> = new BehaviorSubject<FundingRequest[]>([]);
    public achEstimatesSubject: BehaviorSubject<AchEstimates> = new BehaviorSubject<AchEstimates>(null);
    // ng lint is disabled to avoid member-ordering error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public achEstimatesErrorSubject: Subject<any> = new Subject<any>();
    apiBaseUrl: string;
    achEstimateUrl: string;

    private apiLabel = 'FundingManagement API';
    private approvedFundingRequestCount = 0;

    constructor(
        http: HttpClient,
        @Inject(ENVIRONMENT) environment: Environment,
        authService: AuthService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private logger: LoggerService
    ) {
        super(environment, http, authService);
        this.fundingRequests.subscribe((requests: FundingRequest[]) => {
            this.approvedFundingRequestCount = requests.filter((item) => item.status === ResponseStatus.APPROVED_STATUS_API_RESPONSE).length;
        });
        this.achEstimateUrl = this.apiBaseUrl + '/ach/estimate';
    }

    public get approvedFundingRequests(): number {
        return this.approvedFundingRequestCount;
    }

    public getFundingRequests(): Observable<FundingRequest[]> {
        this.logger.log('FundingRequestApiService: getFundingRequests()');
        const url = `${this.apiBaseUrl}/funding-requests`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Get Funding Requests', url);
        this.endpointGet<FundingRequest[]>(url)
            .pipe(take(1))
            .subscribe((fundingRequests: FundingRequest[]) => {
                this.fundingRequests.next(fundingRequests);
            });
        return this.fundingRequests;
    }

    public getFundingRequestsByPage(pagedDataRequest: PagedDataRequest): Observable<FundingAuthorizationPageResult> {
        this.logger.log('FundingRequestApiService: getFundingRequestsByPage()');
        const url = `${this.apiBaseUrl}/funding-requests/page`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.post, 'Get Funding Requests By Page', url);
        return this.endpointPost<FundingAuthorizationPageResult>(url, pagedDataRequest);
    }

    public getAllFundingRequestsCount(): Observable<number> {
        this.logger.log('FundingRequestApiService: getAllFundingRequestsCount()');
        const url = `${this.apiBaseUrl}/funding-requests/page`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.post, 'Get All Funding Requests Count', url);

        const pagedDataRequest: PagedDataRequest = {
            page: 1,
            pageSize: 1,
        };

        return this.endpointPost<FundingAuthorizationPageResult>(url, pagedDataRequest).pipe(
            mergeMap((fundingAuthorizationPageResult: FundingAuthorizationPageResult) => of(fundingAuthorizationPageResult.totalCount))
        );
    }

    public getFundingRequestDetails(authorizationNum: string): Observable<FundingRequest> {
        const url = `${this.apiBaseUrl}/funding-requests/${authorizationNum}`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Get Funding Request Details', url);

        return this.endpointGet<FundingRequest>(url);
    }

    public getFundingRequestHistory(authorizationNum: string): Observable<FundingRequestHistory> {
        const url = `${this.apiBaseUrl}/funding-requests/${authorizationNum}/history`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Get Funding Request History', url);

        return this.endpointGet<FundingRequestHistory>(url);
    }

    public updateFundingRequestStatus(authorizationNum: string, status: string): Observable<void> {
        const url = `${this.apiBaseUrl}/funding-requests/${authorizationNum}/${status}`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.put, 'Update Funding Request Status', url);

        this.endpointPut<void>(url, null).pipe(take(1)).subscribe();

        return of(null);
    }
    // ng lint is disabled to avoid member-ordering error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public patchFundingRequest(authorizationNum: string, patches: FundingRequestPatch[]): Observable<any> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let body: any[] = [];
        patches.forEach((patchObj: FundingRequestPatch) => {
            body = body.concat([
                { op: 'test', path: `/${patchObj.field}`, value: patchObj.oldValue },
                { op: 'replace', path: `/${patchObj.field}`, value: patchObj.newValue },
            ]);
        });
        const url = `${this.apiBaseUrl}/funding-requests/${authorizationNum}`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.patch, 'Patch Funding Request', url);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.endpointPatch<any>(url, body);
    }

    public updateAchEstimates(approvalDate: Moment): void {
        const url = `${this.achEstimateUrl}?approvedBy=${approvalDate.format().replace('+', '%2B')}`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Update ACH Estimates', url);
        this.endpointGet<AchEstimates>(url)
            .pipe(take(1))
            .subscribe(
                (response: AchEstimates) => {
                    if (response) {
                        this.achEstimatesSubject.next({
                            fundingRequests: response.fundingRequests ? response.fundingRequests : 0,
                            total: response.total ? response.total : 0,
                        } as AchEstimates);
                    } else {
                        this.achEstimatesErrorSubject.next();
                    }
                },
                () => {
                    this.achEstimatesErrorSubject.next();
                }
            );
    }
}
