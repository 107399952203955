import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AchFileTileComponent } from './ach-file-tile.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [AchFileTileComponent],
    exports: [AchFileTileComponent],
})
export class AchFileTileModule {}
