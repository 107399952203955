import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpMethods } from '@constants';
import { Environment } from '@environments/environment.interface';
import { ENVIRONMENT } from '@environments/environment.token';
import { AuthService } from '@services/shared/auth/auth.abstract';
import { GoogleAnalyticsService } from '@services/shared/google-analytics/google-analytics.abstract';
import { Observable } from 'rxjs';

import { LoanService } from './loan.abstract';
import { Loan } from './models/loan.model';

@Injectable()
export class LoanApiService extends LoanService {
    private apiLabel = 'FundingManagement API';

    constructor(http: HttpClient, @Inject(ENVIRONMENT) environment: Environment, authService: AuthService, private googleAnalyticsService: GoogleAnalyticsService) {
        super(environment, http, authService);
    }

    public getNewAuthLoans(): Observable<Loan[]> {
        const url = `${this.apiBaseUrl}/loans`;
        this.googleAnalyticsService.sendApiCallEvent(this.apiLabel, HttpMethods.get, 'Loans', url);
        return this.endpointGet<Loan[]>(url);
    }
}
