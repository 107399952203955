import { createFormalNameString } from '@components/funding-request-summary-table/models/funding-request-table.models';
import { ApiLoanStatusResponse, LoanStatus } from '@constants';
import { Loan } from '@services/loan/models/loan.model';

const convertRawStatusResponseToStatus = (str: string): LoanStatus => {
    switch (str) {
        case ApiLoanStatusResponse.readyToFund:
            return LoanStatus.readyToFund;
        case ApiLoanStatusResponse.requestPending:
            return LoanStatus.requestPending;
        default:
            return null;
    }
};

export interface NewAuthRowViewModel {
    applicationNumber: string;
    contractor: string;
    applicantName: string;
    loanId: string;
    status: string;
    spectrumAccountNumber: string;
}

// Creates a new RowViewModel from a raw API Funding Request Response.
export const createNewAuthRowViewModelFromLoanResponse = (loan: Loan): NewAuthRowViewModel => ({
    applicationNumber: loan.applicationNumber,
    contractor: loan.contractorName,
    applicantName: createFormalNameString(loan.applicantFirstName, loan.applicantLastName),
    loanId: loan.id,
    status: convertRawStatusResponseToStatus(loan.status),
    spectrumAccountNumber: loan.spectrumAccountNumber,
});
