import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AchFileMetadata } from '@services/ach/models/ach-file-metadata.model';
import { FundingRequestService } from '@services/funding-request/funding-request.abstract';
import { SidePanelContent } from '@services/side-panel/models/side-panel.model';
import { SidePanelService } from '@services/side-panel/side-panel.service';
import { ConstantsObjectsAchFileRow } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_Objects';
import { ConstantUploadACHFilesSteps } from '../../../../../../enerbank-fm-web-e2e/src/util/constants/Constants_SidePanel';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'eb-file-selection-table',
    templateUrl: './file-selection-table.component.html',
    styleUrls: ['./file-selection-table.component.scss'],
})
export class FileSelectionTableComponent implements OnChanges {
    @Input() achFile: AchFileMetadata;
    @Input() achFiles: AchFileMetadata[];
    @Output() selectedItems = new EventEmitter<AchFileMetadata[]>();
    displayedColumns: string[] = ['select', 'file', 'dateTime', 'username'];
    dataSource = new MatTableDataSource<AchFileMetadata>([]);
    selection = new SelectionModel<AchFileMetadata>(true, []);
    // For QA Automation
    public constantsACHSidePanel: typeof ConstantUploadACHFilesSteps = ConstantUploadACHFilesSteps;
    public constantsACHFileRow: typeof ConstantsObjectsAchFileRow = ConstantsObjectsAchFileRow;

    constructor(public fundingRequestService: FundingRequestService, public sidePanelService: SidePanelService) {}

    public get noFiles(): boolean {
        return !this.achFiles || this.achFiles.length < 1;
    }

    public get numberOfAuthorizationsText(): string {
        if (this.fundingRequestService) {
            if (this.fundingRequestService.approvedFundingRequests === 1) {
                return 'is 1 Authorization';
            } else {
                return 'are ' + this.fundingRequestService.approvedFundingRequests + ' Authorizations';
            }
        }
    }

    public get linkEnabled(): boolean {
        return this.fundingRequestService && this.fundingRequestService.approvedFundingRequests > 0;
    }

    ngOnChanges() {
        if (this.achFile) {
            this.selection.select(this.achFile);
        }
        this.dataSource = new MatTableDataSource<AchFileMetadata>(this.achFiles);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach((row) => {
                const selectedIds = this.selection.selected.map((x) => x.id);
                if (!selectedIds.includes(row.id)) {
                    this.selection.select(row);
                }
            });
        }
        this.selectedItems.emit(this.selection.selected);
    }

    /** Selects single row if it is not selected; otherwise deselects row */
    public toggleItem(row: AchFileMetadata): void {
        const selectedRow = this.selection.selected.find((item) => item.id === row.id);
        if (selectedRow) {
            this.selection.deselect(selectedRow);
        } else {
            this.selection.select(row);
        }
        this.selectedItems.emit(this.selection.selected);
    }

    public openCreateAch(): boolean {
        if (this.linkEnabled) {
            this.sidePanelService.onSidePanelContentChanged.next({
                displayedComponent: SidePanelContent.achFileCreate,
            });
        }
        return false;
    }

    public isRowSelected(row: AchFileMetadata): boolean {
        return this.selection.selected.findIndex((x) => x.id === row.id) !== -1;
    }
}
